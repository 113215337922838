const pointsChecocards = `<table>
  <tr>
    <td>AUTODROMO</td>
    <td><a href="https://maps.app.goo.gl/8nkvbNKGPFyNDM3R7" 
      target="_blank"
      rel="noopener noreferrer"
      >Viad. Río de la Piedad S/n, Granjas México, Iztacalco, 08400 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>METRO PATRIOTISMO</td>
    <td><a href="https://maps.app.goo.gl/gHhYEA5y5qeQZX938" 
      target="_blank"
      rel="noopener noreferrer"
      >Cuauhtémoc, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>METRO CHILPANCINGO</td>
    <td><a href="https://maps.app.goo.gl/kF2RYewZxQ7wqtqdA" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Baja California 80, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>METRO CHABACANO</td>
    <td><a href="https://maps.app.goo.gl/jToLxWG8Ly6cHAXo8" 
      target="_blank"
      rel="noopener noreferrer"
      >Vista Alegre, 06860 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>METRO VELODROMO </td>
    <td><a href="https://maps.app.goo.gl/YXBiEDmy1ZeVoeEH8" 
      target="_blank"
      rel="noopener noreferrer"
      >Jardín Balbuena, 15900 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Museo Nacional de Antropología</td>
    <td><a href="https://maps.app.goo.gl/6yz7FxzhhWryRwBn8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. P.º de la Reforma s/n, Polanco, Bosque de Chapultepec I Secc, Miguel Hidalgo, 11560 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Alameda Central</td>
    <td><a href="https://maps.app.goo.gl/oGY7wigkxPMEWvnx6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Hidalgo s/n, Centro Histórico de la Cdad. de México, Centro, Cuauhtémoc, 06010 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Parque México</td>
    <td><a href="https://maps.app.goo.gl/4w6cKX5EVQv4WGuK6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av México s/n, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Fuente de los coyotes</td>
    <td><a href="https://maps.app.goo.gl/uH4iDPYZq8obdJse6" 
      target="_blank"
      rel="noopener noreferrer"
      >Parque Centenario, Coyoacán TNT, Coyoacán, 04000 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Ángel de la Independencia</td>
    <td><a href="https://maps.app.goo.gl/d5crLqLi6ZnnWz4U8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. P.º de la Reforma, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Plaza de la Constitución</td>
    <td><a href="https://maps.app.goo.gl/aGG8pTyZHPMJdjYb6" 
      target="_blank"
      rel="noopener noreferrer"
      >P.za de la Constitución S/N, Centro Histórico de la Cdad. de México, Centro, Cuauhtémoc, 06010 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Antara</td>
    <td><a href="https://maps.app.goo.gl/sz6CzBdQmnsnCU148" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Ejército Nacional Mexicano 843-B, Granada, Miguel Hidalgo, 11520 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Monumento a la Revolución</td>
    <td><a href="https://maps.app.goo.gl/PEuxaPzcF4njrjiTA" 
      target="_blank"
      rel="noopener noreferrer"
      >Pl. de la República s/n, Tabacalera, Cuauhtémoc, 06030 Cuauhtémoc, CDMX</a></td>
  </tr>
  <tr>
    <td>Parque La Mexicana</td>
    <td><a href="https://maps.app.goo.gl/MFe5e9wfvCACxmTJ9" 
      target="_blank"
      rel="noopener noreferrer"
      >Lomas de Santa Fe, Contadero, Cuajimalpa de Morelos, 05348 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Parque Lincoln </td>
    <td><a href="https://maps.app.goo.gl/JU9JixM2wbin83jC7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Emilio Castelar 163, Polanco, Polanco III Secc, Miguel Hidalgo, 11560 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Parque Hundido </td>
    <td><a href="https://maps.app.goo.gl/PUsmNHFx7Yra4Z1S9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. de los Insurgentes Sur s/n, Extremadura Insurgentes, Benito Juárez, 03740 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Bosque de Chapultepec</td>
    <td><a href="https://maps.app.goo.gl/VVGFArHefCsHhNp47" 
      target="_blank"
      rel="noopener noreferrer"
      >Miguel Hidalgo, Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Alameda Sur</td>
    <td><a href="https://maps.app.goo.gl/TKh1g4pd6AsGicFC6" 
      target="_blank"
      rel="noopener noreferrer"
      >Canal de Miramontes S/N, Coapa, Las Campañas, Coyoacán, 04929 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Castillo de Chapultepec</td>
    <td><a href="https://maps.app.goo.gl/idK99ydtyQ6X9vpe6" 
      target="_blank"
      rel="noopener noreferrer"
      >Bosque de Chapultepec I Secc, Miguel Hidalgo, 11580 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Palacio de Bellas Artes</td>
    <td><a href="https://maps.app.goo.gl/UAqEvq3NMBXiuUmu5" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Juarez S/N, Centro Histórico de la Cdad. de México, Centro, Cuauhtémoc, 06050 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Museo del Templo Mayor</td>
    <td><a href="https://maps.app.goo.gl/epXm5xAbWuMAz2BZ9" 
      target="_blank"
      rel="noopener noreferrer"
      >Seminario 8, Centro Histórico de la Cdad. de México, Centro, Cuauhtémoc, 06060 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Museo Nacional De Arte</td>
    <td><a href="https://maps.app.goo.gl/s14h326J8cuHkt596" 
      target="_blank"
      rel="noopener noreferrer"
      >C. de Tacuba 8, Centro Histórico de la Cdad. de México, Centro, Cuauhtémoc, 06000 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Fuente de Cibeles</td>
    <td><a href="https://maps.app.goo.gl/E5LHqk1zJX6kt45p9" 
      target="_blank"
      rel="noopener noreferrer"
      >Pl. Villa de Madrid, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Polyforum Siqueiros</td>
    <td><a href="https://maps.app.goo.gl/NvHMiUTk9yubAQbu7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Insurgentes Sur 701, Nápoles, Benito Juárez, 03810 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Acuario Inbursa</td>
    <td><a href="https://maps.app.goo.gl/WjQkozdf1zbRSqHK7" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Miguel de Cervantes Saavedra 386, Granada, Miguel Hidalgo, 11500 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Estadio Olímpico Universitario</td>
    <td><a href="https://maps.app.goo.gl/hP7XWVu1XyiPFQpz5" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. de los Insurgentes Sur S/N, C.U., Coyoacán, 04510 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Estadio Azteca</td>
    <td><a href="https://maps.app.goo.gl/f89S9iMg7YeQiry77" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz. de Tlalpan 3465, Sta. Úrsula Coapa, Coyoacán, 04650 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Museo Soumaya</td>
    <td><a href="https://maps.app.goo.gl/4YVMuFRz8ppccGeNA" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Miguel de Cervantes Saavedra, Granada, Miguel Hidalgo, 11529 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Parque España</td>
    <td><a href="https://maps.app.goo.gl/GEEtKMYgfk3c6k6B7" 
      target="_blank"
      rel="noopener noreferrer"
      >Parque España, Colonia Condesa, Cuauhtémoc, 06140 Cuauhtémoc, CDMX</a></td>
  </tr>
  <tr>
    <td>Teatro de los Insurgentes </td>
    <td><a href="https://maps.app.goo.gl/n2Lhcpoof6bDfA3X7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. de los Insurgentes Sur 1587, San José Insurgentes, Benito Juárez, 03900 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Mundo E</td>
    <td><a href="https://maps.app.goo.gl/41vHoFJmMSD68v2B9" 
      target="_blank"
      rel="noopener noreferrer"
      >Nº 1007, Perif. Blvd. Manuel Ávila Camacho Manzana 003, Hab Jardines de Santa Monica, 54055 Tlalnepantla, Méx.</a></td>
  </tr>
  <tr>
    <td>Palacio Municipal de Tlalnepantla de Baz</td>
    <td><a href="https://maps.app.goo.gl/z8VdC5bv6XQXUFww8" 
      target="_blank"
      rel="noopener noreferrer"
      >Plaza Gustavo Baz, Tlalnepantla Centro, 54000 Tlalnepantla, Méx.</a></td>
  </tr>
  <tr>
    <td>Plaza Satélite</td>
    <td><a href="https://maps.app.goo.gl/opZVaRJskAZcBHU98" 
      target="_blank"
      rel="noopener noreferrer"
      >Cto Centro Comercial 2251, Cd. Satélite, 53100 Naucalpan de Juárez, Méx.</a></td>
  </tr>
  <tr>
    <td>Parque Tezozómoc</td>
    <td><a href="https://maps.app.goo.gl/9a6Sh5bmhitfMAfa7" 
      target="_blank"
      rel="noopener noreferrer"
      >Manuel Salazar S/N, Hacienda del Rosario, Prados del Rosario, Azcapotzalco, 02410 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Plaza Lomas Verdes</td>
    <td><a href="https://maps.app.goo.gl/BKM9GiyUdzMb538S7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Lomas Verdes, Anillo Perif. esquina, 53150 Naucalpan de Juárez, Méx.</a></td>
  </tr>
  <tr>
    <td>Parque Naucalli</td>
    <td><a href="https://maps.app.goo.gl/TyoXJhfcUjDZiKU27" 
      target="_blank"
      rel="noopener noreferrer"
      >Perif. Blvd. Manuel Ávila Camacho Manzana 020, Boulevares, 53150 Naucalpan de Juárez, Méx.</a></td>
  </tr>
  <tr>
    <td>La Minerva</td>
    <td><a href="https://maps.app.goo.gl/UiuQMbSobJjTe3Ba9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Ignacio L Vallarta, Vallarta, 44110 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>Plaza Tapatía </td>
    <td><a href="https://maps.app.goo.gl/qppU32SMMHvsy9bx5" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz Independencia Sur, Zona Centro, 44100 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>Catedral de Guadalajara</td>
    <td><a href="https://maps.app.goo.gl/154UUbq1pXDFWwCh7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Fray Antonio Alcalde 10, Zona Centro, 44100 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>Plaza de los Mariachis </td>
    <td><a href="https://maps.app.goo.gl/nYJeLcqHCU3ofUQt8" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Álvaro Obregón 23, San Juan de Dios, 44360 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>Teatro Degollado </td>
    <td><a href="https://maps.app.goo.gl/gSTpXe3u4aRdQCa79" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Degollado s/n, Zona Centro, 44100 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>Rotonda de los Jaliscienses Ilustres</td>
    <td><a href="https://maps.app.goo.gl/MBEobq5zZuWBuqVN7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Fray Antonio Alcalde 108, Zona Centro, 44100 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>Fuentes Los Danzantes</td>
    <td><a href="https://maps.app.goo.gl/XuW2cmno1xNRfBRR9" 
      target="_blank"
      rel="noopener noreferrer"
      >P.º Hospicio, San Juan de Dios, 44360 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>Plaza de la Liberación</td>
    <td><a href="https://maps.app.goo.gl/1pQcP3gvGteUZeU38" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Pedro Loza 27A, Zona Centro, 44100 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>Jardín Hidalgo</td>
    <td><a href="https://maps.app.goo.gl/FwRn7jD7WaJUCqsD8" 
      target="_blank"
      rel="noopener noreferrer"
      >Guillermo Prieto S/N, Centro, 45500 San Pedro Tlaquepaque, Jal.</a></td>
  </tr>
  <tr>
    <td>Parque Agua Azul</td>
    <td><a href="https://maps.app.goo.gl/jU9zA66WFGNFJDAZA" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz Independencia Sur 973, Centro, 44100 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>La Gran Plaza Fashion Mall</td>
    <td><a href="https://maps.app.goo.gl/a6QJi3zw2XaGkdBr6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Ignacio L Vallarta 3959, Don Bosco Vallarta, 45049 Zapopan, Jal.</a></td>
  </tr>
  <tr>
    <td>Andares Centro Comercial</td>
    <td><a href="https://maps.app.goo.gl/6DQEVyajAgRAcDrX6" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvrd Puerta de Hierro 4965, Puerta de Hierro, 45116 Zapopan, Jal.</a></td>
  </tr>
  <tr>
    <td>Letras Puerto Vallarta</td>
    <td><a href="https://maps.app.goo.gl/FwEKCxtFsY5of8AJ6" 
      target="_blank"
      rel="noopener noreferrer"
      >Morelos s/n, Centro, 48300 Puerto Vallarta, Jal.</a></td>
  </tr>
  <tr>
    <td>Los Arcos</td>
    <td><a href="https://maps.app.goo.gl/t8u4FpprVx9MS3wZ8" 
      target="_blank"
      rel="noopener noreferrer"
      >Malecón s/n, Centro, 48300 Puerto Vallarta, Jal.</a></td>
  </tr>
  <tr>
    <td>El Malecón - Puerto Vallarta</td>
    <td><a href="https://maps.app.goo.gl/eD56tVoTPkjsnkpE7" 
      target="_blank"
      rel="noopener noreferrer"
      >Paseo Díaz Ordaz S/N, Centro, 48300 Puerto Vallarta, Jal.</a></td>
  </tr>
  <tr>
    <td>Parque Fundidora </td>
    <td><a href="https://maps.app.goo.gl/8RQpZHuEXGE6YLAc8" 
      target="_blank"
      rel="noopener noreferrer"
      >Avenida Fundidora y, Adolfo Prieto S/N, Obrera, 64010 Monterrey, N.L.</a></td>
  </tr>
  <tr>
    <td>Paseo Santa Lucía</td>
    <td><a href="https://maps.app.goo.gl/tb1Hdk52zBPo4yv68" 
      target="_blank"
      rel="noopener noreferrer"
      >Avenida Fundidora y, Adolfo Prieto s/n, Obrera, 64010 Monterrey, N.L.</a></td>
  </tr>
  <tr>
    <td>La Macroplaza </td>
    <td><a href="https://maps.app.goo.gl/s5i2WYN4u6jhyhms8" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro, 64000 Monterrey, N.L.</a></td>
  </tr>
  <tr>
    <td>Museo Marco </td>
    <td><a href="https://maps.app.goo.gl/TFc2vL28p1FSrpaP8" 
      target="_blank"
      rel="noopener noreferrer"
      >Juan Zuazua, Padre Raymundo Jardón y, Centro, 64000 Monterrey, N.L.</a></td>
  </tr>
  <tr>
    <td>Estadio BBVA </td>
    <td><a href="https://maps.app.goo.gl/yEp9fs8t6FdeqsUu6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Pablo Livas 2011, La Pastora, 67140 Guadalupe, N.L.</a></td>
  </tr>
  <tr>
    <td>Museo de Historia Mexicana</td>
    <td><a href="https://maps.app.goo.gl/KtvwV3k1vMjR5EMS7" 
      target="_blank"
      rel="noopener noreferrer"
      >Dr. José Ma. Coss 445, Centro, 64000 Monterrey, N.L.</a></td>
  </tr>
  <tr>
    <td>Galerías Monterrey</td>
    <td><a href="https://maps.app.goo.gl/ujrq5zARUn5NAUxb7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Insurgentes 2500, Vista Hermosa, 64620 Monterrey, N.L.</a></td>
  </tr>
  <tr>
    <td>Paseo San Pedro</td>
    <td><a href="https://maps.app.goo.gl/dYeScyrrmk6pGPqg6" 
      target="_blank"
      rel="noopener noreferrer"
      >Avenida José Vasconcelos esq, Av. Manuel Gómez Morín 402, Del Valle, 66220 San Pedro Garza García, N.L.</a></td>
  </tr>
  <tr>
    <td>Museo De Arte Contemporáneo De Monterrey</td>
    <td><a href="https://maps.app.goo.gl/D49X4AppthyWPJMX8" 
      target="_blank"
      rel="noopener noreferrer"
      >Juan Zuazua, Padre Raymundo Jardón y, Centro, 64000 Monterrey, N.L.</a></td>
  </tr>
  <tr>
    <td>Alameda Monterrey</td>
    <td><a href="https://maps.app.goo.gl/QDvpaMzy5r2RyP4SA" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro, 64000 Monterrey, N.L.</a></td>
  </tr>
  <tr>
    <td>Barrio del Artista </td>
    <td><a href="https://maps.app.goo.gl/BDByS4tLo7cNxdnL8" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Héroes del 5 de Mayo, Centro histórico de Puebla, 72000 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Fuerte de Loreto y Guadalupe</td>
    <td><a href="https://maps.app.goo.gl/f7zMvfWWDgbT2upt7" 
      target="_blank"
      rel="noopener noreferrer"
      >Moctezuma, 72260 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Parque Paseo de los Gigantes</td>
    <td><a href="https://maps.app.goo.gl/L11Jax17NumRkVNj6" 
      target="_blank"
      rel="noopener noreferrer"
      >Constancia 9, San José las Flores, 72116 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Callejón de los Sapos </td>
    <td><a href="https://maps.app.goo.gl/ZPPZ5thoWRLDFedX6" 
      target="_blank"
      rel="noopener noreferrer"
      >México 6, México 83, 72017 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Plaza Dorada </td>
    <td><a href="https://maps.app.goo.gl/GnJUDq78qSJP9NQJA" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Heroes 5 de Mayo # 3510 C.C. Plaza Dorada, Anzures, 72534 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Zócalo de Puebla</td>
    <td><a href="https://maps.app.goo.gl/xCSuSbqS1WwM37f26" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Don Juan de Palafox y. Mendoza, Centro histórico de Puebla, 72000 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Zona Histórica de los Fuertes</td>
    <td><a href="https://maps.app.goo.gl/1bCFWzJ4j4ZKiV286" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz. de los Fuertes 2806, Cívica 5 de Mayo, 72260 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Angelopolis</td>
    <td><a href="https://maps.app.goo.gl/4hCMy4B4u6is4TmT6" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvrd del Niño Poblano 2510, Reserva Territorial Atlixcáyotl, Concepción la Cruz, 72197 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Museo Amparo</td>
    <td><a href="https://maps.app.goo.gl/ZhVBUYEkDf5Bhf2Y7" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 2 Sur 708, Centro, 72000 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Museo Nacional De Los Ferrocarriles Mexicanos</td>
    <td><a href="https://maps.app.goo.gl/tsZotsLJZiVyThySA" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle 11 Nte. 1005, San Miguelito, 72090 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Paseo Bravo</td>
    <td><a href="https://maps.app.goo.gl/c8iSFfi5AYSxUfJf6" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Constitución de 1917 3, Centro, 72000 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Parque De La Niñez</td>
    <td><a href="https://maps.app.goo.gl/89bFAwZmoP4kum3v5" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvrd del Niño Poblano 2901, Reserva Territorial Atlixcáyotl, Centro Comercial Puebla, 72834 San Bernardino Tlaxcalancingo, Pue.</a></td>
  </tr>
  <tr>
    <td>Parque Zócalo de Puebla</td>
    <td><a href="https://maps.app.goo.gl/i33A3SfZPd5WfmDk8" 
      target="_blank"
      rel="noopener noreferrer"
      >72000, Av. Don Juan de Palafox y. Mendoza 232, Centro histórico de Puebla, Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Mercado de Sabores</td>
    <td><a href="https://maps.app.goo.gl/tvhDCUdBEdi4ujrV9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. 4 Pte. 1104, Historiadores, 72000 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Skate Plaza Xonaca</td>
    <td><a href="https://maps.app.goo.gl/DZNRBqz61SzUeJog9" 
      target="_blank"
      rel="noopener noreferrer"
      >Xonaca N, Xonaca, 72280 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Casa de la Corregidora</td>
    <td><a href="https://maps.app.goo.gl/N4xenfCtyByvNnVr5" 
      target="_blank"
      rel="noopener noreferrer"
      >Andador, C. 5 de Mayo, Centro, 76000 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Alameda Hidalgo </td>
    <td><a href="https://maps.app.goo.gl/Be9zrjadoM4wVv1LA" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Constituyentes s/n, Centro Histórico, 76000 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>El Acueducto</td>
    <td><a href="https://maps.app.goo.gl/7QdqAUkFjwYhd2Ht8" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz. de los Arcos, Carretas, 76050 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Estadio la Corregidora</td>
    <td><a href="https://maps.app.goo.gl/spQkFtC57ne428KM8" 
      target="_blank"
      rel="noopener noreferrer"
      >Avenidas de las Torres S/N, Centro Sur, 76090 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Centro de Congresos Querétaro</td>
    <td><a href="https://maps.app.goo.gl/fz1yVjP6vqN3ctNT7" 
      target="_blank"
      rel="noopener noreferrer"
      >Paseo de las Artes 1531-B, Josefa Vergara, 76090 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Plaza de Los Fundadores</td>
    <td><a href="https://maps.app.goo.gl/D5t6C1Co5XnpyaeM6" 
      target="_blank"
      rel="noopener noreferrer"
      >Prolongacion Independencia Sur n, La Santa Cruz, Centro, 76020 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Jardín Guerrero</td>
    <td><a href="https://maps.app.goo.gl/3wPpKt77yLqpCutT6" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Vicente Guerrero 3, Centro, 76000 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Galerías Querétaro</td>
    <td><a href="https://maps.app.goo.gl/19j2g1PfzeEtoAPs9" 
      target="_blank"
      rel="noopener noreferrer"
      >Avenida 5 de Febrero 99, Esq, Acceso Carretera Celaya Cuota, Los Virreyes, 76175 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Plaza del Parque</td>
    <td><a href="https://maps.app.goo.gl/EeWGvKBV7zGi5f3U9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Corregidora Nte. 691, Plaza del Parque, 76169 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Plaza Patio Querétaro</td>
    <td><a href="https://maps.app.goo.gl/NnPi9ebMkoLWvWRf9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Revolución, Puerta del Sol II, 76114 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>"Mercado Josefa Ortiz de Domínguez ""La Cruz"""</td>
    <td><a href="https://maps.app.goo.gl/YwNPz6y7y19zB4hK7" 
      target="_blank"
      rel="noopener noreferrer"
      >Mercado Josefa Ortiz de Dominguez ""La Cruz"" (sobre, C/ 15 de Mayo, Centro, 76020 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>La Zacatecana • Casa Museo</td>
    <td><a href="https://maps.app.goo.gl/kABJDugafc7d4uMw5" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Independencia 59, Centro, 76000 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Plaza de la Tecnología Querétaro</td>
    <td><a href="https://maps.app.goo.gl/MswYvRwkxbgEtrgr7" 
      target="_blank"
      rel="noopener noreferrer"
      >Manuel Tolsa 2, Ejido Modelo, Centro, 76000 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Plaza del Estudiante</td>
    <td><a href="https://maps.app.goo.gl/Z69aHZEYJ1VXC3GEA" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Tecnológico 03, Centro, 76000 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Plaza Juriquilla</td>
    <td><a href="https://maps.app.goo.gl/nRNuLCTTPvBa6VK87" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Juriquilla 3100, Jurica Acueducto, 76230 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Jardín de San Marcos</td>
    <td><a href="https://maps.app.goo.gl/DNEbKYXrbEiHSyrk7" 
      target="_blank"
      rel="noopener noreferrer"
      >Jesús F. Contreras, Barrio de San Marcos, 20070 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Museo Nacional de la Muerte</td>
    <td><a href="https://maps.app.goo.gl/2qVJ5GH38Z8smSfK9" 
      target="_blank"
      rel="noopener noreferrer"
      >Rivero y Gutiérrez entre José María Morelos y Pavón, Zona Centro, 20000 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Templo de San Antonio</td>
    <td><a href="https://maps.app.goo.gl/yXigPE27vr9aCLHN9" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Pedro Parga 252, Zona Centro, 20000 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Plaza de la Patria</td>
    <td><a href="https://maps.app.goo.gl/3byyFQYY7qymGAgD9" 
      target="_blank"
      rel="noopener noreferrer"
      >5 de Mayo 105, Zona Centro, 20000 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Complejo turístico Tres Centurias</td>
    <td><a href="https://maps.app.goo.gl/SU6khrCMzvshrzhW6" 
      target="_blank"
      rel="noopener noreferrer"
      >Plaza tres centurias, Desarrollo Especial Talleres F.F.C.C., 20259 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Centro Comercial Plaza Patria</td>
    <td><a href="https://maps.app.goo.gl/W9hZizz2FjHeV2BcA" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. José María Chávez 123, Zona Centro, 20000 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Plaza Aguascalientes</td>
    <td><a href="https://maps.app.goo.gl/MHfZJHSsHCo3rJAo6" 
      target="_blank"
      rel="noopener noreferrer"
      >20259, Av. Adolfo López Mateos Ote. 1016, Barrio de la Estación, 20259 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Centro Comercial Pasaje San Antonio</td>
    <td><a href="https://maps.app.goo.gl/udG4KbNXcAVwTiWP7" 
      target="_blank"
      rel="noopener noreferrer"
      >Lic. Benito Juárez 318, Zona Centro, 20000 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Parque Miguel Hidalgo</td>
    <td><a href="https://maps.app.goo.gl/LkQuNHXRmyP818SX6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av LIC.Adolfo López M.Ote s/n, Barrio de la Purísima, 20259 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Parque Colinas del Río</td>
    <td><a href="https://maps.app.goo.gl/9KRZd7UMajZ2WNkc6" 
      target="_blank"
      rel="noopener noreferrer"
      >Río Bravo, Colinas del Río, 20010 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Plaza Santo Tomás</td>
    <td><a href="https://maps.app.goo.gl/VuSrBtPEWX3NjgMX8" 
      target="_blank"
      rel="noopener noreferrer"
      >Miramar 666, Zona Centro, 22800 Ensenada, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque Morelos</td>
    <td><a href="https://maps.app.goo.gl/6iUFmoY2oQzDcgGR7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av de los Insurgentes 16000, Guadalajara, 22105 Tijuana, B.C.</a></td>
  </tr>
  <tr>
    <td>Malecón de Playas de Tijuana</td>
    <td><a href="https://maps.app.goo.gl/FzGGmPsMY2zN8EV9A" 
      target="_blank"
      rel="noopener noreferrer"
      >P.º Costero, Costa, 22504 Tijuana, B.C.</a></td>
  </tr>
  <tr>
    <td>Torre de Agua Caliente</td>
    <td><a href="https://maps.app.goo.gl/thZLZgDdoHgnetGg7" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Agua Caliente, Juárez, 22040 Tijuana, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque Hacienda Aguacaliente</td>
    <td><a href="https://maps.app.goo.gl/jYEZjFC8vh2x1aeh8" 
      target="_blank"
      rel="noopener noreferrer"
      >Sta. Claudia 5875, Hacienda Agua Caliente, 22030 Tijuana, B.C.</a></td>
  </tr>
  <tr>
    <td>Plaza Alameda Otay</td>
    <td><a href="https://maps.app.goo.gl/cWsgT8WQqGxxduyj9" 
      target="_blank"
      rel="noopener noreferrer"
      >La Pechuga, 22425 Tijuana, B.C.</a></td>
  </tr>
  <tr>
    <td>Estadio Universitario UABC Tijuana</td>
    <td><a href="https://maps.app.goo.gl/b36VTWzK36AJiCzo8" 
      target="_blank"
      rel="noopener noreferrer"
      >UABC, Mesa de Otay, 22424 Tijuana, B.C.</a></td>
  </tr>
  <tr>
    <td>Universidad Autónoma de Baja California</td>
    <td><a href="https://maps.app.goo.gl/XsGEVPrMaPePPinZ7" 
      target="_blank"
      rel="noopener noreferrer"
      >Universidad 14418, UABC, Parque Internacional Industrial Tijuana, 22390 Tijuana, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque México</td>
    <td><a href="https://maps.app.goo.gl/1DNPuMr6bj4kv2ww8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Parque México Nte. 156, Playas, Jardines Playas de Tijuana, 22500 Tijuana, B.C.</a></td>
  </tr>
  <tr>
    <td>Bosque y Zoológico de la Ciudad</td>
    <td><a href="https://maps.app.goo.gl/ZSX3as1ycyFPfMoB7" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Alvarado S/N, Nueva Esperanza, 21050 Mexicali, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque Los Naranjos</td>
    <td><a href="https://maps.app.goo.gl/CmBTm666dxfiKJPT9" 
      target="_blank"
      rel="noopener noreferrer"
      >Los Naranjos, 21387 Mexicali, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque Xochicalli</td>
    <td><a href="https://maps.app.goo.gl/yUC1SNdgNszp4c8x6" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz Laguna Xochimilco, Xochicalli, 21353 Mexicali, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque Venezia</td>
    <td><a href="https://maps.app.goo.gl/NT34vWepWqVGk1Fx7" 
      target="_blank"
      rel="noopener noreferrer"
      >Latina 1222, Residencial Venecia, 21353 Mexicali, B.C.</a></td>
  </tr>
  <tr>
    <td>Plaza Juventud 2000</td>
    <td><a href="https://maps.app.goo.gl/WGPHaWDfUQGxV8og8" 
      target="_blank"
      rel="noopener noreferrer"
      >Laguna de Viesca 244, Terrenos Rústicos, 21353 Mexicali, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque De Quinta Del Rey III</td>
    <td><a href="https://maps.app.goo.gl/FKgJKiYFfjBCMTRBA" 
      target="_blank"
      rel="noopener noreferrer"
      >Residencial Quinta del Rey 3ra Etapa, 21355 Mexicali, B.C.</a></td>
  </tr>
  <tr>
    <td>Plaza Xochimilco</td>
    <td><a href="https://maps.app.goo.gl/XrUsD8szSX69PtM1A" 
      target="_blank"
      rel="noopener noreferrer"
      >Calzada Laguna Xochimilco, Calz Anáhuac, 21380 Mexicali, B.C.</a></td>
  </tr>
  <tr>
    <td>Macroplaza del Valle</td>
    <td><a href="https://maps.app.goo.gl/aCHtWArB3QUJdAXr5" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Lázaro Cárdenas #2200, El Porvenir, 21220 Mexicali, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque La Rumorosa</td>
    <td><a href="https://maps.app.goo.gl/JmuJFuQoNiPhTLGh9" 
      target="_blank"
      rel="noopener noreferrer"
      >Delegación #100, 21510 La Rumorosa, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque Chapultepec</td>
    <td><a href="https://maps.app.goo.gl/kZGNL57aEVd8FGPN8" 
      target="_blank"
      rel="noopener noreferrer"
      >José María Morelos y Pavón 121, Chapultepec, 22785 Ensenada, B.C.</a></td>
  </tr>
  <tr>
    <td>Ventana al Mar</td>
    <td><a href="https://maps.app.goo.gl/qXvScrUfAt5cwKJ46" 
      target="_blank"
      rel="noopener noreferrer"
      >Prol. Castillo, Zona Centro, 22800 Ensenada, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque Revolución</td>
    <td><a href="https://maps.app.goo.gl/7rXA2MhcJbyHr5E17" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Moctezuma, Zona Centro, 22800 Ensenada, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque Obrera</td>
    <td><a href="https://maps.app.goo.gl/ZVEocWwm2EwuSSeAA" 
      target="_blank"
      rel="noopener noreferrer"
      >Obrera, 22830 Ensenada, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque Josefa Ortiz de Dominguez</td>
    <td><a href="https://maps.app.goo.gl/aRH7UTZsqzWSHA549" 
      target="_blank"
      rel="noopener noreferrer"
      >Costa Azul, 22890 Ensenada, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque Costa Bella</td>
    <td><a href="https://maps.app.goo.gl/2MLuPoNgPw2NffkX8" 
      target="_blank"
      rel="noopener noreferrer"
      >P.º Costa Bella 105, 22898 Ensenada, B.C.</a></td>
  </tr>
  <tr>
    <td>Plaza Puerto Paraiso</td>
    <td><a href="https://maps.app.goo.gl/9weMtNhNBBzW1R8RA" 
      target="_blank"
      rel="noopener noreferrer"
      >Lázaro Cárdenas Sn y Cabo Bello, Centro, 23450 Cabo San Lucas, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Plaza Bonita Mall</td>
    <td><a href="https://maps.app.goo.gl/TWUewWJNc5KPgfnt8" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Paseo de la Marina 17, Centro, 23450 Cabo San Lucas, B.C.S.</a></td>
  </tr>

  <tr>
    <td>Luxury Avenue Los Cabos</td>
    <td><a href="https://maps.app.goo.gl/TswefFUv2VaBrqQF6" 
      target="_blank"
      rel="noopener noreferrer"
      >Luxury Avenue, Paseo de la Marina, Blvd. Lázaro Cárdenas, El Medano Ejidal, 23453 Cabo San Lucas, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Patio Los Cabos</td>
    <td><a href="https://maps.app.goo.gl/AyNjwrS6HPREGMex7" 
      target="_blank"
      rel="noopener noreferrer"
      >De las Brisas 2404, Brisas del Pacifico, 23473 Cabo San Lucas, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Plaza Golden Palace</td>
    <td><a href="https://maps.app.goo.gl/K5eKwLw8G18osvjf7" 
      target="_blank"
      rel="noopener noreferrer"
      >Todos los Santos-Cabo San Lucas 9, Lomas Altas, 23472 Cabo San Lucas, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Plaza Dorada</td>
    <td><a href="https://maps.app.goo.gl/6c8sJmTzZaf4H3XW9" 
      target="_blank"
      rel="noopener noreferrer"
      >KM 1.9, Todos los Santos-Cabo San Lucas, Cabo San Lucas - Todos los Santos, Lomas Altas, 23473 Cabo San Lucas, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Plaza Embarcadero</td>
    <td><a href="https://maps.app.goo.gl/2PKU5S9QyFvQXz5w7" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Paseo de la Marina Lt 6, Centro, Marina, 23450 Cabo San Lucas, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Plaza las Californias</td>
    <td><a href="https://maps.app.goo.gl/vfEk8VLRz2HNv2TdA" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Lázaro Cárdenas, Ampliación Juárez, 23400 Cabo San Lucas, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Parque Lazaro Cárdenas</td>
    <td><a href="https://maps.app.goo.gl/3vCjdw6cr8RD5HiR6" 
      target="_blank"
      rel="noopener noreferrer"
      >Vicente Guerrero 3360, La Escondida, 23039 La Paz, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Parque Revolución</td>
    <td><a href="https://maps.app.goo.gl/vhvzRR2DkFvrmZQL9" 
      target="_blank"
      rel="noopener noreferrer"
      >Zona Central, 23000 La Paz, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Mi Plaza La Paz</td>
    <td><a href="https://maps.app.goo.gl/dUrk2Ghx2sURY8Hx8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Constituyente de 1975, 23098, La Paz, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Galerías La Paz</td>
    <td><a href="https://maps.app.goo.gl/T8EFw7jhH2Lv7ZG3A" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Gral. Agustín Olachea 8010, El Zacatal, 23088 La Paz, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Plaza Paseo La Paz</td>
    <td><a href="https://maps.app.goo.gl/7NfDNMWqnaUvD55A6" 
      target="_blank"
      rel="noopener noreferrer"
      >Lib. Daniel Roldan Zimbron, 23097 La Paz, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Plaza Bajamar</td>
    <td><a href="https://maps.app.goo.gl/Yksud2tpMAy2nmbZ7" 
      target="_blank"
      rel="noopener noreferrer"
      >5 DE MAYO, 18 de Marzo ESQ, COL. CENTRO, 23000 La Paz, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Malecón de La Paz</td>
    <td><a href="https://maps.app.goo.gl/sadELnPwhLhgDRtB9" 
      target="_blank"
      rel="noopener noreferrer"
      >P.º Malecón, Zona Central, 23000 La Paz, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Catedral de Nuestra Señora de La Paz</td>
    <td><a href="https://maps.app.goo.gl/PquPapk7ZqFkMotc6" 
      target="_blank"
      rel="noopener noreferrer"
      >Revolución de 1910 S/N, Zona Central, 23000 La Paz, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Instituto Sudcaliforniano De Cultura</td>
    <td><a href="https://maps.app.goo.gl/6DNqsYXMCmiLuLx8A" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Ignacio Altamirano 2640, Zona Central, 23000 La Paz, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Puerta de Tierra</td>
    <td><a href="https://maps.app.goo.gl/hFoF8EA8T6qmfpcN8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Cto Baluartes, Zona Centro, 24000 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Malecón de Campeche</td>
    <td><a href="https://maps.app.goo.gl/Ac7ZAeSBhWjjSfNe7" 
      target="_blank"
      rel="noopener noreferrer"
      >Golfo de México, Solidaridad Nacional, 24014 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Puerta de Mar</td>
    <td><a href="https://maps.app.goo.gl/DUVKvpny19KHiEHFA" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle 8, Zona Centro, 24000 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>"Museo Arqueológico de Campeche, Fuerte de San Miguel"</td>
    <td><a href="https://maps.app.goo.gl/Hi7fnibPZY4hSy3f9" 
      target="_blank"
      rel="noopener noreferrer"
      >Carr. Escénica, Zona Centro, 24030 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Parque Campeche</td>
    <td><a href="https://maps.app.goo.gl/Z5U6RH25KcUo7aAXA" 
      target="_blank"
      rel="noopener noreferrer"
      >Pedro Sainz de Baranda, Área Ah, 24010 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Baluarte de San Pedro</td>
    <td><a href="https://maps.app.goo.gl/9gnK4GRymY537jBH6" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 49 x 51, Zona Centro, 24050 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Parque Principal Campeche</td>
    <td><a href="https://maps.app.goo.gl/MmdPouQxgrLLVCo1A" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro Histórico, 24000 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Baluarte de la Soledad</td>
    <td><a href="https://maps.app.goo.gl/KpbdfXcfhxZkdyM78" 
      target="_blank"
      rel="noopener noreferrer"
      >8 o de La Muralla, Zona Centro, 24000 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Plaza de la Independencia</td>
    <td><a href="https://maps.app.goo.gl/obqE7Bop3ahjZwUv9" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro Histórico, 24000 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Letras Campeche</td>
    <td><a href="https://maps.app.goo.gl/nyCdyDsstRUHeR5eA" 
      target="_blank"
      rel="noopener noreferrer"
      >Carr. Costera del Golfo, Zona Centro, 24000 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Mansión Carvajal</td>
    <td><a href="https://maps.app.goo.gl/Mg3KP94y4Variu877" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 10 230, Zona Centro, 24000 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Ximbal Parque Recreativo</td>
    <td><a href="https://maps.app.goo.gl/WhAxxPngCMUjwAog9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Lázaro Cárdenas s/n, Flor de Limón, 24060 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Centro Cultural Casa No. 6</td>
    <td><a href="https://maps.app.goo.gl/GiADmNY3xG1fZTRS8" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 57, Zona Centro, 24000 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Moch Couoh</td>
    <td><a href="https://maps.app.goo.gl/GM2WW2tm7zk241QJ6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Adolfo Ruiz Cortinez 108A, Barrio de San Román, 24040 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Jardín Botánico Xmuch-Haltún</td>
    <td><a href="https://maps.app.goo.gl/zhiDKjy8ZBrfihq1A" 
      target="_blank"
      rel="noopener noreferrer"
      >Zona Centro, 24000 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Parque Caña Hueca</td>
    <td><a href="https://maps.app.goo.gl/TM1KuKSGVTY9SeJx6" 
      target="_blank"
      rel="noopener noreferrer"
      >Libramiento Poniente Norte, 29030 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Parque de la Marimba</td>
    <td><a href="https://maps.app.goo.gl/dc1xnk5tzPgxzSyL9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Central Pte S/N, Centro, 29000 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Parque del Oriente</td>
    <td><a href="https://maps.app.goo.gl/jcWan3F615U5oYyU8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Rosa del Pte s/n, Inf. Grijalva, 29044 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Parque Central</td>
    <td><a href="https://maps.app.goo.gl/KCr22YzwpsmgRxrq7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Central Poniente s/n, El Jobo, 29100 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Parque Central Ocozocoautla</td>
    <td><a href="https://maps.app.goo.gl/H4iHAVPCqyyHULkH8" 
      target="_blank"
      rel="noopener noreferrer"
      >Primera Oriente, Centro, 29140 Ocozocoautla de Espinosa, Chis.</a></td>
  </tr>
  <tr>
    <td>Plaza Las Américas</td>
    <td><a href="https://maps.app.goo.gl/kRbZLoyk5gZFEjoD9" 
      target="_blank"
      rel="noopener noreferrer"
      >5a. Nte. Pte. 5, Joyo Mayyu, 29030 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Macrocentro Comercial Plaza Crystal-Galerías Boulevard</td>
    <td><a href="https://maps.app.goo.gl/FnJ3fdFWjEzcpwvU7" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Belisario Domínguez 1691, Sin Nombre, Jardines de Tuxtla, 29020 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Plaza Crystal Tuxtla</td>
    <td><a href="https://maps.app.goo.gl/cx3iwrWFG53Bsw3PA" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Belisario Domínguez, Sin Nombre, Flamboyán, 29059 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Plaza Polifórum</td>
    <td><a href="https://maps.app.goo.gl/hXuWiJkfERFVZsVC6" 
      target="_blank"
      rel="noopener noreferrer"
      >Andrés Serra Rojas 250, El Retiro, 29040 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Glorioso Cristo de Chiapas</td>
    <td><a href="https://maps.app.goo.gl/aS1cNsZD5QPKzRSz5" 
      target="_blank"
      rel="noopener noreferrer"
      >Segunda Pte. Nte. s/n, La Lomita, 30530 Copoya, Chis.</a></td>
  </tr>
  <tr>
    <td>Parque Joyyo Mayu [Tuxtla Gutiérrez]</td>
    <td><a href="https://maps.app.goo.gl/9jazPEpoefmAEopp6" 
      target="_blank"
      rel="noopener noreferrer"
      >Contra esquina del Reloj Floral, y, Prolongación 5a. Norte, Perif. Nte. Pte., Joyo Mayyu, 29030 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Centro INAH-Museo Regional</td>
    <td><a href="https://maps.app.goo.gl/23k8xuc9jF5akxTP6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av, Norte Poniente No. 1126, Centro, 29000 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Museo del Café de Chiapas</td>
    <td><a href="https://maps.app.goo.gl/gfPwUQcYkDKorwcH6" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle 2a. Ote. Nte. 236, San Marcos, 29000 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>La Parroquia de Veracruz </td>
    <td><a href="https://maps.app.goo.gl/nyvzGgzdP9W3PU6z6" 
      target="_blank"
      rel="noopener noreferrer"
      >Plaza Faro, Sur 33 700, Centro, 94300 Orizaba, Ver.</a></td>
  </tr>
  <tr>
    <td>Parque Acuático Inbursa </td>
    <td><a href="https://maps.app.goo.gl/ehU5p4QZSrFBtAXf8" 
      target="_blank"
      rel="noopener noreferrer"
      >Paseo del Parque 873, Fraccionamiento Nuevo, 91726 Veracruz, Ver.</a></td>
  </tr>
  <tr>
    <td>Malecón Veracruz Puerto</td>
    <td><a href="https://maps.app.goo.gl/K5d1nbvyrKbSgE386" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Mariano Arista, Faros, 91709 Veracruz, Ver.</a></td>
  </tr>
  <tr>
    <td>Teatro Ignacio de la Llave</td>
    <td><a href="https://maps.app.goo.gl/jpd54HQRcU2uA5m77" 
      target="_blank"
      rel="noopener noreferrer"
      >Colón Ote. 57, Centro, 94300 Orizaba, Ver.</a></td>
  </tr>
  <tr>
    <td>Parque Zamora</td>
    <td><a href="https://maps.app.goo.gl/9iug5rjYkcA1ajCd8" 
      target="_blank"
      rel="noopener noreferrer"
      >Independencia, Centro, 91700 Veracruz, Ver.</a></td>
  </tr>
  <tr>
    <td>Zócalo Boca del Río</td>
    <td><a href="https://maps.app.goo.gl/CzfnyYV9cg3YHpCH6" 
      target="_blank"
      rel="noopener noreferrer"
      >Boulevard Miguel Alemán 14, Centro, 94290 Boca del Río, Ver.</a></td>
  </tr>
  <tr>
    <td>Malecón de Boca del Río</td>
    <td><a href="https://maps.app.goo.gl/ztLjHHk6XnMn6Gy58" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Manuel Ávila Camacho 9, Costa Verde, 94294 Boca del Río, Ver.</a></td>
  </tr>
  <tr>
    <td>Las Américas </td>
    <td><a href="https://maps.app.goo.gl/hgJ4VnRC1945KCav9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Las Américas Col, Ylang Ylang, 94298 Veracruz, Ver.</a></td>
  </tr>
  <tr>
    <td>Portal Veracruz</td>
    <td><a href="https://maps.app.goo.gl/w9KTCFB2Wnf5rvCC7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Salvador Díaz Mirón 2069, Centro, 91700 Veracruz, Ver.</a></td>
  </tr>
  <tr>
    <td>Parque Boca del Río</td>
    <td><a href="https://maps.app.goo.gl/8pcgEzncePoqCTQp7" 
      target="_blank"
      rel="noopener noreferrer"
      >plaza banderas, Parque, Boca del Río, Ver.</a></td>
  </tr>
  <tr>
    <td>Parque Tampiquera</td>
    <td><a href="https://maps.app.goo.gl/TfqpL61CtJ5RGDLf6" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Minatitlan, La Tampiquera, 94290 Boca del Río, Ver.</a></td>
  </tr>
  <tr>
    <td>Diana Cazadora</td>
    <td><a href="https://maps.app.goo.gl/siTMPzhL1NmAZAaU7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Costera Miguel Alemán S/N, Fracc Magallanes, La Condesa, 39690 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Centro Internacional Acapulco </td>
    <td><a href="https://maps.app.goo.gl/6CKRhk9cJcLvPZVT7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Costera Miguel Alemán 4455, Costa Azul, 39859 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>La Quebrada </td>
    <td><a href="https://maps.app.goo.gl/MGA96QkqTongGGSf9" 
      target="_blank"
      rel="noopener noreferrer"
      >La Quebrada 25, Centro, 39300 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>La Isla Shopping Village</td>
    <td><a href="https://maps.app.goo.gl/BiBeoNTtVxn69gz67" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. de las Naciones 1813, Playa Dinamita, 39760 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Casa de los Vientos</td>
    <td><a href="https://maps.app.goo.gl/AfDUFBHybt5aF81H8" 
      target="_blank"
      rel="noopener noreferrer"
      >Inalámbrica, Las Playas, 39390 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Plaza Marbella </td>
    <td><a href="https://maps.app.goo.gl/ayhDbEf1Kx1mLL1e6" 
      target="_blank"
      rel="noopener noreferrer"
      >Miguel Alemán Valdez S/N Farallón, 39690 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Zócalo de Acapulco</td>
    <td><a href="https://maps.app.goo.gl/H6K41RjHAM35TjQn8" 
      target="_blank"
      rel="noopener noreferrer"
      >Juan Álvarez, Las Cruces, 39770 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Galerías Acapulco</td>
    <td><a href="https://maps.app.goo.gl/o2yYnv9u5e2tx9Pe7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Costera Miguel Alemán 1632, Fracc Magallanes, Magallanes, 39670 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Plaza Isla Diamante</td>
    <td><a href="https://maps.app.goo.gl/mSQWc6Xt9eXN6Hwd7" 
      target="_blank"
      rel="noopener noreferrer"
      >Aeropuerto, 39897 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Plaza Embarcadero</td>
    <td><a href="https://maps.app.goo.gl/PtLfvR7cWrFwEc3D7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Costera Miguel Alemán 3007, Fracc Costa Azul, Costa Azul, 39850 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Monumento a los Niños Heroes</td>
    <td><a href="https://maps.app.goo.gl/MWAcTcQ4D2KAdR5Y6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Costera MIguel Alemán s/n, Centro, 39300 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Juan Ranulfo Escudero Reguera</td>
    <td><a href="https://maps.app.goo.gl/bkdsaB248Vn9P9N89" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Costera Miguel Alemán s/n, La Pinzona, Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Parque el Palomar </td>
    <td><a href="https://maps.app.goo.gl/3eVs7opErcPC4KMn7" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 10a s/n, El Palomar, 31203 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Catedral de Chihuahua </td>
    <td><a href="https://maps.app.goo.gl/XS7nB7VuaSiw8WQ1A" 
      target="_blank"
      rel="noopener noreferrer"
      >Predio la Cantera, 31216 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Quinta Gameros </td>
    <td><a href="https://maps.app.goo.gl/BAvxUVtrCyzhTwGC9" 
      target="_blank"
      rel="noopener noreferrer"
      >Simon, Av. Paseo Bolívar 401, Zona Centro, 31000 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Museo de la Revolución</td>
    <td><a href="https://maps.app.goo.gl/DK7x11xKHyZ4ohEF6" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 10a 3010, Santa Rosa, 31050 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Plaza del Ángel</td>
    <td><a href="https://maps.app.goo.gl/cVjLs92ncutoomxE9" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Libertad s/n, Centro, 31000 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Catedral Metropolitana de Chihuahua</td>
    <td><a href="https://maps.app.goo.gl/XiT95pKAfVgEhrcYA" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Guadalupe Victoria S/N, Zona Centro, 31000 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Casa Chihuahua</td>
    <td><a href="https://maps.app.goo.gl/N1JHxtq3NKtV2KtGA" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Libertad 901, Zona Centro, 31000 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Casa Redonda</td>
    <td><a href="https://maps.app.goo.gl/R627oYbFvZAC67T39" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Tecnológico s/n, Santo Niño, 31200 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Parque Lerdo de Tejada</td>
    <td><a href="https://maps.app.goo.gl/YEUvyF7qj5EtS6zG6" 
      target="_blank"
      rel="noopener noreferrer"
      >Victoria s/n, Centro, 31000 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Letras monumentales Chihuahua</td>
    <td><a href="https://maps.app.goo.gl/oqzp5v1i6gzvzjnT8" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 13a, Zona Centro, 31000 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Plaza Catedral Chihuahua</td>
    <td><a href="https://maps.app.goo.gl/C2sXRZaBKGH9SCQ9A" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Libertad 605, Zona Centro II, 31000 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Fashion Mall Chihuahua</td>
    <td><a href="https://maps.app.goo.gl/s5RThJz9ijjHvjf57" 
      target="_blank"
      rel="noopener noreferrer"
      >Perif. de la Juventud 3501, Puerta de Hierro, 31410 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Dunas Plaza Comercial</td>
    <td><a href="https://maps.app.goo.gl/sLhsiYQwkbikvsSU6" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvrd Antonio Ortiz Mena 2807, Quintas del Sol, Campestre-Lomas, 31214 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Parque De Los Tronquitos</td>
    <td><a href="https://maps.app.goo.gl/6vnSgAj74YkSchzT8" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Alaska, Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Museo del Desierto</td>
    <td><a href="https://maps.app.goo.gl/wUf4WxRNq4j6cyMk6" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Carlos Abedrop Dávila 3745, Nuevo Centro Metropolitano de Saltillo, 25022 Saltillo, Coah.</a></td>
  </tr>
  <tr>
    <td>Parque Mirador Saltillo</td>
    <td><a href="https://maps.app.goo.gl/S2zydZf4GAZDDNKVA" 
      target="_blank"
      rel="noopener noreferrer"
      >Ojo de Agua 620, Zona Centro, 25040 Saltillo, Coah.</a></td>
  </tr>
  <tr>
    <td>MUSAVE Museo de Las Aves de México Saltillo</td>
    <td><a href="https://maps.app.goo.gl/EbNMTCiBmpXRRTi78" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Hidalgo 151, Zona Centro, 25000 Saltillo, Coah.</a></td>
  </tr>
  <tr>
    <td>Plaza de Armas</td>
    <td><a href="https://maps.app.goo.gl/8eh4aVrpkZzBLXPs5" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Hidalgo 109, Centro, 25000 Saltillo, Coah.</a></td>
  </tr>
  <tr>
    <td>Callejón de Guevara</td>
    <td><a href="https://maps.app.goo.gl/ucnhiUdyBunCVXo77" 
      target="_blank"
      rel="noopener noreferrer"
      >Cuauhtémoc 122, Cuatro Ciénegas de Carranza, Zona Centro, 27640 Cuatro Ciénegas de Carranza, Coah.</a></td>
  </tr>
  <tr>
    <td>Museo de los Presidentes Coahuilenses</td>
    <td><a href="https://maps.app.goo.gl/w3wEXAUjmCE3twRR6" 
      target="_blank"
      rel="noopener noreferrer"
      >Gral. Nicolás Bravo 264, Zona Centro, 25000 Saltillo, Coah.</a></td>
  </tr>
  <tr>
    <td>Parque Ecológico El Chapulín</td>
    <td><a href="https://maps.app.goo.gl/u4aTn7DncU8yr64n9" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Antonio Cárdenas S/N, Zona Sin Asignación de Nombre de Colonia, Chapultepec, 25050 Saltillo, Coah.</a></td>
  </tr>
  <tr>
    <td>Galerías Saltillo</td>
    <td><a href="https://maps.app.goo.gl/pAXAtvFKVfshx9b79" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvrd. Nazario Ortiz Garza 2345, Colonia Tanque de Peña, 25279 Saltillo, Coah.</a></td>
  </tr>
  <tr>
    <td>Alameda Zaragoza</td>
    <td><a href="https://maps.app.goo.gl/BD9bjoYgCqSuYaF17" 
      target="_blank"
      rel="noopener noreferrer"
      >Zona Centro, 25000 Saltillo, Coah.</a></td>
  </tr>
  <tr>
    <td>Parque Fundadores AC</td>
    <td><a href="https://maps.app.goo.gl/xkQ5ajTtuDCi2ky5A" 
      target="_blank"
      rel="noopener noreferrer"
      >Melchor Muzquiz, Plan de Ayala, 27199 Torreón, Coah.</a></td>
  </tr>
  <tr>
    <td>Plaza De la Madre</td>
    <td><a href="https://maps.app.goo.gl/2gnDc51ck6meyoDh8" 
      target="_blank"
      rel="noopener noreferrer"
      >Avenida Benito Juárez, General Cepeda, Coah.</a></td>
  </tr>
  <tr>
    <td>Plaza de las Culturas</td>
    <td><a href="https://maps.app.goo.gl/bjWsAyBfwPw9CzbY7" 
      target="_blank"
      rel="noopener noreferrer"
      >Comunicación 801, Burócrata, 26020 Piedras Negras, Coah.</a></td>
  </tr>
  <tr>
    <td>Museo de la Revolución Mexicana</td>
    <td><a href="https://maps.app.goo.gl/ncDakFtzk3hkFcg28" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Hidalgo 167, Centro, 25000 Saltillo, Coah.</a></td>
  </tr>
  <tr>
    <td>Bosque Urbano Oriente</td>
    <td><a href="https://maps.app.goo.gl/WtKm2mJN2awDx4Wp9" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz. Juan Pablo II, Residencial las Etnias, 27058 Torreón, Coah.</a></td>
  </tr>
  <tr>
    <td>Ex Hacienda Nogueras </td>
    <td><a href="https://maps.app.goo.gl/MG8sz2pudJdHo2sB6" 
      target="_blank"
      rel="noopener noreferrer"
      >Hacienda de Nogueras S / N, Nogueras, 28454 Nogueras, Col.</a></td>
  </tr>
  <tr>
    <td>Plaza San Fernando </td>
    <td><a href="https://maps.app.goo.gl/7zwDLEPKwTafmYsC9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Constitución 555, Lomas de Circunvalación, 28010 Colima, Col.</a></td>
  </tr>
  <tr>
    <td>Museo Regional de Historia de Colima</td>
    <td><a href="https://maps.app.goo.gl/RA7vb8trtvZRXYyMA" 
      target="_blank"
      rel="noopener noreferrer"
      >Portal Morelos 1, Centro Histórico, 28000 Colima, Col.</a></td>
  </tr>
  <tr>
    <td>Jardín Libertad </td>
    <td><a href="https://maps.app.goo.gl/LL7SBciLnksCEbaL6" 
      target="_blank"
      rel="noopener noreferrer"
      >Reforma s/n, Centro, 28000 Colima, Col.</a></td>
  </tr>
  <tr>
    <td>Quiosco de Colima</td>
    <td><a href="https://maps.app.goo.gl/xAYkFzmSTzovX8PWA" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro, 28000 Colima, Col.</a></td>
  </tr>
  <tr>
    <td>Plaza Principal de Comala</td>
    <td><a href="https://maps.app.goo.gl/tBwDHKCADpRna5CYA" 
      target="_blank"
      rel="noopener noreferrer"
      >Fco. I. Madero 6, Centro, 28450 Comala, Col.</a></td>
  </tr>
  <tr>
    <td>Plaza Country </td>
    <td><a href="https://maps.app.goo.gl/xasAagZWeRKGDxTy6" 
      target="_blank"
      rel="noopener noreferrer"
      >Felipe Sevilla del Río 201, Jardines Vista Hermosa I, 28017 Colima, Col.</a></td>
  </tr>
  <tr>
    <td>Parque Hidalgo </td>
    <td><a href="https://maps.app.goo.gl/cXj4167CLesGoKNQ8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. 20 de Noviembre, Centro, 28070 Colima, Col.</a></td>
  </tr>
  <tr>
    <td>Zentralia Colima</td>
    <td><a href="https://maps.app.goo.gl/TQEbL4csTFcj9KcX8" 
      target="_blank"
      rel="noopener noreferrer"
      >P.º Miguel de la Madrid Hurtado 301, 28018 Colima, Col.</a></td>
  </tr>
  <tr>
    <td>Malecón de Manzanillo</td>
    <td><a href="https://maps.app.goo.gl/Fk47eVyrdLQzF9AL7" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Lic. Miguel De La Madrid Hurtado 16705, Deportiva 5 de Mayo, 28220 Manzanillo, Col.</a></td>
  </tr>
  <tr>
    <td>Parque Fundidora Ferrería</td>
    <td><a href="https://maps.app.goo.gl/3kyPDLhKBLaZdksT9" 
      target="_blank"
      rel="noopener noreferrer"
      >Domicilio conocido s/n, 34302 La Ferrería, Dgo.</a></td>
  </tr>
  <tr>
    <td>Plaza Hito 450</td>
    <td><a href="https://maps.app.goo.gl/jA4ydi5UrF6a6nYW8" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Guadalupe 700, Zona Centro, 34000 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Museo Regional de Durango Jose Angel Rodriguez Solorzano</td>
    <td><a href="https://maps.app.goo.gl/VWmbT1RVM5wAyzzb7" 
      target="_blank"
      rel="noopener noreferrer"
      >Victoria Sur 100, Zona Centro, 34000 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Monumento a la bandera </td>
    <td><a href="https://maps.app.goo.gl/o8f3CRTt2L1JNvjSA" 
      target="_blank"
      rel="noopener noreferrer"
      >Cam. del Amanecer, Los Remedios, 34100 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Casa de la Cultura Banamex</td>
    <td><a href="https://maps.app.goo.gl/VTeQCqWhFqoRt7S57" 
      target="_blank"
      rel="noopener noreferrer"
      >Avenida 5 de Febrero Ote, Centro, 34000 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Mirador de los Remedios </td>
    <td><a href="https://maps.app.goo.gl/V7b7yKXFYtkR3s2j9" 
      target="_blank"
      rel="noopener noreferrer"
      >Cerro de Los Remedios s/n, Los Remedios, 34100 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Museo Francisco Villa </td>
    <td><a href="https://maps.app.goo.gl/uetYtgc8dgKKybFV6" 
      target="_blank"
      rel="noopener noreferrer"
      >5 de Febrero, Zaragoza y, Zona Centro, 34000 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Paseo del Viejo Oeste </td>
    <td><a href="https://maps.app.goo.gl/VR9mQuU5Mq1zGSCG6" 
      target="_blank"
      rel="noopener noreferrer"
      >Puente Carretera a Parral km 12, 34394 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Paseo Constitución</td>
    <td><a href="https://maps.app.goo.gl/PjdgDdR4SZYvvE87A" 
      target="_blank"
      rel="noopener noreferrer"
      >34000, C. Constitución 103, Zona Centro, 34000 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Parque Guadina </td>
    <td><a href="https://maps.app.goo.gl/23EtAnhQibZmXNRR9" 
      target="_blank"
      rel="noopener noreferrer"
      >Sin nombre No. 304 SN-S PARQUE GUADIANA, Parque Guadiana, 34116 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Parque Sahuatoba</td>
    <td><a href="https://maps.app.goo.gl/qn1gf247vRM2bku96" 
      target="_blank"
      rel="noopener noreferrer"
      >Vista Hermosa del Guadiana, 34116 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Galerías del Real </td>
    <td><a href="https://maps.app.goo.gl/dcqJwPaTFhwRFN2eA" 
      target="_blank"
      rel="noopener noreferrer"
      >Durango 101, Real del Mezquital, 34199 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Paseo Durango</td>
    <td><a href="https://maps.app.goo.gl/SnMTMYZ6kA4tJhi59" 
      target="_blank"
      rel="noopener noreferrer"
      >34080 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Basílica de Durango</td>
    <td><a href="https://maps.app.goo.gl/eKo1H3Bv2C9gAvZH6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. 20 de Noviembre s/n, Zona Centro, 34000 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Jardín de la Unión </td>
    <td><a href="https://maps.app.goo.gl/Rkk8TQVdESE4x4Ac9" 
      target="_blank"
      rel="noopener noreferrer"
      >Zona Centro, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Plaza del Baratillo </td>
    <td><a href="https://maps.app.goo.gl/vqmMoiF7D1dV6NVV9" 
      target="_blank"
      rel="noopener noreferrer"
      >Baratillo 26, Zona Centro, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Callejón del Beso </td>
    <td><a href="https://maps.app.goo.gl/hRgwEnFPcnqJUocb7" 
      target="_blank"
      rel="noopener noreferrer"
      >Belén 22, Zona Centro, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Monumento al Pípila</td>
    <td><a href="https://maps.app.goo.gl/Ma54C5jrLZDn7XSQA" 
      target="_blank"
      rel="noopener noreferrer"
      >Cerro de San Miguel S/N, Zona Centro, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Teatro Juárez</td>
    <td><a href="https://maps.app.goo.gl/4cJa1vX9763bXKxZ8" 
      target="_blank"
      rel="noopener noreferrer"
      >De Sopena 10, Centro, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Plaza de las Ranas</td>
    <td><a href="https://maps.app.goo.gl/BWUFeWjEMfoQ8ztD6" 
      target="_blank"
      rel="noopener noreferrer"
      >Zona Centro, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Museo Casa Diego Rivera </td>
    <td><a href="https://maps.app.goo.gl/2rDRimKW7sngswjm6" 
      target="_blank"
      rel="noopener noreferrer"
      >Positos 47, Zona Centro, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Estadio León</td>
    <td><a href="https://maps.app.goo.gl/9xxBojHiHdpgAd9SA" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Adolfo López Mateos 1810, La Martinica, 37500 León de los Aldama, Gto.</a></td>
  </tr>
  <tr>
    <td>Universidad de Guanajuato</td>
    <td><a href="https://maps.app.goo.gl/rafYhy5QoLmHPBQx8" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Milenio 1001, Predio San Carlos, 37670 León de los Aldama, Gto.</a></td>
  </tr>
  <tr>
    <td>Presa de la Olla </td>
    <td><a href="https://maps.app.goo.gl/CfHUCbWcHqYRQsfp6" 
      target="_blank"
      rel="noopener noreferrer"
      >Barrio de la Presa, 36094 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Jardin Embajadoras</td>
    <td><a href="https://maps.app.goo.gl/2DBYjr8hJ2Zxs8oWA" 
      target="_blank"
      rel="noopener noreferrer"
      >P.º de La Presa, Zona Centro, 36094 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Teatro Juárez</td>
    <td><a href="https://maps.app.goo.gl/1PGco3zsEgyu8gVC6" 
      target="_blank"
      rel="noopener noreferrer"
      >De Sopena 10, Centro, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Mercado Hidalgo</td>
    <td><a href="https://maps.app.goo.gl/nAbM7GvtR5n67p5f6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Benito Juárez Mercado, Hidalgo, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Explanada de la Alhondiga</td>
    <td><a href="https://maps.app.goo.gl/7Yzrsqr1MCjYiNHx5" 
      target="_blank"
      rel="noopener noreferrer"
      >Zona Centro, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Galerías Pachuca</td>
    <td><a href="https://maps.app.goo.gl/g8G5cydUXzJkfkF8A" 
      target="_blank"
      rel="noopener noreferrer"
      >Camino Real de La Plata 102, Zona Plateada, 42083 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Estadio Hidalgo</td>
    <td><a href="https://maps.app.goo.gl/fjtKEBRZP8NesqFQ8" 
      target="_blank"
      rel="noopener noreferrer"
      >2da B Juárez 102, Los Jales, Ex Hacienda de Coscotitlán, 42064 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Mercado Primero de Mayo</td>
    <td><a href="https://maps.app.goo.gl/h7vJXxix5VXKw1Br7" 
      target="_blank"
      rel="noopener noreferrer"
      >C. José Maria Morelos y Pavón, Centro, 42000 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Mercado Benito Juárez</td>
    <td><a href="https://maps.app.goo.gl/RJTU6o8o7mj8wYd3A" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Venustiano Carranza 300, Centro, 42000 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Reloj Monumental de Pachuca</td>
    <td><a href="https://maps.app.goo.gl/3VEB34HsjxbbLNxj8" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro, 42000 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Archivo Histórico y Museo de Minería A.C</td>
    <td><a href="https://maps.app.goo.gl/55zrDm5CwkRBSyEM9" 
      target="_blank"
      rel="noopener noreferrer"
      >Javier Mina 110, Centro, 42000 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Plaza Juárez</td>
    <td><a href="https://maps.app.goo.gl/HPbqon29ntTPShqY7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Benito Juárez S/N, Centro, 42000 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Parque Hidalgo</td>
    <td><a href="https://maps.app.goo.gl/wGu8gGbrv5KC7xQPA" 
      target="_blank"
      rel="noopener noreferrer"
      >Parque Hidalgo, Jardín Colón, 42050 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Parque de la Familia</td>
    <td><a href="https://maps.app.goo.gl/PJmFpwBvMn2hM8wr7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Benito Juárez sn, Centro, 42000 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Plaza Bella Pachuca</td>
    <td><a href="https://maps.app.goo.gl/5KNdmK8mXmyBBFPu5" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Revolución 1307, Revolución, 42064 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Museo del Fútbol </td>
    <td><a href="https://maps.app.goo.gl/1Sd4dJHeqVGFMgKC9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Ferrocarril Central, Zona Plateada, 42084 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Mercado Aquiles Serdán</td>
    <td><a href="https://maps.app.goo.gl/dKEXm9ubWVtyEuv56" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. del Palmar 130, Aquiles Serdán, 42035 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Ajolotario Miquiztli Xolotl Pachuca</td>
    <td><a href="https://maps.app.goo.gl/LjkkSC9dqNntFT3F6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Amado Nervo 524, Morelos, 42040 Pachuca de Soto, Hgo.</a></td>
  </tr>
  <tr>
    <td>Centro de Convenciones y Exposiciones de Morelia</td>
    <td><a href="https://maps.app.goo.gl/j4qq2eKn7SWV4MBY9" 
      target="_blank"
      rel="noopener noreferrer"
      >Calzada Ventura Puente esq, Perif. Paseo de la República s/n, Félix Ireta, 58070 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Planetario de Morelia</td>
    <td><a href="https://maps.app.goo.gl/Hgy5p6VEDndQRTyF6" 
      target="_blank"
      rel="noopener noreferrer"
      >Calzada Ventura Puente y, Perif. Paseo de la República S/N, 58070 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Parque Zoológico Benito Juárez</td>
    <td><a href="https://maps.app.goo.gl/ZDyrofrdrHc1u6HU9" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz. Juárez S/N, Félix Ireta, 58070 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Monumento a Los Niños Héroes</td>
    <td><a href="https://maps.app.goo.gl/dibdFKd9tZ8oQbks8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Francisco I. Madero Pte, Centro histórico de Morelia, 58000 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Monumento a Benito Juárez</td>
    <td><a href="https://maps.app.goo.gl/uqP1LBT8V3sR2u1v8" 
      target="_blank"
      rel="noopener noreferrer"
      >Allende 169/73, Centro histórico de Morelia, 58000 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Bosque Cuauhtémoc</td>
    <td><a href="https://maps.app.goo.gl/gtTHSFfpJjxYK1ww9" 
      target="_blank"
      rel="noopener noreferrer"
      >Rafael Carrillo 138, Cuauhtémoc, 58000 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Mercado de Dulces y Artesanías</td>
    <td><a href="https://maps.app.goo.gl/KrSmpxWtKtx3vjjN8" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro histórico de Morelia, 58000 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Plaza U</td>
    <td><a href="https://maps.app.goo.gl/sQnFrPDB7cBfRu8s6" 
      target="_blank"
      rel="noopener noreferrer"
      >Avenida Universidad 1755, Fraccionamiento Real Universidad, 58060 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Teatro Stella Inda</td>
    <td><a href="https://maps.app.goo.gl/EJKkVGEkHwsY8ckL6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Francisco I. Madero Pte 1200, Centro histórico de Morelia, 58000 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Jardín de Las Rosas</td>
    <td><a href="https://maps.app.goo.gl/1yYSPvLR5mQvefgdA" 
      target="_blank"
      rel="noopener noreferrer"
      >C. de Santiago Tapia, Centro histórico de Morelia, 58000 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Plaza del Carmen</td>
    <td><a href="https://maps.app.goo.gl/GCgQRmVPHkFPE5EbA" 
      target="_blank"
      rel="noopener noreferrer"
      >C. de Eduardo Ruiz 31, Centro histórico de Morelia, 58000 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Museo del Dulce Calle Real</td>
    <td><a href="https://maps.app.goo.gl/jD1iWieC4MY8E74q8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Francisco I. Madero Ote 440, Centro histórico de Morelia, 58000 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Mercado Revolución</td>
    <td><a href="https://maps.app.goo.gl/wEpTdHHXa7KHe6pn8" 
      target="_blank"
      rel="noopener noreferrer"
      >Revolución, Centro histórico de Morelia, 58000 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Fuente de las Tarascas</td>
    <td><a href="https://maps.app.goo.gl/413EsNN1r9Jxa97d8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Acueducto 890, Centro histórico de Morelia, 58000 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Mercado Independencia</td>
    <td><a href="https://maps.app.goo.gl/o1f8RbNeZP4qX3G46" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Lázaro Cárdenas 526, Ventura Puente, 58020 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Parque Municipal Gral. Emiliano Zapata</td>
    <td><a href="https://maps.app.goo.gl/1Y3x1GAVLkaXfSxb8" 
      target="_blank"
      rel="noopener noreferrer"
      >J. Jesús Valle, Emiliano Zapata, 62744 Cuautla, Mor.</a></td>
  </tr>
  <tr>
    <td>Centro Cultural Teopanzolco</td>
    <td><a href="https://maps.app.goo.gl/bAqkcDBP8nap1Rkq5" 
      target="_blank"
      rel="noopener noreferrer"
      >Río Balsas 22, Vista Hermosa, 62290 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Plaza de Armas</td>
    <td><a href="https://maps.app.goo.gl/4jQEHEqNaXGAC2FMA" 
      target="_blank"
      rel="noopener noreferrer"
      >Plaza de Armas S/N, Cuernavaca Centro, Centro, 62000 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Museo Robert Brady</td>
    <td><a href="https://maps.app.goo.gl/e84eGRvuHJyBzyBC7" 
      target="_blank"
      rel="noopener noreferrer"
      >Netzahualcoyotl 4, Cuernavaca Centro, Centro, 62000 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Museo Regional de los Pueblos de Morelos (Palacio de Cortés)</td>
    <td><a href="https://maps.app.goo.gl/TpMGSVRV5xYkYH6Q9" 
      target="_blank"
      rel="noopener noreferrer"
      >Francisco Leyva 100, Cuernavaca Centro, Centro, 62000 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>MMAC Juan Soriano</td>
    <td><a href="https://maps.app.goo.gl/hdd8BNoM24PiVqaj7" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Dr. A. Nápoles Gandara, Amatitlán, 62410 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Galerías Cuernavaca</td>
    <td><a href="https://maps.app.goo.gl/3BxJADMMdNFF3g3S8" 
      target="_blank"
      rel="noopener noreferrer"
      >Autopista México - Acapulco, Blvd. del Lago km 87.5, 62370 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Fórum Cuernavaca</td>
    <td><a href="https://maps.app.goo.gl/Th9wcMD3kwkm28qH7" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Jacarandas 103, Ricardo Flores Magon, 62376 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Monumento a la Revolución Mexicana</td>
    <td><a href="https://maps.app.goo.gl/83kNqhP8Hqopspf16" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Plan de Ayala, Satelite, Bosques de Chapultepec, 62460 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Iglesia San Nicolas de Tolentino</td>
    <td><a href="https://maps.app.goo.gl/yx1JqnNA5Sww4EvC7" 
      target="_blank"
      rel="noopener noreferrer"
      >José María Morelos 25, Ahuatepec, 62300 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Papalote Museo del Niño</td>
    <td><a href="https://maps.app.goo.gl/rS49JmBqoocoSAmo8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Vicente Guerrero 205, Lomas de la Selva, 62270 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Centro Comercial Las Plazas</td>
    <td><a href="https://maps.app.goo.gl/YsJUU1HiTEQj2Z4UA" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Gutemberg 3, Cuernavaca Centro, Centro, 62000 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Monumento a Benito Juárez</td>
    <td><a href="https://maps.app.goo.gl/wrJBv9vL4j9prYJXA" 
      target="_blank"
      rel="noopener noreferrer"
      >Glorieta, Cuernavaca Centro, Centro, 62000 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Parque Puerta Paraiso</td>
    <td><a href="https://maps.app.goo.gl/wFewbPQ4siHWGGHs9" 
      target="_blank"
      rel="noopener noreferrer"
      >63173 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Museo Interactivo de Ciencias e innovación de Nayarit</td>
    <td><a href="https://maps.app.goo.gl/HW7bXq4CeQV23Zqr8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. de la Salud s/n, Sin Referencia, 63173 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Parque a la Madre</td>
    <td><a href="https://maps.app.goo.gl/RKQCUZxYU7vJc1q7A" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Ignacio Allende Pte 308, Centro, 63000 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Parque Indio Mariano</td>
    <td><a href="https://maps.app.goo.gl/m5QDzaQEUtEdhWLy9" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 3 43, El Rodeo, 63060 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Parque Juan Escutia</td>
    <td><a href="https://maps.app.goo.gl/mqKeWHUEqgskjqR56" 
      target="_blank"
      rel="noopener noreferrer"
      >Sebastián Lerdo de Tejada Pte. 390, Centro, 63000 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Forum Tepic</td>
    <td><a href="https://maps.app.goo.gl/LW9ervsJoHZrKNYJ8" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvrd Luis Donaldo Colosio 680, Benito Juárez Oriente, 63175 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Plaza Manglar</td>
    <td><a href="https://maps.app.goo.gl/8jepD8QyVVRm5Zs79" 
      target="_blank"
      rel="noopener noreferrer"
      >Plaza Manglar, Av. de la Cultura 94-local 35, Los Fresnos Oriente, Los Fresnos, 63190 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Museo Regional de Nayarit</td>
    <td><a href="https://maps.app.goo.gl/cb5wXxXUZgY7zSNq5" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. México Nte. 91, Centro, 63000 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Glorieta del Angel de la Independencia</td>
    <td><a href="https://maps.app.goo.gl/inurunQ4dbyaNLi57" 
      target="_blank"
      rel="noopener noreferrer"
      >Eucalipto 138, Cd del Valle, 63157 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Casa Museo Amado Nervo</td>
    <td><a href="https://maps.app.goo.gl/s2eHTHgCRzm75Zft9" 
      target="_blank"
      rel="noopener noreferrer"
      >Zacatecas no. 284 Norte, Centro, 63000 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Teatro del Pueblo Alí Chumacero</td>
    <td><a href="https://maps.app.goo.gl/cNuL3xHr89nTd3QPA" 
      target="_blank"
      rel="noopener noreferrer"
      >Avenida Juan Escutia s/n, Centro, 63000 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Parque La Loma</td>
    <td><a href="https://maps.app.goo.gl/7Rtx7T6yPdxxKeFy9" 
      target="_blank"
      rel="noopener noreferrer"
      >Puebla 239, Centro, 63000 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Alameda de Tepic</td>
    <td><a href="https://maps.app.goo.gl/uC9ZqfDza9AwL9vB8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Ignacio Allende Pte 399-434, Centro, 63000 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>El Llano</td>
    <td><a href="https://maps.app.goo.gl/4hJZGUgmhBUogy5MA" 
      target="_blank"
      rel="noopener noreferrer"
      >RUTA INDEPENDENCIA, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Zócalo (Plaza de la Constitución)</td>
    <td><a href="https://maps.app.goo.gl/r2YQXPCvoQJ6gmLT7" 
      target="_blank"
      rel="noopener noreferrer"
      >Portal del Palacio, OAX_RE_BENITO JUAREZ, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Mercado 20 de Noviembre</td>
    <td><a href="https://maps.app.goo.gl/djgK8PPcmmBovdhs8" 
      target="_blank"
      rel="noopener noreferrer"
      >20 de Noviembre 512, OAX_RE_BENITO JUAREZ, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Mercado de la Merced</td>
    <td><a href="https://maps.app.goo.gl/cuNUtgY73KG1XCB8A" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. José María Morelos 1522A, RUTA INDEPENDENCIA, Centro, 68000 Centro, Oax.</a></td>
  </tr>
  <tr>
    <td>Museo de Arte Prehispánico de México Rufino Tamayo</td>
    <td><a href="https://maps.app.goo.gl/UbxRjfQwVACov61r6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. José María Morelos 503, RUTA INDEPENDENCIA, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Parque Francisco I Madero</td>
    <td><a href="https://maps.app.goo.gl/juxV8demNcTV7hdeA" 
      target="_blank"
      rel="noopener noreferrer"
      >Francisco I. Madero s/n, Santa María del Marquesado, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Plaza Mazari</td>
    <td><a href="https://maps.app.goo.gl/D9FpvEBpp8QhTSdT9" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz. Porfirio Díaz 256, Reforma, 68050 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Fuente de las 8 Regiones</td>
    <td><a href="https://maps.app.goo.gl/h6fnYBuc1nZwdCFh6" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz. Porfirio Díaz S/N, Reforma, 68050 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Andador Turístico</td>
    <td><a href="https://maps.app.goo.gl/WAUSwLWtoqqamCkw8" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Macedonio Alcalá 100, RUTA INDEPENDENCIA, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Barrio Xochimilco</td>
    <td><a href="https://maps.app.goo.gl/zbgocHmNaKF4wVkE8" 
      target="_blank"
      rel="noopener noreferrer"
      >Cosijopí 217, RUTA INDEPENDENCIA, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Basílica de Nuestra Señora de la Soledad</td>
    <td><a href="https://maps.app.goo.gl/Mbg2J6aSBMGnXLXE7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. de la Independencia 107, CALZADA MADERO, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Plaza de la Danza</td>
    <td><a href="https://maps.app.goo.gl/Gv4DQua9AtX9HmEe7" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 2 de Abril, CALZADA MADERO, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Mercado Benito Juárez</td>
    <td><a href="https://maps.app.goo.gl/yTW5af9zuhYsuV4d8" 
      target="_blank"
      rel="noopener noreferrer"
      >Las Casas S/N, OAX_RE_BENITO JUAREZ, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Mercado de Artesanías</td>
    <td><a href="https://maps.app.goo.gl/6Zo5FsubZhSGGFcTA" 
      target="_blank"
      rel="noopener noreferrer"
      >Gral. Ignacio Zaragoza, OAX_RE_BENITO JUAREZ, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Plaza de los Sentimientos de la Nación</td>
    <td><a href="https://maps.app.goo.gl/kzctszBhbpbAf9J2A" 
      target="_blank"
      rel="noopener noreferrer"
      >Flores Magón, Valerio Trujano esq, Centro, 68000 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Mercado del Arte Mexicano</td>
    <td><a href="https://maps.app.goo.gl/p6wYyja8m38WBuBR8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Tankah 25, 77509 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Las Plazas Outlet Cancún</td>
    <td><a href="https://maps.app.goo.gl/St6EobR2rGCyJUM7A" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Andrés Quintana Roo 39-39, 77500 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Plaza Las Américas</td>
    <td><a href="https://maps.app.goo.gl/URWyQHv4VCmXQK4M9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Tulum Sur Supermanzana 7, 77500 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Monumento a Leona Vicario</td>
    <td><a href="https://maps.app.goo.gl/h5tR3rqHPJ7dAk6bA" 
      target="_blank"
      rel="noopener noreferrer"
      >Supermanzana 36, 77505 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Parque Los Fundadores</td>
    <td><a href="https://maps.app.goo.gl/x8w1QM2a2qP4PZvPA" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Benito Juárez, Centro, 77713 Playa del Carmen, Q.R.</a></td>
  </tr>
  <tr>
    <td>Museo Frida Kahlo Riviera Maya</td>
    <td><a href="https://maps.app.goo.gl/qfCvbySf8YwCiEUm9" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle 8 entre 5ta Avenida y el Mar, Centro, 77710 Playa del Carmen, Q.R.</a></td>
  </tr>
  <tr>
    <td>Parque de las Palapas</td>
    <td><a href="https://maps.app.goo.gl/JbeyncFZpgT8k34m8" 
      target="_blank"
      rel="noopener noreferrer"
      >77500, Tulipanes LB, Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Torre Escénica del Embarcadero de Cancún</td>
    <td><a href="https://maps.app.goo.gl/HF1wJyA9HJWrkewA9" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Kukulcan Km. 4.5, Kukulcan Boulevard, Zona Hotelera, 77500 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Interactive Aquarium Cancún</td>
    <td><a href="https://maps.app.goo.gl/Wmk9dLL7DQLfwD668" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Kukulcan 12.5, La Isla, Zona Hotelera, 77500 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Letras Cancún y Mirador</td>
    <td><a href="https://maps.app.goo.gl/qE7tkfPoSJz9faBK9" 
      target="_blank"
      rel="noopener noreferrer"
      >Punta Nizuc - Cancún 335, Zona Hotelera, 77500 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Museo Maya de Cancún y Zona Arqueológica de San Miguelito</td>
    <td><a href="https://maps.app.goo.gl/oULHLGDST3A7L7E2A" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Kukulcan km 16.5, Zona Hotelera, 77500 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Quinta Alegría Shopping Mall</td>
    <td><a href="https://maps.app.goo.gl/EqGyYVE9hbhHwLFJ8" 
      target="_blank"
      rel="noopener noreferrer"
      >Quinta Avenida S/N Esquina con, Av. Constituyentes, Centro, 77710 Playa del Carmen, Q.R.</a></td>
  </tr>
  <tr>
    <td>Marina Puerto Cancún</td>
    <td><a href="https://maps.app.goo.gl/xJcDXsaUCFcQyo4u7" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Kukulcan 1, Puerto Juarez, Zona Hotelera, 77500 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Parque del Artesano</td>
    <td><a href="https://maps.app.goo.gl/E7XYrGQNLtAVL2s1A" 
      target="_blank"
      rel="noopener noreferrer"
      >26, C. Margaritas 26, 77500 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Parque Fonatur</td>
    <td><a href="https://maps.app.goo.gl/ieYRBb3VttZtaigi8" 
      target="_blank"
      rel="noopener noreferrer"
      >Unnamed Road, Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Centro histórico San Luis Potosí</td>
    <td><a href="https://maps.app.goo.gl/fpdnsMAjpwN4jy7X7" 
      target="_blank"
      rel="noopener noreferrer"
      >78000, Jdn. Hidalgo 19, Centro Historico, 78000 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Plaza de Aranzazú</td>
    <td><a href="https://maps.app.goo.gl/GC8WfAKL7gTD7nBS8" 
      target="_blank"
      rel="noopener noreferrer"
      >Hermenegildo Galeana 480, Centro Historico, 78000 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Plaza Fundadores</td>
    <td><a href="https://maps.app.goo.gl/A6gm8nazwwMjtrmT8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Venustiano Carranza, Centro Historico, 78100 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Plaza de Armas San Luis Potosí</td>
    <td><a href="https://maps.app.goo.gl/qLyLq7pmj84GNBMR7" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro Historico, 78000 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Alameda Juan Sarabia</td>
    <td><a href="https://maps.app.goo.gl/Rm3943Dy2YeZ65qq5" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro Historico, 78000 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Jardín de San Francisco</td>
    <td><a href="https://maps.app.goo.gl/nERQUk5SaUuZXB7r9" 
      target="_blank"
      rel="noopener noreferrer"
      >Jdn. Guerrero S/N, Centro Historico, 78000 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Estatua del Señor de las Palomas</td>
    <td><a href="https://maps.app.goo.gl/S1tRBoxqpHzCdo6C6" 
      target="_blank"
      rel="noopener noreferrer"
      >Jdn. Hidalgo 1, Centro Historico, 78000 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Caja del Agua</td>
    <td><a href="https://maps.app.goo.gl/uX3CsKqUyRctPgYy6" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz de Guadalupe 200, Barrio de San Sebastian, 78349 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Mercado República</td>
    <td><a href="https://maps.app.goo.gl/SX7MZCJWDhj3A63m7" 
      target="_blank"
      rel="noopener noreferrer"
      >Reforma 405A, Centro Historico, 78000 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Plaza Tangamanga</td>
    <td><a href="https://maps.app.goo.gl/pfYAKUB3cKWLFKRLA" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Manuel J. Clouthier 263, Tangamanga, 78269 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Mercado Hidalgo</td>
    <td><a href="https://maps.app.goo.gl/w7UAyJoDZTVf7UBQ9" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Miguel Hidalgo S/N, Zona Centro, 78000 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>"Museo del Ferrocarril ""Jesús García Corona"""</td>
    <td><a href="https://maps.app.goo.gl/UNWP6so96zq6yQwW6" 
      target="_blank"
      rel="noopener noreferrer"
      >Manuel José Othón 0, Centro Historico, 78000 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Plaza San Luis</td>
    <td><a href="https://maps.app.goo.gl/6DBPfL8gpmget7nSA" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Antonio Rocha Cordero 700 Fracc, Lomas del Tecnologico, 78216 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Centro Comercial El Dorado</td>
    <td><a href="https://maps.app.goo.gl/VLZbDjTfHpfHSR689" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Nereo Rodríguez Barragán 450, Col del Valle, 78200 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Malecón de Topolobampo</td>
    <td><a href="https://maps.app.goo.gl/gqCZY36qfowXTTDi9" 
      target="_blank"
      rel="noopener noreferrer"
      >Angel Flores 66, Pesquera, 81371 Topolobampo, Sin.</a></td>
  </tr>
  <tr>
    <td>Parque Sinaloa</td>
    <td><a href="https://maps.app.goo.gl/WfHeVfU7wdvR7efn9" 
      target="_blank"
      rel="noopener noreferrer"
      >81245, Sin referencia No. 6 830, Parque Sinaloa, Los Mochis, Sin.</a></td>
  </tr>
  <tr>
    <td>Paseo Los Mochis</td>
    <td><a href="https://maps.app.goo.gl/tT9TwL4dnFGEfS5L7" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd Centenario, Centro, 81271 Los Mochis, Sin.</a></td>
  </tr>
  <tr>
    <td>Parque Las Riberas</td>
    <td><a href="https://maps.app.goo.gl/EYLAbX6mJR7NDSNX9" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Francisco Labastida Ochoa S/N, Desarrollo Urbano Tres Ríos, 80230 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Zoológico de Culiacán</td>
    <td><a href="https://maps.app.goo.gl/nJ1VHzYsPfFZmp48A" 
      target="_blank"
      rel="noopener noreferrer"
      >P.º Niños Heroes, Las Quintas, 80060 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>MASIN Museo de Arte de Sinaloa</td>
    <td><a href="https://maps.app.goo.gl/r5P817dmmSfzZJMA7" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Gral Rafael Buelna Tenorio S/N, Primer Cuadro, 80000 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Catedral - Basílica de Nuestra Señora del Rosario</td>
    <td><a href="https://maps.app.goo.gl/NNesBXdiXuxgtCrZ8" 
      target="_blank"
      rel="noopener noreferrer"
      >Prol. Álvaro Obregón S/N, Primer Cuadro, Centro, 80000 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Parque Culiacán 87</td>
    <td><a href="https://maps.app.goo.gl/J1ejx7KschPbPDyZ8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. México 68, Parque Culiacan 87, República Mexicana, 80147 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Museo Regional de Sinaloa</td>
    <td><a href="https://maps.app.goo.gl/xUkFgTxUeJh3Uih86" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Gral Rafael Buelna Tenorio S/N, Las Quintas, Col. Centro, 80000 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Jardín Botánico Culiacán</td>
    <td><a href="https://maps.app.goo.gl/F7B76FLVGq1UJksWA" 
      target="_blank"
      rel="noopener noreferrer"
      >Av, Calz de las Americas Nte No 2131, Burócrata, 80040 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Forum Culiacán Centro Comercial</td>
    <td><a href="https://maps.app.goo.gl/VpjFFpdpM8QDXQhdA" 
      target="_blank"
      rel="noopener noreferrer"
      >Diego Valadés Ríos 1676, Desarrollo Urbano Tres Ríos, 80000 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Plaza Sendero Culiacán</td>
    <td><a href="https://maps.app.goo.gl/DCHytA66cDpYbXke8" 
      target="_blank"
      rel="noopener noreferrer"
      >Sendero Culiacán Boulevard José Limon, Humaya No. 2545, C.P. 8002 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Plaza Carolinas</td>
    <td><a href="https://maps.app.goo.gl/dxn9Zg2RKK6crHja7" 
      target="_blank"
      rel="noopener noreferrer"
      >80059 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Centro de Ciencias de Sinaloa</td>
    <td><a href="https://maps.app.goo.gl/nAmTrFwX8RUNqfwp9" 
      target="_blank"
      rel="noopener noreferrer"
      >Calz de las Americas Nte 2771-Nte, Burócrata, 80010 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Estadio Tomateros</td>
    <td><a href="https://maps.app.goo.gl/peuQVrTCBEXEwV7x6" 
      target="_blank"
      rel="noopener noreferrer"
      >Avenida Jesús Andrade S/N, Miguel Alemán, 80200 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Monumento al Pescador</td>
    <td><a href="https://maps.app.goo.gl/6HFmYfFa1yA48HyA9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av del Mar s/n, Centro, 82000 Mazatlán, Sin.</a></td>
  </tr>
  <tr>
    <td>Monumento a La Continuidad de La Vida del Deno</td>
    <td><a href="https://maps.app.goo.gl/Gv8QpJh5n1qVATYk6" 
      target="_blank"
      rel="noopener noreferrer"
      >P.º Claussen, Centro, 82000 Mazatlán, Sin.</a></td>
  </tr>
  <tr>
    <td>Catedral - Basílica de la Inmaculada Concepción</td>
    <td><a href="https://maps.app.goo.gl/xYHdoFJcTfRQwdBEA" 
      target="_blank"
      rel="noopener noreferrer"
      >21 de Marzo, Centro, 82000 Mazatlán, Sin.</a></td>
  </tr>
  <tr>
    <td>Malecón Mazatlán</td>
    <td><a href="https://maps.app.goo.gl/w5dUeWp6Ru21gsrW6" 
      target="_blank"
      rel="noopener noreferrer"
      >Tellería, 82149 Mazatlán, Sin.</a></td>
  </tr>
  <tr>
    <td>Mercado Municipal López Mateos</td>
    <td><a href="https://maps.app.goo.gl/Y3xA6f3d3HQDrmNd7" 
      target="_blank"
      rel="noopener noreferrer"
      >Netzahualcóyotl s/n, Lopez Mateos, 82140 Mazatlán, Sin.</a></td>
  </tr>
  <tr>
    <td>Parque Lineal - Tlamatini Tochihuitl</td>
    <td><a href="https://maps.app.goo.gl/unkkUxLyKrhSBRu68" 
      target="_blank"
      rel="noopener noreferrer"
      >Mazatlan s/n, Jaripillo, 82136 Mazatlán, Sin.</a></td>
  </tr>
  <tr>
    <td>Gran Plaza Mazatlán</td>
    <td><a href="https://maps.app.goo.gl/nMhsRoZVkK64Mv2X6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Reforma s/n, Alameda, 82123 Mazatlán, Sin.</a></td>
  </tr>
  <tr>
    <td>Letras Turísticas De Hermosillo</td>
    <td><a href="https://maps.app.goo.gl/SDxd4Numbc9ScfKh7" 
      target="_blank"
      rel="noopener noreferrer"
      >Mariano Matamoros SN-S, Centro, 83000 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Museo Regional de Sonora</td>
    <td><a href="https://maps.app.goo.gl/9XwrmuqoeCQfVAj18" 
      target="_blank"
      rel="noopener noreferrer"
      >Jesús García Final s/n, La Matanza, 83080 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Catedral Metropolitana de Nuestra Señora de la Asunción</td>
    <td><a href="https://maps.app.goo.gl/TapA1JXLdqkhMA5Q6" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvr. Miguel Hidalgo S/N, Centro Norte, 83000 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Museo de Arte de Sonora</td>
    <td><a href="https://maps.app.goo.gl/U2JKs4unMt9oBYLz6" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro Cultural Musas, Blvd. Agustín de Vildósola S/N, Proyecto Rio Sonora Hermosillo XXI, 83270 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Plaza Dila</td>
    <td><a href="https://maps.app.goo.gl/AT7pjVXdcBhMYYej6" 
      target="_blank"
      rel="noopener noreferrer"
      >Bulevar José María Morelos 349, Bugambilia, 83140 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Parque Tutuli</td>
    <td><a href="https://maps.app.goo.gl/C1AZT52WXVawPzSS7" 
      target="_blank"
      rel="noopener noreferrer"
      >C. Garmendia, Modelo, 83190 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Monumento Miguel Hidalgo</td>
    <td><a href="https://maps.app.goo.gl/R4g8dnZDuGxCawgS7" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Gral Álvaro Obregón, Centro, 83000 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Monumento a la Revolución</td>
    <td><a href="https://maps.app.goo.gl/pH3rNcDRz8UJsLuA6" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Jesús García, Centro, 83000 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Plaza Girasol</td>
    <td><a href="https://maps.app.goo.gl/p9NUZphyvWijR7y99" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Solidaridad 56, Sahuaro, 83171 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Kiosco</td>
    <td><a href="https://maps.app.goo.gl/xsCWyKxFhog3t3HU8" 
      target="_blank"
      rel="noopener noreferrer"
      >Jesús García Corona, Centro, 83000 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Plaza Santa Fe</td>
    <td><a href="https://maps.app.goo.gl/1pg1nQHmW5f1mQW26" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Solidaridad, Santa Fe, 83249 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Plaza Cine Sonora</td>
    <td><a href="https://maps.app.goo.gl/mMan6pgNWhjrNza27" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Luis Donaldo Colosio Murrieta SN, S,Centro, 83000 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Galerías Mall Sonora</td>
    <td><a href="https://maps.app.goo.gl/gunmNeWowsyA6htG8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Cultura 55 Ote, Proyecto Rio Sonora Hermosillo XXI, 83280 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Plaza Emiliana De Zubeldia</td>
    <td><a href="https://maps.app.goo.gl/igTJnWGBdZNL9Ur98" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro, 83000 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Monumento a Benito Juárez</td>
    <td><a href="https://maps.app.goo.gl/jXnmxmAo2cxX1twV7" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvr. P.º Río Sonora, Proyecto Río Sonora, Proyecto Rio Sonora Hermosillo XXI, 83270 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Parque La Choca</td>
    <td><a href="https://maps.app.goo.gl/PMutxXxj3iVaQLzU6" 
      target="_blank"
      rel="noopener noreferrer"
      >86035, Av Paseo Tabasco 1998, Multiochenta, 86035 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Parque Museo La Venta</td>
    <td><a href="https://maps.app.goo.gl/T4Zu5k62MVBC3Pw18" 
      target="_blank"
      rel="noopener noreferrer"
      >Boulevard Adolfo Ruiz Cortines S/N, Jesus Garcia, 86040 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Parque Tomás Garrido Canabal</td>
    <td><a href="https://maps.app.goo.gl/yu9w5HqEuQwL4Mvu5" 
      target="_blank"
      rel="noopener noreferrer"
      >Jesus Garcia, 86040 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Casa De Los Azulejos</td>
    <td><a href="https://maps.app.goo.gl/n58FApuxKtASQzQL8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. 27 de Febrero, Centro Delegacion Dos, 86000 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Catedral del Señor de Tabasco</td>
    <td><a href="https://maps.app.goo.gl/25nUYe9tFyQEw8Dz6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Paseo Tabasco 606, centro, 86000 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Plaza Altabrisa Tabasco</td>
    <td><a href="https://maps.app.goo.gl/EaqxSpeaBfxGAboe9" 
      target="_blank"
      rel="noopener noreferrer"
      >Perif. Carlos Pellicer Cámara #129, Real del Sur, Col. 1° de Mayo, 86190 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Galerías Tabasco</td>
    <td><a href="https://maps.app.goo.gl/C3JfCm7vyqQcSNDM6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Paseo Tabasco 1405, Colonia Desarrollo Urbano, 86035 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Plaza City Center Vh</td>
    <td><a href="https://maps.app.goo.gl/iBVBCsu6ZWfs1yc16" 
      target="_blank"
      rel="noopener noreferrer"
      >Plaza City Center, Paseo Usumacinta, Boulevard Adolfo Ruiz Cortines S/N, Atasta de Serra, 86035 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Plaza Sendero Villahermosa</td>
    <td><a href="https://maps.app.goo.gl/vAnxUwjeDyJ4m3Vu5" 
      target="_blank"
      rel="noopener noreferrer"
      >Perif. Carlos Pellicer Cámara 1020, Jose Maria Pino Suarez, 86029 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Monumento a Los Niños Héroes de Chapultepec</td>
    <td><a href="https://maps.app.goo.gl/ikMqxJhjaGfNpdEg9" 
      target="_blank"
      rel="noopener noreferrer"
      >Prol. P.º Usumacinta, Gil y Saenz, 86080 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Monumento José María Morelos</td>
    <td><a href="https://maps.app.goo.gl/dtiLYFUqtehRSyu1A" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. 27 de Febrero, Centro Delegacion Uno, 86000 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>"Plaza de Armas, Centro"</td>
    <td><a href="https://maps.app.goo.gl/5criLBbvZ19S4NvK7" 
      target="_blank"
      rel="noopener noreferrer"
      >Independencia, Jose Maria Pino Suárez, Centro Delegacion Uno, 86000 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Plaza Strada</td>
    <td><a href="https://maps.app.goo.gl/inz6mDgqp99upqgs6" 
      target="_blank"
      rel="noopener noreferrer"
      >Juan Estrada Torre 101, Primero de Mayo, 86190 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Plaza Bicentenario</td>
    <td><a href="https://maps.app.goo.gl/JGGxBr9uot3NHqim7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. 27 de Febrero Independencia esquina, Centro Delegacion Uno, 86000 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Centro de Villahermosa</td>
    <td><a href="https://maps.app.goo.gl/hyBhNfcYUqYKFPn76" 
      target="_blank"
      rel="noopener noreferrer"
      >Independencia 377, Jose Maria Pino Suárez, Centro Delegacion Uno, 86029 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Plaza de Armas Tampico</td>
    <td><a href="https://maps.app.goo.gl/3mfkxc1fUgqQpWHAA" 
      target="_blank"
      rel="noopener noreferrer"
      >Salvador Díaz Mirón 108, Zona Centro, 89000 Tampico, Tamps.</a></td>
  </tr>
  <tr>
    <td>Parque Metropolitano de Tampico</td>
    <td><a href="https://maps.app.goo.gl/ChtFM9q4kyQ7sFue8" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Fidel Velázquez, Laguna del Carpintero, 89040 Tampico, Tamps.</a></td>
  </tr>
  <tr>
    <td>"Letras monumentales ""Tampico"""</td>
    <td><a href="https://maps.app.goo.gl/uW21RyUNEUWiBxtF7" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Miguel Hidalgo, Sin Colonia, 89339 Tampico, Tamps.</a></td>
  </tr>
  <tr>
    <td>Plaza de la Libertad</td>
    <td><a href="https://maps.app.goo.gl/wo4uQfuxmwDCH31R6" 
      target="_blank"
      rel="noopener noreferrer"
      >Plaza de la Libertad, Benito Juárez 306, Zona Centro, 89000 Tampico, Tamps.</a></td>
  </tr>
  <tr>
    <td>Catedral de Tampico</td>
    <td><a href="https://maps.app.goo.gl/Hy5SM1zoD114Uw4Y9" 
      target="_blank"
      rel="noopener noreferrer"
      >Cap. Emilio Carranza 101, Zona Centro, 89000 Tampico, Tamps.</a></td>
  </tr>
  <tr>
    <td>Galerías Altama</td>
    <td><a href="https://maps.app.goo.gl/t8dMM75ndY9JSoKg9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Ejército Mexicano 706, Colonias Primavera, 89130 Tampico, Tamps.</a></td>
  </tr>
  <tr>
    <td>3 ARCOS Centro Comercial</td>
    <td><a href="https://maps.app.goo.gl/A6nBxb4kskzA76Jk9" 
      target="_blank"
      rel="noopener noreferrer"
      >Regiomontana S/N, El Naranjal, 89106 Tampico, Tamps.</a></td>
  </tr>
  <tr>
    <td>Plaza La Pirámide</td>
    <td><a href="https://maps.app.goo.gl/xYY8sJsyi2SkX5Uf8" 
      target="_blank"
      rel="noopener noreferrer"
      >Carrillo Puerto, Ampliacion Unidad Nacional, 89510 Cd Madero, Tamps.</a></td>
  </tr>
  <tr>
    <td>Museo de Historia Natural de Tamaulipas TAMUX</td>
    <td><a href="https://maps.app.goo.gl/ezNGJLaRi8DHQtcz5" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. Fidel Velazquez s/n, Horacio Terán, 87130 Cdad. Victoria, Tamps.</a></td>
  </tr>
  <tr>
    <td>Museo Regional de Historia de Tamaulipas</td>
    <td><a href="https://maps.app.goo.gl/Qb7We2xJ5cTqzd3r7" 
      target="_blank"
      rel="noopener noreferrer"
      >22 Allende S/N, Zona Centro, 87000 Cdad. Victoria, Tamps.</a></td>
  </tr>
  <tr>
    <td>"Parque de Bienestar ""La Loma"""</td>
    <td><a href="https://maps.app.goo.gl/JqBxEybdVtH2qoAd6" 
      target="_blank"
      rel="noopener noreferrer"
      >87120 Calle Procuraduria, Lauro Aguirre esquina, Burócratas Estatales, 87000 Cdad. Victoria, Tamps.</a></td>
  </tr>
  <tr>
    <td>Teatro Juárez</td>
    <td><a href="https://maps.app.goo.gl/ZhVnUqFWVWwzX6mc9" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Cristóbal Colón Nte., Zona Centro, 87000 Cdad. Victoria, Tamps.</a></td>
  </tr>
  <tr>
    <td>Plaza 844 Victoria</td>
    <td><a href="https://maps.app.goo.gl/8ZU4AubsAMhNQgQZ6" 
      target="_blank"
      rel="noopener noreferrer"
      >Gral. Lázaro Cárdenas Eje Vial Nte. - Sur 844-L6, Héroe de Nacozari, 87030 Cdad. Victoria, Tamps.</a></td>
  </tr>
  <tr>
    <td>Parque lineal Francisco I. Madero</td>
    <td><a href="https://maps.app.goo.gl/F1d52XCyJhpidBRy5" 
      target="_blank"
      rel="noopener noreferrer"
      >Avenida Francisco I. Madero (17 137, Zona Centro, 87000 Cdad. Victoria, Tamps.</a></td>
  </tr>
  <tr>
    <td>Plaza Hidalgo</td>
    <td><a href="https://maps.app.goo.gl/F7RfRfmY9AH6TLDE6" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle Cristóbal Colón Nte. 126-148, Zona Centro, 87000 Cdad. Victoria, Tamps.</a></td>
  </tr>
  <tr>
    <td>Parque Recreativo y Deportivo de La Juventud</td>
    <td><a href="https://maps.app.goo.gl/YkfK6LQn4ACH63GQ7" 
      target="_blank"
      rel="noopener noreferrer"
      >Heroico Colegio Militar 5, Centro, 90000 Tlaxcala de Xicohténcatl, Tlax.</a></td>
  </tr>
  <tr>
    <td>Plaza Xicohténcatl</td>
    <td><a href="https://maps.app.goo.gl/n5Q9Q2YmGP8v51AXA" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Independencia 3, Centro, 90062 Tlaxcala de Xicohténcatl, Tlax.</a></td>
  </tr>
  <tr>
    <td>Catedral de Nuestra Señora de la Asunción de Tlaxcala</td>
    <td><a href="https://maps.app.goo.gl/77Luwq4167y3thGLA" 
      target="_blank"
      rel="noopener noreferrer"
      >San Francisco, Centro, 90000 Tlaxcala de Xicohténcatl, Tlax.</a></td>
  </tr>
  <tr>
    <td>Parque Benito Juárez</td>
    <td><a href="https://maps.app.goo.gl/y9f52sAh5cdp12pX9" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. 20 de Noviembre 24, Centro, 90000 Centro, Tlax.</a></td>
  </tr>
  <tr>
    <td>Zócalo de la Ciudad Tlaxcala</td>
    <td><a href="https://maps.app.goo.gl/VQMFgVpTGaUJLx7DA" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro, 90000 Tlaxcala de Xicohténcatl, Tlax.</a></td>
  </tr>
  <tr>
    <td>Mercado Municipal Emilio Sánchez Piedras</td>
    <td><a href="https://maps.app.goo.gl/pvpwNnK4KbL7DCsn6" 
      target="_blank"
      rel="noopener noreferrer"
      >Mercado municipal Emilio Sánchez Piedras Int del mercado, Centro, 90000 Tlaxcala de Xicohténcatl, Tlax.</a></td>
  </tr>
  <tr>
    <td>Museo de la Memoria</td>
    <td><a href="https://maps.app.goo.gl/5RZbhee8oVRWFk2S8" 
      target="_blank"
      rel="noopener noreferrer"
      >Av Independencia 3, Centro, 90000 Tlaxcala de Xicohténcatl, Tlax.</a></td>
  </tr>
  <tr>
    <td>Quiosco Parque Tlaxcala</td>
    <td><a href="https://maps.app.goo.gl/ZS7m3WJUBTLrnwyA7" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro, 90000 Tlaxcala de Xicohténcatl, Tlax.</a></td>
  </tr>
  <tr>
    <td>Capilla del Pocito Tlaxcala</td>
    <td><a href="https://maps.app.goo.gl/su3EsrWSfG9uKXnh9" 
      target="_blank"
      rel="noopener noreferrer"
      >Del Agua Santa 7, Ocotlán, 90100 Tlaxcala de Xicohténcatl, Tlax.</a></td>
  </tr>
  <tr>
    <td>Parque de Santa Lucía</td>
    <td><a href="https://maps.app.goo.gl/oG1tuFoiat3NBGGt8" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 60 476A, Parque Santa Lucia, Centro, 97000 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Letras Mérida</td>
    <td><a href="https://maps.app.goo.gl/XdbppxZkmRwxb3bP8" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 62 498-500, Centro, 97000 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Macroplaza</td>
    <td><a href="https://maps.app.goo.gl/J5CfNGSGDfnVvVAB6" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 33, Colonia itzimna, Polígono 108, 97143 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>"Plaza Principal de Mérida ""Plaza Grande"""</td>
    <td><a href="https://maps.app.goo.gl/FovBhdY2RUkpjxbg7" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 60 S/N, Centro, 97000 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>El Cohete</td>
    <td><a href="https://maps.app.goo.gl/SAu6VztyDnC34xvb7" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle 20 A x 35 y, Cto. Colonias, Álamos Nueva Alemán, 97146 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Parque San Juan</td>
    <td><a href="https://maps.app.goo.gl/UueTzXNucypQHk3z7" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 67A 529, Centro, 97000 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>"Gran Parque ""La Plancha"""</td>
    <td><a href="https://maps.app.goo.gl/YRfdt3aA7RZYQzeE6" 
      target="_blank"
      rel="noopener noreferrer"
      >Centro, 97000 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Mercado San Benito</td>
    <td><a href="https://maps.app.goo.gl/ZdEeDnikvMXtHJTw7" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle 54 s/n, Centro, 97000 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Parque de Santiago</td>
    <td><a href="https://maps.app.goo.gl/8km4x1VioWt98BtaA" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 72, Parque Santiago, Centro, 97000 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Monumento a Manuel Cepeda Peraza</td>
    <td><a href="https://maps.app.goo.gl/o6gkMrHKDsdVQH4g7" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 60 X 59, Centro, 97000 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Monumento a Pedro Infante</td>
    <td><a href="https://maps.app.goo.gl/B4pizvFgcXcA7ESC7" 
      target="_blank"
      rel="noopener noreferrer"
      >Por 62 y 91 Calle 62, S/N, Centro, 97000 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Monumento a la Patria</td>
    <td><a href="https://maps.app.goo.gl/rVQR79Rje9p2afL56" 
      target="_blank"
      rel="noopener noreferrer"
      >P.º de Montejo 438, Centro, 97050 Mérida, Yuc.</a></td>
  </tr>

  <tr>
    <td>Parque de las Américas</td>
    <td><a href="https://maps.app.goo.gl/giq98yHAmqGW3zsa9" 
      target="_blank"
      rel="noopener noreferrer"
      >Por calle 20 y 23, Av. Colón, García Ginerés, 97070 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Galerías Mérida</td>
    <td><a href="https://maps.app.goo.gl/kTK8QVKXvf581T1o6" 
      target="_blank"
      rel="noopener noreferrer"
      >Calle 3 300, entre calle 24 y calle 60, Zona Industrial, Colonia Revolución, 97110 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Paseo 60</td>
    <td><a href="https://maps.app.goo.gl/aP1wqv918N36XpWi6" 
      target="_blank"
      rel="noopener noreferrer"
      >C. 60 346, Zona Paseo Montejo, Centro, 97000 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Parque Sierra de Alica</td>
    <td><a href="https://maps.app.goo.gl/J2oKZrVgXrGtoHjQ7" 
      target="_blank"
      rel="noopener noreferrer"
      >J. Jesús González Ortega 304, Sierra de Alica, 98059 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Fuente de los Faroles</td>
    <td><a href="https://maps.app.goo.gl/jKMZ2mckzBLXcZij8" 
      target="_blank"
      rel="noopener noreferrer"
      >Tacuba, Zacatecas Centro, 98000 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Plazuela Miguel Auza</td>
    <td><a href="https://maps.app.goo.gl/Z4HBVWNG6yPoCPp29" 
      target="_blank"
      rel="noopener noreferrer"
      >Miguel Auza, Zacatecas Centro, 98000 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Museo Zacatecano</td>
    <td><a href="https://maps.app.goo.gl/23PZAiZrYnMFCVd26" 
      target="_blank"
      rel="noopener noreferrer"
      >Dr. Ignacio Hierro 307, Zacatecas Centro, 98000 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Jardín Independencia</td>
    <td><a href="https://maps.app.goo.gl/u44jFfbHBJZa7d9H6" 
      target="_blank"
      rel="noopener noreferrer"
      >Av. Juárez, Zacatecas Centro, 98000 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Monumento Al Migrante</td>
    <td><a href="https://maps.app.goo.gl/6wwzgXuuih8YBaWj7" 
      target="_blank"
      rel="noopener noreferrer"
      >Bvld. López Portillo, Boulevares, 98065 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Monumento a las Culturas</td>
    <td><a href="https://maps.app.goo.gl/3urHu7SwrhfZUdEh6" 
      target="_blank"
      rel="noopener noreferrer"
      >J. Jesús González Ortega, Sierra de Alica, 98059 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Galerías Zacatecas</td>
    <td><a href="https://maps.app.goo.gl/RttqrBwKVYgX2Q7Q8" 
      target="_blank"
      rel="noopener noreferrer"
      >Blvd. El Bote 202, Colonia Ciudad Argentum, 98040 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Plaza Futura</td>
    <td><a href="https://maps.app.goo.gl/YTrBuL5X6wepEWDB6" 
      target="_blank"
      rel="noopener noreferrer"
      >De La Unión 101-S, Zona A, Zacatecas Centro, 98000 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Plaza Alessia Zacatecas</td>
    <td><a href="https://maps.app.goo.gl/G94WTaLy7GuqMHhu5" 
      target="_blank"
      rel="noopener noreferrer"
      >Zona A, Minera, 98050 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Plaza De Las Artesanías</td>
    <td><a href="https://maps.app.goo.gl/zpwjECSJT6CjDWKN6" 
      target="_blank"
      rel="noopener noreferrer"
      >Tacuba 150, Zacatecas Centro, 98000 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Museo Manuel Felguérez</td>
    <td><a href="https://maps.app.goo.gl/n4XWyLK7esZN372b7" 
      target="_blank"
      rel="noopener noreferrer"
      >Cristóbal Colón, Zacatecas Centro, 98000 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Parque Alfonso Esparza Oteo</td>
    <td><a href="https://maps.app.goo.gl/2FrosL1LNJ5dVWLc7"
      target="_blank" rel="noopener noreferrer">Georgia, Nápoles, Benito Juárez, 03810 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Monumental Plaza de Toros México</td>
    <td><a href="https://maps.app.goo.gl/HsfabHh2hEYNeV348"
      target="_blank" rel="noopener noreferrer">Cda. Augusto Rodin 130, Cd. de los Deportes, Benito Juárez, 03710 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Pepsi Center WTC</td>
    <td><a href="https://maps.app.goo.gl/xy1w8L6z4prU1MAk6"
      target="_blank" rel="noopener noreferrer">Dakota s/n, Nápoles, Benito Juárez, 03810 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Parque de los Venados</td>
    <td><a href="https://maps.app.goo.gl/V2w9KWDV9HSoPTiXA"
      target="_blank" rel="noopener noreferrer">Avenida División del Norte y Calle Doctor José María Vértiz, Sta Cruz Atoyac, Benito Juárez, 03310 CDMX</a></td>
  </tr>
  <tr>
    <td>Museo Frida Kahlo</td>
    <td><a href="https://maps.app.goo.gl/DRLCgMEAs1fQM9fi8"
      target="_blank" rel="noopener noreferrer">Londres 247, Del Carmen, Coyoacán, 04100 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Oasis Coyoacán</td>
    <td><a href="https://maps.app.goo.gl/DipHouaRpncA4L2M7"
      target="_blank" rel="noopener noreferrer">Av. Universidad 1770, Romero de Terreros, Coyoacán, 04310 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Parque Tezontle</td>
    <td><a href="https://maps.app.goo.gl/qux8xxxt7gt7RuQt5"
      target="_blank" rel="noopener noreferrer">Canal de Tezontle 1512, Dr Alfonso Ortiz Tirado, Iztapalapa, 09020 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Parque Lindavista</td>
    <td><a href="https://maps.app.goo.gl/bCMgAXze1vTZ9uLQ6"
      target="_blank" rel="noopener noreferrer">Colector 13 280, Magdalena de las Salinas, Gustavo A. Madero, 07760 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Toreo Parque Central</td>
    <td><a href="https://maps.app.goo.gl/nsC3tBrukkSP1nKF7"
      target="_blank" rel="noopener noreferrer">Perif. Blvd. Manuel Ávila Camacho 5, Lomas de Sotelo, 53390 Naucalpan de Juárez, Méx.</a></td>
  </tr>
  <tr>
    <td>Hipódromo de las Américas</td>
    <td><a href="https://maps.app.goo.gl/J4t3WnsNc8j6B2GV7"
      target="_blank" rel="noopener noreferrer">Lomas de Sotelo, Hipódromo de las Américas, Miguel Hidalgo, 11610 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Papalote Museo del Niño</td>
    <td><a href="https://maps.app.goo.gl/wZqtaW8Q9VGVjdzH9"
      target="_blank" rel="noopener noreferrer">Av Constituyentes 268, Bosque de Chapultepec II Secc, Miguel Hidalgo, 11100 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Galerías Insurgentes</td>
    <td><a href="https://maps.app.goo.gl/U8RWMthUFF3HKagHA"
      target="_blank" rel="noopener noreferrer">Parroquia 194, Colonia del Valle, Benito Juárez, 03100 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Club Campestre de la Ciudad de México</td>
    <td><a href="https://maps.app.goo.gl/pYhZwwtLDL971J4CA"
      target="_blank" rel="noopener noreferrer">Calz. de Tlalpan 1978, Country Club Churubusco, Coyoacán, 04220 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Parque Nacional Bosque de Tlalpan</td>
    <td><a href="https://maps.app.goo.gl/6zJce9xtXgGGesMa9"
      target="_blank" rel="noopener noreferrer">Parques del Pedregal, Tlalpan, 14738 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Patriotismo</td>
    <td><a href="https://maps.app.goo.gl/tb3i1JeAZDgJggPe7"
      target="_blank" rel="noopener noreferrer">Escandón I Secc, 11800 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Polanco</td>
    <td><a href="https://maps.app.goo.gl/uL822VXbZMrMihbo8"
      target="_blank" rel="noopener noreferrer">Polanco, Polanco V Secc, Miguel Hidalgo, 11560 Ciudad de México, CDMX</a></td>
  </tr>
  <tr>
    <td>Plaza Tlalne Fashion Mall</td>
    <td><a href="https://maps.app.goo.gl/AbtgiJWUcayA9ZmU7"
      target="_blank" rel="noopener noreferrer">Av Sor Juana Inés de La Cruz 280, San Lorenzo, 54033 Tlalnepantla, Méx.</a></td>
  </tr>
  <tr>
    <td>Plaza Las Américas</td>
    <td><a href="https://maps.app.goo.gl/QeVZ9SCFRRbLT3169"
      target="_blank" rel="noopener noreferrer">Av. Central S/N, Fraccionamiento Las Americas, Las Américas, 55076 Ecatepec de Morelos, Méx.</a></td>
  </tr>
  <tr>
    <td>Multiplaza Aragón</td>
    <td><a href="https://maps.app.goo.gl/c8Gq3uXWYZT4fZVL8"
      target="_blank" rel="noopener noreferrer">Av. Carlos Hank González 120, Rinconada de Aragon, 55140 Ecatepec de Morelos, Méx.</a></td>
  </tr>
  <tr>
    <td>Parque de la Ciencia Tlalnepantla</td>
    <td><a href="https://maps.app.goo.gl/VWcMUYZG1GbfzBAC9"
      target="_blank" rel="noopener noreferrer">Cerro Colorado Manzana 002, Ecuestre Residencial San José, Industrial la Presa, 54187 Tlalnepantla, Méx.</a></td>
  </tr>
  <tr>
    <td>Teatro Zaragoza</td>
    <td><a href="https://maps.app.goo.gl/G5BmCeKhrBAQtWDE7"
      target="_blank" rel="noopener noreferrer">Calz. S. Mateo s/n, San Juan Bosco 1, 52946 Cdad. López Mateos, Méx.</a></td>
  </tr>
  <tr>
    <td>Costco Atizapán</td>
    <td><a href="https://maps.app.goo.gl/3qtKLFS29jnZJHcL7"
      target="_blank" rel="noopener noreferrer">3, Av. Adolfo Ruiz Cortines 1, Lomas de Atizapan, 52966 Cdad. López Mateos, Méx.</a></td>
  </tr>
  <tr>
    <td>Zoológico Guadalajara</td>
    <td><a href="https://maps.app.goo.gl/qLnejCwxu2axnpq9A"
      target="_blank" rel="noopener noreferrer">C. P.º del Zoológico 600, Huentitán El Alto, 44390 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>Tlaquepaque Centro</td>
    <td><a href="https://maps.app.goo.gl/QTGkpMeYG7TdxsBr6"
      target="_blank" rel="noopener noreferrer">Centro, 45500 San Pedro Tlaquepaque, Jal.</a></td>
  </tr>
  <tr>
    <td>Estadio Jalisco</td>
    <td><a href="https://maps.app.goo.gl/CL2U6Q9FWf23vFiE8"
      target="_blank" rel="noopener noreferrer">C. Siete Colinas 1772, Independencia, 44290 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>Expo Guadalajara</td>
    <td><a href="https://maps.app.goo.gl/5xze2QGnQhqbzkqn7"
      target="_blank" rel="noopener noreferrer">Av. Mariano Otero 1499, Verde Valle, 44550 Guadalajara, Jal.</a></td>
  </tr>
  <tr>
    <td>Parque Montenegro</td>
    <td><a href="https://maps.app.goo.gl/hSBn9hhBECgYQF2JA"
      target="_blank" rel="noopener noreferrer">Av. de la Solidaridad Iberoamericana 7007, La Capilla, 45690 Las Pintas, Jal.</a></td>
  </tr>
  <tr>
    <td>Parque El Capitán</td>
    <td><a href="https://maps.app.goo.gl/rBZiCD5jRaV3WQKY7"
      target="_blank" rel="noopener noreferrer">Av. Fuentes del Valle, Fuentes del Valle, 66220 San Pedro Garza García, N.L.</a></td>
  </tr>
  <tr>
    <td>Club Campestre de Monterrey</td>
    <td><a href="https://maps.app.goo.gl/JNZd9XpXYyqa2AiS8"
      target="_blank" rel="noopener noreferrer">Av Alfonso Reyes 107, Veredalta, 66267 San Pedro Garza García, N.L.</a></td>
  </tr>
  <tr>
    <td>Arena Monterrey</td>
    <td><a href="https://maps.app.goo.gl/aVbjKenDhkzh8sT56"
      target="_blank" rel="noopener noreferrer">Av. Francisco I. Madero 2500, Centro, 64010 Monterrey, N.L.</a></td>
  </tr>
  <tr>
    <td>Zoológico La Pastora</td>
    <td><a href="https://maps.app.goo.gl/k6jJQcv1FXPek9oY9"
      target="_blank" rel="noopener noreferrer">Av Eloy Cavazos, Jardines de La Pastora, 67140 Guadalupe, N.L.</a></td>
  </tr>
  <tr>
    <td>Plaza Nuevo Sur</td>
    <td><a href="https://maps.app.goo.gl/P8GPokdYkeVL5SeM6"
      target="_blank" rel="noopener noreferrer">Av. Revolución 2703, Ladrillera, 64830 Monterrey, N.L.</a></td>
  </tr>
  <tr>
    <td>Parque Ecológico</td>
    <td><a href="https://maps.app.goo.gl/n1N4ZW3cnMtg72569"
      target="_blank" rel="noopener noreferrer">Calle 24 Sur S/N, Azcarate, 72501 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Plaza San Diego</td>
    <td><a href="https://maps.app.goo.gl/VSswQvf71f56ket67"
      target="_blank" rel="noopener noreferrer">Blvd. Forjadores de Puebla 3401, San Diego los Sauces, 72760 Cholula de Rivadavia, Pue.</a></td>
  </tr>
  <tr>
    <td>Galerías Serdán</td>
    <td><a href="https://maps.app.goo.gl/gW2ngkU2dhCvTDbY7"
      target="_blank" rel="noopener noreferrer">Blvd. Hermanos Serdán 270, Colonia Rancho, Posadas, 72060 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Teleférico de Puebla</td>
    <td><a href="https://maps.app.goo.gl/jnJX6YM8cuBkf36X7"
      target="_blank" rel="noopener noreferrer">C. 44 Ote. 204, Moctezuma, 72260 Heroica Puebla de Zaragoza, Pue.</a></td>
  </tr>
  <tr>
    <td>Parque Recreativo El Ameyal</td>
    <td><a href="https://maps.app.goo.gl/tg6gV1g8B2udBX6e8"
      target="_blank" rel="noopener noreferrer">El Carmen 10, Estrellas del Mar, 72702 San Juan Cuautlancingo, Pue.</a></td>
  </tr>
  <tr>
    <td>Ciudad del Sol</td>
    <td><a href="https://maps.app.goo.gl/V8v6f3TievmeQm9z6"
      target="_blank" rel="noopener noreferrer">Blvrd Peña Flor 123, 76118 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Plaza Puerta La Victoria</td>
    <td><a href="https://maps.app.goo.gl/CLQMxMtzQmiGu8Nn9"
      target="_blank" rel="noopener noreferrer">Av. Constituyentes Ote. 40 - A, zona dos extendida, Villas del Sol, 76046 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Jardín Zenea</td>
    <td><a href="https://maps.app.goo.gl/6seDsJBi788bm6HX8"
      target="_blank" rel="noopener noreferrer">C. 16 de Septiembre 8-24, Centro, 76000 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Los Arcos de Querétaro</td>
    <td><a href="https://maps.app.goo.gl/GFWZpZFGMA66c18d8"
      target="_blank" rel="noopener noreferrer">Calz. de los Arcos, Bosques del Acueducto, 76020 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Parque Alcanfores Norte</td>
    <td><a href="https://maps.app.goo.gl/rak15S4MHr2hStRS7"
      target="_blank" rel="noopener noreferrer">Gustavo Adolfo Bécquer, Parque los Alcanfores Secc Nte, 76156 Santiago de Querétaro, Qro.</a></td>
  </tr>
  <tr>
    <td>Estadio Victoria</td>
    <td><a href="https://maps.app.goo.gl/aJtwo2GTAgRQxen38"
      target="_blank" rel="noopener noreferrer">Manuel Zavala Madrigal 101, Héroes, 20190 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Isla San Marcos</td>
    <td><a href="https://maps.app.goo.gl/dqqDtYZyez4zLvJ49"
      target="_blank" rel="noopener noreferrer">Blvd. San Marcos s/n, Pirules, 20207 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Museo Espacio</td>
    <td><a href="https://maps.app.goo.gl/CUfCHy9Ypkd8fGLq8"
      target="_blank" rel="noopener noreferrer">Av. Ferrocarril Sur S/N, AntiguosTalleres del Ferrocarril, Desarrollo Especial Talleres F.F.C.C., 20259 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Foro de las Estrellas</td>
    <td><a href="https://maps.app.goo.gl/h3RZZRBs2PNQFhKe8"
      target="_blank" rel="noopener noreferrer">20220, Lienzo Charro 107, Las Flores, 20220 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Centro Comercial Altaria</td>
    <td><a href="https://maps.app.goo.gl/9gutxY17hXwW6HvD8"
      target="_blank" rel="noopener noreferrer">Blvd. A Zacatecas 849, Trojes de Alonso, 20116 Aguascalientes, Ags.</a></td>
  </tr>
  <tr>
    <td>Estadio Caliente</td>
    <td><a href="https://maps.app.goo.gl/qidFxFk3poBvvott6"
      target="_blank" rel="noopener noreferrer">Blvd. Agua Caliente 12027, Hipodromo, 22020 Tijuana, B.C.</a></td>
  </tr>
  <tr>
    <td>Torre de Agua Caliente</td>
    <td><a href="https://maps.app.goo.gl/fMobZ9u9YcajJv13A"
      target="_blank" rel="noopener noreferrer">Blvd. Agua Caliente, Juárez, 22040 Tijuana, B.C.</a></td>
  </tr>
  <tr>
    <td>Parque Teniente Guerrero</td>
    <td><a href="https://maps.app.goo.gl/v91e4xUBUqLg74rE6"
      target="_blank" rel="noopener noreferrer">C. Carrillo Puerto 7428, Zona Centro, 22000 Tijuana, B.C.</a></td>
  </tr>
  <tr>
    <td>Plaza San Pedro</td>
    <td><a href="https://maps.app.goo.gl/CaRuBzonyASWjur3A"
      target="_blank" rel="noopener noreferrer">Calz. Cetys 1801, Vista Hermosa, 21259 Mexicali, B.C.</a></td>
  </tr>
  <tr>
    <td>Bosque y Zoológico de la Ciudad</td>
    <td><a href="https://maps.app.goo.gl/bj9TX6MiYzzoCmbCA"
      target="_blank" rel="noopener noreferrer">C. Alvarado S/N, Nueva Esperanza, 21050 Mexicali, B.C.</a></td>
  </tr>
  <tr>
    <td>Telmex</td>
    <td><a href="https://maps.app.goo.gl/ppwsqL91SNG1LBAf6"
      target="_blank" rel="noopener noreferrer">Hidalgo y Juárez s/n, Centro, 23400 San José del Cabo, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Plaza Mijares</td>
    <td><a href="https://maps.app.goo.gl/tvhWnqDLb7Xtvdem9"
      target="_blank" rel="noopener noreferrer">Blvd. Antonio Mijares, Centro, 23400 San José del Cabo, B.C.S.</a></td>
  </tr>
  <tr>
    <td>San José del Cabo Mercado Orgánico</td>
    <td><a href="https://maps.app.goo.gl/yyBSLeoeiYwmUvep7"
      target="_blank" rel="noopener noreferrer">Centro, 23407 San José del Cabo, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Parque Los Cangrejos</td>
    <td><a href="https://maps.app.goo.gl/q9oBpbZy4t57yshcA"
      target="_blank" rel="noopener noreferrer">P.º Cabo de Oro, Los Cangrejos, 23473 Cabo San Lucas, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Arena La Paz</td>
    <td><a href="https://maps.app.goo.gl/JnUdKRXKJqqk6jEH8"
      target="_blank" rel="noopener noreferrer">Zona Central, 23000 La Paz, B.C.S.</a></td>
  </tr>
  <tr>
    <td>Museo de la Ciudad (Baluarte de San Carlos)</td>
    <td><a href="https://maps.app.goo.gl/VmxGtjYhQnVnKDgEA"
      target="_blank" rel="noopener noreferrer">Av. 16 de Septiembre, Zona Centro, 24000 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Mercado De San Francisco</td>
    <td><a href="https://maps.app.goo.gl/Ghh8Db1a2hw1SVde8"
      target="_blank" rel="noopener noreferrer">C. 10 68, Barrio de San Francisco, 24010 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Parque del Joloch</td>
    <td><a href="https://maps.app.goo.gl/FCZfGaVFhagYvgyh9"
      target="_blank" rel="noopener noreferrer">24026, Malagón 2, Granjas, San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Estadio Nelson Barrera Romellón</td>
    <td><a href="https://maps.app.goo.gl/PKyTL3DzBbriq95C6"
      target="_blank" rel="noopener noreferrer">Benjamín Ganzo, San Joaquín, 24027 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>Parque Los Álamos</td>
    <td><a href="https://maps.app.goo.gl/bpDfmXD6jC3wN9of7"
      target="_blank" rel="noopener noreferrer">Calle Pradera entre calle Encinos y calle, Los Girasoles, Fraccionamiento Los Álamos, C. P, 24088 San Francisco de Campeche, Camp.</a></td>
  </tr>
  <tr>
    <td>ZOOMAT</td>
    <td><a href="https://maps.app.goo.gl/kdA5bdN1xN26n5nr5"
      target="_blank" rel="noopener noreferrer">Calz. Cerro Hueco s/n, El Zapotal, 29094 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Foro Chiapas</td>
    <td><a href="https://maps.app.goo.gl/cyvR4qNSBBWHwbSq7"
      target="_blank" rel="noopener noreferrer">Carretera Juan Crispin, Carr. Chicoasen Km. 2.5, Plan de Ayala, 29020 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Iglesia de San Patricio Tuxtla</td>
    <td><a href="https://maps.app.goo.gl/AUeebpfX6HZ7cC4x6"
      target="_blank" rel="noopener noreferrer">Cam. Al Club Campestre s/n, Fracc. San Patricio, 29000 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Jardín Botánico Faustino Miranda</td>
    <td><a href="https://maps.app.goo.gl/TxUcYu4B9mvQhUgm6"
      target="_blank" rel="noopener noreferrer">Av, 5a. Avenida Nte. Ote. s/n, Parque Madero, Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>Mercado de los Ancianos</td>
    <td><a href="https://maps.app.goo.gl/pPN48uKC4RetwW6UA"
      target="_blank" rel="noopener noreferrer">9A Avenida Sur Oriente No. 1617 Locales 1 y 2, Sta. Cruz, 29073 Tuxtla Gutiérrez, Chis.</a></td>
  </tr>
  <tr>
    <td>San Juan de Ulúa</td>
    <td><a href="https://maps.app.goo.gl/7irGQg29fgG1ZZ4X8"
      target="_blank" rel="noopener noreferrer">Cam. Escénico a San Juan de Ulúa S/N, Manuel Contreras, 91891 Veracruz, Ver.</a></td>
  </tr>
  <tr>
    <td>Acuario de Veracruz</td>
    <td><a href="https://maps.app.goo.gl/u4p72Y2BW6qcBFYT8"
      target="_blank" rel="noopener noreferrer">Blvd. Manuel Ávila Camacho S/n, Ricardo Flores Magón, 91900 Veracruz, Ver.</a></td>
  </tr>
  <tr>
    <td>"Estadio de Béisbol Profesional ""Beto Ávila"""</td>
    <td><a href="https://maps.app.goo.gl/4rFkK5ypLbDkwnND9"
      target="_blank" rel="noopener noreferrer">Paseo de Las Jacarandas 224 B, entre Calle Antón de Alaminos y Virginia, Virginia, 94294 Boca del Río, Ver.</a></td>
  </tr>
  <tr>
    <td>Plaza Nuevo Veracruz</td>
    <td><a href="https://maps.app.goo.gl/pRtjEcsAUUEuSnkf6"
      target="_blank" rel="noopener noreferrer">Av. Circuito Nuevo Veracruz 835, Ex Hacienda de Buenavista, 91726 Veracruz</a></td>
  </tr>
  <tr>
    <td>Plaza Framboyanes</td>
    <td><a href="https://maps.app.goo.gl/wCu6khtdAWB76zZ9A"
      target="_blank" rel="noopener noreferrer">Carr. Xalapa Veracruz 317, Bruno Pagliai, 91697 Valente Díaz, Ver.</a></td>
  </tr>
  <tr>
    <td>Cinépolis Renacimiento</td>
    <td><a href="https://maps.app.goo.gl/NCsQTY3JtG6jYJDe8"
      target="_blank" rel="noopener noreferrer">Calle 14 lt 718, Zapata, Emiliano Zapata, 39700 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Patio Acapulco</td>
    <td><a href="https://maps.app.goo.gl/e9pkkVPGR71zhto7A"
      target="_blank" rel="noopener noreferrer">Carr. Cayaco - Puerto Marqués 244, El Cayaco, 39905 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Playa Condesa</td>
    <td><a href="https://maps.app.goo.gl/gRLMG6uKt8qD6H8b6"
      target="_blank" rel="noopener noreferrer">Guerrero</a></td>
  </tr>
  <tr>
    <td>Parque Papagayo</td>
    <td><a href="https://maps.app.goo.gl/gCWpeRDTuz7ra85EA"
      target="_blank" rel="noopener noreferrer">Av Niños Heroes de Veracruz 27, Alta Costa Azul, 39830 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Caleta playa</td>
    <td><a href="https://maps.app.goo.gl/UeVarXySkAHpxvMu7"
      target="_blank" rel="noopener noreferrer">Alta Caleta 7, Las Playas, 39390 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Torre de Mr. Hayes</td>
    <td><a href="https://maps.app.goo.gl/RgcnnePRPh8R1KcSA"
      target="_blank" rel="noopener noreferrer">13 de Septiembre 164, La Mira, 39480 Acapulco de Juárez, Gro.</a></td>
  </tr>
  <tr>
    <td>Feria de Santa Rita</td>
    <td><a href="https://maps.app.goo.gl/imS3tVJ724vhu3om6"
      target="_blank" rel="noopener noreferrer">31064 Juárez, Francisco Portillo 1829, Villa Juárez, 31064 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Ciudad Deportiva</td>
    <td><a href="https://maps.app.goo.gl/tipjY5vvCS3vqt5x9"
      target="_blank" rel="noopener noreferrer">Av. Tecnologico, Av División del Nte y, Altavista, 31200 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Cinépolis Chihuahua</td>
    <td><a href="https://maps.app.goo.gl/XS7iM2SBtLp55CBG7"
      target="_blank" rel="noopener noreferrer">Av Zaragoza 1114, Norte I, 31160 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Distrito Uno</td>
    <td><a href="https://maps.app.goo.gl/K8VwK7qzRcf9pfc87"
      target="_blank" rel="noopener noreferrer">Perif. de la Juventud 5700, Distrito Uno, 31110 Chihuahua, Chih.</a></td>
  </tr>
  <tr>
    <td>Paseo Central</td>
    <td><a href="https://maps.app.goo.gl/D7yGS7Apu5EDnNy87"
      target="_blank" rel="noopener noreferrer">Perif. de la Juventud, Haciendas, 31216 Chihuahua</a></td>
  </tr>
  <tr>
    <td>Parque Xochipilli I</td>
    <td><a href="https://maps.app.goo.gl/76DCEnXgkXkcphhS6"
      target="_blank" rel="noopener noreferrer">Av. Adolfo López Mateos 50, Progreso, 25770 Monclova, Coah.</a></td>
  </tr>
  <tr>
    <td>Torre Eiffel Gómez Palacio</td>
    <td><a href="https://maps.app.goo.gl/coZYyrt7QcQzP2Xq9"
      target="_blank" rel="noopener noreferrer">Blvrd González de la Vega 18, Parque Industrial, 35079 Gómez Palacio, Dgo.</a></td>
  </tr>
  <tr>
    <td>Ciudad Deportiva Francisco I. Madero</td>
    <td><a href="https://maps.app.goo.gl/jF87yEgW2jAuPaBNA"
      target="_blank" rel="noopener noreferrer">Calle, Prol. David Berlanga, Amp. Gabriel Hernández, 25280 Saltillo, Coah.</a></td>
  </tr>
  <tr>
    <td>Museo Casa del Cerro</td>
    <td><a href="https://maps.app.goo.gl/cSfaBXkMezWKmaJt5"
      target="_blank" rel="noopener noreferrer">Industria S/N, Durangueña, 27000 Torreón, Coah.</a></td>
  </tr>
  <tr>
    <td>Teatro de la Ciudad Raúl Flores Canelo</td>
    <td><a href="https://maps.app.goo.gl/P5wSJFomq72woGKv7"
      target="_blank" rel="noopener noreferrer">Carretera 57 Km. 5 Jardines de Montessori, 25720 Monclova, Coah.</a></td>
  </tr>
  <tr>
    <td>Estadio Colima</td>
    <td><a href="https://maps.app.goo.gl/gcpZ28qAbp9XYAPP6"
      target="_blank" rel="noopener noreferrer">Lic. y Prof. Francisco Solórzano 00, La Albarrada, Arboledas, 28078 Colima, Col.</a></td>
  </tr>
  <tr>
    <td>Feria de Todos los Santos</td>
    <td><a href="https://maps.app.goo.gl/gQ1oQTP1KRKsey1w8"
      target="_blank" rel="noopener noreferrer">Av. Niños Héroes S/N, La Estancia, 28610 Colima, Col.</a></td>
  </tr>
  <tr>
    <td>Cervecería de Colima</td>
    <td><a href="https://maps.app.goo.gl/QWaZ5QLaTpgUJ6nH9"
      target="_blank" rel="noopener noreferrer">Carretera Colima-Guadalajara Km. 5, El Trapiche, 28550 Cuauhtémoc, Col.</a></td>
  </tr>
  <tr>
    <td>Ecoparc</td>
    <td><a href="https://maps.app.goo.gl/xkdw6WcdR7Q9KPZh8"
      target="_blank" rel="noopener noreferrer">C. Santos Degollado S/N, Centro, 28000 Colima, Col.</a></td>
  </tr>
  <tr>
    <td>Topocharco</td>
    <td><a href="https://maps.app.goo.gl/w8fUFV43gQA3WaPz7"
      target="_blank" rel="noopener noreferrer">De la Plata, Villas Providencia, 28987 Cdad. de Villa de Álvarez, Col.</a></td>
  </tr>
  <tr>
    <td>Universidad Tecnológica de Durango</td>
    <td><a href="https://maps.app.goo.gl/xLtXjvxYAuxAR2yJA"
      target="_blank" rel="noopener noreferrer">Durango - Mezquital, 34308 Gabino Santillán, Dgo.</a></td>
  </tr>
  <tr>
    <td>Jardín Morelos</td>
    <td><a href="https://maps.app.goo.gl/eifHzFEuRbDkLK6WA"
      target="_blank" rel="noopener noreferrer">Calle 5 de Febrero, Zona Centro, 34000 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Lago de la China</td>
    <td><a href="https://maps.app.goo.gl/TnqaHwuGagiMRpNU6"
      target="_blank" rel="noopener noreferrer">Boulevard Guadiana, Parque Guadiana, 34047 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Unidad Deportiva Chiquis Cavada</td>
    <td><a href="https://maps.app.goo.gl/CQwa6tNheQdWJVnz8"
      target="_blank" rel="noopener noreferrer">Manuel Díaz B., Héctor Mayagoytia, 34010 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Cinépolis Durango</td>
    <td><a href="https://maps.app.goo.gl/nwKP5CPa9BeK39S26"
      target="_blank" rel="noopener noreferrer">Blvd. Francisco Villa 1501, Jardines de Durango, 34209 Durango, Dgo.</a></td>
  </tr>
  <tr>
    <td>Museo de las Momias de Guanajuato</td>
    <td><a href="https://maps.app.goo.gl/YQSVnrFkcRyTvrkG7"
      target="_blank" rel="noopener noreferrer">Explanada del Panteón Municipal s/n, Centro, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Museo de Arte Olga Costa</td>
    <td><a href="https://maps.app.goo.gl/nGg5hNwvBKFc7deQA"
      target="_blank" rel="noopener noreferrer">C. Pastita 158, Pastita, 36090 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Museo Ex Hacienda San Gabriel de Barrera</td>
    <td><a href="https://maps.app.goo.gl/cgamkmsbxZvkccdEA"
      target="_blank" rel="noopener noreferrer">KM 2.5, Guanajuato- Marfil, El Cerrito, 36050 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Museo Iconográfico Del Quijote</td>
    <td><a href="https://maps.app.goo.gl/hJNn1nAxfQrgUr8x5"
      target="_blank" rel="noopener noreferrer">Cantarranas 1, Zona Centro, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>Casa de Tía Aura</td>
    <td><a href="https://maps.app.goo.gl/T7i6Beb49idNiSco7"
      target="_blank" rel="noopener noreferrer">P.º de La Presa 62, Barrio de la Presa, 36000 Guanajuato, Gto.</a></td>
  </tr>
  <tr>
    <td>"Diana Cazadora, Ixmiquilpan"</td>
    <td><a href="https://maps.app.goo.gl/5Wpuqckypa9W84sL8"
      target="_blank" rel="noopener noreferrer">Lib. al Cardonal 13, Centro, 42300 Ixmiquilpan, Hgo.</a></td>
  </tr>
  <tr>
    <td>Templo y Ex Convento de San Miguel Arcángel</td>
    <td><a href="https://maps.app.goo.gl/5F1UD3QeywHZJNKH9"
      target="_blank" rel="noopener noreferrer">Nicolás Romero 34, Centro, 42300 Ixmiquilpan, Hgo.</a></td>
  </tr>
  <tr>
    <td>Parque San Javier</td>
    <td><a href="https://maps.app.goo.gl/YTWga5dT2eUwfknq7"
      target="_blank" rel="noopener noreferrer">San Javier, 42305 Ixmiquilpan, Hgo.</a></td>
  </tr>
  <tr>
    <td>Mercado Morelos</td>
    <td><a href="https://maps.app.goo.gl/jVfChXBjPS7iRT1e6"
      target="_blank" rel="noopener noreferrer">Jesús del Rosal 10, San Antonio, 42302 Ixmiquilpan, Hgo.</a></td>
  </tr>
  <tr>
    <td>Monumento a Lázaro Cárdenas</td>
    <td><a href="https://maps.app.goo.gl/EiXzfEqQCh9iLgo19"
      target="_blank" rel="noopener noreferrer">Av Francisco I. Madero Pte, Nueva Valladolid, 58190 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Estadio Morelos</td>
    <td><a href="https://maps.app.goo.gl/75iQV1PaDBp8t3Zz5"
      target="_blank" rel="noopener noreferrer">58147 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Complejo Deportivo Bicentenario</td>
    <td><a href="https://maps.app.goo.gl/j5t5ZK5HpJXJAQpLA"
      target="_blank" rel="noopener noreferrer">Av. Torreon Nuevo S/N, Barrio Alto, 58118 Morelia, Mich.</a></td>
  </tr>
  <tr>
    <td>Basílica de Nuestra Señora de la Salud</td>
    <td><a href="https://maps.app.goo.gl/bVMdbyUq4tCsRpSD7"
      target="_blank" rel="noopener noreferrer">Gral. Benigno Serrato S/N, Centro, 61600 Pátzcuaro, Mich.</a></td>
  </tr>
  <tr>
    <td>Centro de Uruapan</td>
    <td><a href="https://maps.app.goo.gl/ovD2ojQ9pYtWcE2f9"
      target="_blank" rel="noopener noreferrer">Uruapan, Amp Revolución, 60000 Uruapan, Mich.</a></td>
  </tr>
  <tr>
    <td>Zócalo de Xochitepec</td>
    <td><a href="https://maps.app.goo.gl/mvc2PjAk7T3cDZQs8"
      target="_blank" rel="noopener noreferrer">Colón, Centro, 62790 Xochitepec, Mor.</a></td>
  </tr>
  <tr>
    <td>Zócalo de Jiutepec</td>
    <td><a href="https://maps.app.goo.gl/aWReWN4WTAbgzZ6B7"
      target="_blank" rel="noopener noreferrer">Av. Centenario S/N, Centro, 62550 Jiutepec, Mor.</a></td>
  </tr>
  <tr>
    <td>Plaza Novum</td>
    <td><a href="https://maps.app.goo.gl/Jpx6GUXzpRHJKi7a9"
      target="_blank" rel="noopener noreferrer">Blvd. Paseo Cuauhnáhuac Km. 3.5, Bugambilias, 62550 Jiutepec, Mor.</a></td>
  </tr>
  <tr>
    <td>Campo La Union Patriotas</td>
    <td><a href="https://maps.app.goo.gl/6nRFmGvhUpGTPPPW7"
      target="_blank" rel="noopener noreferrer">Estrada Cajigal 310, zona 1, El Empleado, 62250 Cuernavaca, Mor.</a></td>
  </tr>
  <tr>
    <td>Tepoznieves </td>
    <td><a href="https://maps.app.goo.gl/BawRM3GjZy7nEx6g6"
      target="_blank" rel="noopener noreferrer">5 de Mayo 21, San Miguel, 62520 Tepoztlán, Mor.</a></td>
  </tr>
  <tr>
    <td>Plaza La Cantera</td>
    <td><a href="https://maps.app.goo.gl/y7rt4m268FxFTfKC9"
      target="_blank" rel="noopener noreferrer">Av Aguamilpa, Jazmines, 63173 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Mirador Zitakua</td>
    <td><a href="https://maps.app.goo.gl/hY8xaRjpEEhX3oYq6"
      target="_blank" rel="noopener noreferrer">Zitácua, 63174 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Plaza de Armas Tepic</td>
    <td><a href="https://maps.app.goo.gl/Xtjcj4k5vnBgSkoy6"
      target="_blank" rel="noopener noreferrer">Amado Nervo Pte. 37, Centro, 63000 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Estadio Olímpico Santa Teresita</td>
    <td><a href="https://maps.app.goo.gl/iYxxDod8LsXDE5AC9"
      target="_blank" rel="noopener noreferrer">63020, Santa Teresita, 63020 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Tecnológico Nacional De México campus “Tepic”</td>
    <td><a href="https://maps.app.goo.gl/8oZyEj8iisrpPNzG8"
      target="_blank" rel="noopener noreferrer">Av. Tecnológico No. 2595, Instituto Tecnológico de Tepic, 63175 Tepic, Nay.</a></td>
  </tr>
  <tr>
    <td>Monumento a Don Benito Juárez García</td>
    <td><a href="https://maps.app.goo.gl/YC8UZt77VphwnN3c6"
      target="_blank" rel="noopener noreferrer">A Viguera, Eucaliptos, 68274 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Zicatela</td>
    <td><a href="https://maps.app.goo.gl/gTHG8V4BMr9uDity9"
      target="_blank" rel="noopener noreferrer">Oaxaca</a></td>
  </tr>
  <tr>
    <td>El Idilio</td>
    <td><a href="https://maps.app.goo.gl/vR7cnMfbfddZHjML6"
      target="_blank" rel="noopener noreferrer">Primera Nte., Centro, 71980 Puerto Escondido, Oax.</a></td>
  </tr>
  <tr>
    <td>Flip Out Oaxaca</td>
    <td><a href="https://maps.app.goo.gl/GrVCuErf2vrAqV1t8"
      target="_blank" rel="noopener noreferrer">Av. Lázaro Cárdenas 160, Cabecera Municipal San Sebastian Tutla, 71320 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Parque Luis Donaldo Colosio</td>
    <td><a href="https://maps.app.goo.gl/8gdBYUZT7eRZsBT87"
      target="_blank" rel="noopener noreferrer">1o. de Mayo, Agencia Municipal de San Felipe del Agua, 68026 Oaxaca de Juárez, Oax.</a></td>
  </tr>
  <tr>
    <td>Zoológico Payo Obispo</td>
    <td><a href="https://maps.app.goo.gl/vEioDoQZp2vkhDYE6"
      target="_blank" rel="noopener noreferrer">Av Insurgentes s/n, 17 de Octubre, 77015 Chetumal, Q.R.</a></td>
  </tr>
  <tr>
    <td>Museo de la Cultura Maya</td>
    <td><a href="https://maps.app.goo.gl/xY8cnnk3XibFwdnX8"
      target="_blank" rel="noopener noreferrer">Av de los Héroes S/N, Centro, 77000 Chetumal, Q.R.</a></td>
  </tr>
  <tr>
    <td>Acuario Palmas 1</td>
    <td><a href="https://maps.app.goo.gl/kpJewQ5S3jzQbXGDA"
      target="_blank" rel="noopener noreferrer">Guacamayas, Las Palmas 1, 77723 Playa del Carmen, Q.R.</a></td>
  </tr>
  <tr>
    <td>Domo Deportivo 237</td>
    <td><a href="https://maps.app.goo.gl/GRnNwwjBSdQFuYco8"
      target="_blank" rel="noopener noreferrer">Prol. Tulum 237, 77527 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>Estadio Cancún 86</td>
    <td><a href="https://maps.app.goo.gl/4b1pHyS1Z7DrYzZL7"
      target="_blank" rel="noopener noreferrer">Av Tulum Supermanzana 89 MZA 7 LTE 8, 77527 Cancún, Q.R.</a></td>
  </tr>
  <tr>
    <td>"La Loma, Centro Deportivo"</td>
    <td><a href="https://maps.app.goo.gl/rCTjHsFJ8uCB3DdTA"
      target="_blank" rel="noopener noreferrer">Av. de la Victoria 100, Mixto la Loma, 78216 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>"Parque ""De Morales"" Juan H. Sánchez"</td>
    <td><a href="https://maps.app.goo.gl/REDEicxwT7ffoVRR8"
      target="_blank" rel="noopener noreferrer">Venustiano Carranza 2380, Polanco, San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Parque Bicentenario San Luis Potosí</td>
    <td><a href="https://maps.app.goo.gl/DG7kaPFM97CHF4uK8"
      target="_blank" rel="noopener noreferrer">Anillo Perif., Immsa, 78180 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Estadio Alfonso Lastras Ramírez</td>
    <td><a href="https://maps.app.goo.gl/m9HFWMap1pZzb44e8"
      target="_blank" rel="noopener noreferrer">Malaquita 1030, Valle Dorado, 78399 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Teatro del pueblo FENAPO</td>
    <td><a href="https://maps.app.goo.gl/1SkiLN76fb2s6yyc7"
      target="_blank" rel="noopener noreferrer">América del Sur 2200, Satelite Francisco I. Madero, 78384 San Luis Potosí, S.L.P.</a></td>
  </tr>
  <tr>
    <td>Parque Tematico Mas Culiacán</td>
    <td><a href="https://maps.app.goo.gl/JmLK6c2ZFA845xtj9"
      target="_blank" rel="noopener noreferrer">Blvd. Mario López Valdez, Universidad de Occidente, 80058 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Estadio Dorados</td>
    <td><a href="https://maps.app.goo.gl/VfXR2nmus2SJw5ze9"
      target="_blank" rel="noopener noreferrer">80020, Blvd. Rotarismo, Desarrollo Urbano Tres Ríos, Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Plaza San Isidro</td>
    <td><a href="https://maps.app.goo.gl/SF2uqG1dk5UsTmVKA"
      target="_blank" rel="noopener noreferrer">Boulevard de los Ganaderos 1787, Lomas de San Isidro, 80197 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Citicinemas La Isla</td>
    <td><a href="https://maps.app.goo.gl/D42ysxr3FZZw1t799"
      target="_blank" rel="noopener noreferrer">Cancún 1555, Fracc Musalá Isla Bonita, Raquet Club, 80060 Culiacán Rosales, Sin.</a></td>
  </tr>
  <tr>
    <td>Jardín Botánico Benjamin Francis Johnston</td>
    <td><a href="https://maps.app.goo.gl/DmAbkHwr7JCaaD487"
      target="_blank" rel="noopener noreferrer">Blvd. Antonio Rosales Sur 750, Centro, 81200 Los Mochis, Sin.</a></td>
  </tr>
  <tr>
    <td>Estadio El Encanto</td>
    <td><a href="https://maps.app.goo.gl/Vh4qbJTX6ihPR3aNA"
      target="_blank" rel="noopener noreferrer">Av. Munich Fraccionamiento Pradera Dorada, Fraccionamiento Pradera Dorada, 82139 Mazatlán, Sin.</a></td>
  </tr>
  <tr>
    <td>Parque Urbi Villa del Rey</td>
    <td><a href="https://maps.app.goo.gl/7d9SRnLUfmszhTHp6"
      target="_blank" rel="noopener noreferrer">Bv. Colonial, Villas del Rey, 85136 Cdad. Obregón, Son.</a></td>
  </tr>
  <tr>
    <td>"Parque ""Los Pioneros"""</td>
    <td><a href="https://maps.app.goo.gl/CyFYUkeQW2CP29As7"
      target="_blank" rel="noopener noreferrer">C. Yaqui, Norte, 85040 Cdad. Obregón, Son.</a></td>
  </tr>
  <tr>
    <td>Plaza 300</td>
    <td><a href="https://maps.app.goo.gl/zkLdhghoKrDFbcWk6"
      target="_blank" rel="noopener noreferrer">Calle Michoacán #4202 Sur, C. 300, Franja Comercial 300, 85180 Cdad. Obregón, Son.</a></td>
  </tr>
  <tr>
    <td>Parque Madero</td>
    <td><a href="https://maps.app.goo.gl/5i3K7zPYz9wVa15x9"
      target="_blank" rel="noopener noreferrer">Jesús García Corona, Centro, 83000 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Monumento a Don Ignacio Soto Ávila</td>
    <td><a href="https://maps.app.goo.gl/Sr8fSFfJuMKUW83q9"
      target="_blank" rel="noopener noreferrer">Calle Israel González Y/O Perimetral 1031, Modelo, 83190 Hermosillo, Son.</a></td>
  </tr>
  <tr>
    <td>Parque de Convivencia Familiar</td>
    <td><a href="https://maps.app.goo.gl/oMgu1eKXiBNTqko39"
      target="_blank" rel="noopener noreferrer">C. Xicoténcatl LB, Carlos Pellicer Camara, 86530 Cárdenas, Tab.</a></td>
  </tr>
  <tr>
    <td>Cabeza Olmeca</td>
    <td><a href="https://maps.app.goo.gl/yF3QUVxpt1ukcVM96"
      target="_blank" rel="noopener noreferrer">Gral. Ernesto Aguirre Colorado 12, Guadalupe, 86400 Huimanguillo, Tab.</a></td>
  </tr>
  <tr>
    <td>Museo Interactivo Papagayo</td>
    <td><a href="https://maps.app.goo.gl/KnydxgS3DhDEKZJe7"
      target="_blank" rel="noopener noreferrer">Prolongacion de Paseo Usumacinta 2005 Ranchería Emiliano Zapata, 86280 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Parque Acuático Los Delfines</td>
    <td><a href="https://maps.app.goo.gl/dbsFaaZc4sFBpmGd8"
      target="_blank" rel="noopener noreferrer">Carretera Al Cedro Km 3.2 El cedro, 86220 Nacajuca, Tab.</a></td>
  </tr>
  <tr>
    <td>Mercado Público 'José María Pino Suárez'</td>
    <td><a href="https://maps.app.goo.gl/tH898XHqJHNnpHFv6"
      target="_blank" rel="noopener noreferrer">Delegacional 6, Av Pino Suárez 702, Centro Delegacion Cinco, 86000 Villahermosa, Tab.</a></td>
  </tr>
  <tr>
    <td>Mercado Del Norte</td>
    <td><a href="https://maps.app.goo.gl/xmxMQ6PdDwj4DgyF8"
      target="_blank" rel="noopener noreferrer">Río San Marcos 106, Unidad del Valle, 89314 Tampico, Tamps.</a></td>
  </tr>
  <tr>
    <td>Parque Laguna de Champayan</td>
    <td><a href="https://maps.app.goo.gl/NK2S4J3KXnpWQHLz9"
      target="_blank" rel="noopener noreferrer">C. Juárez 10, Zona Centro, 89600 Altamira, Tamps.</a></td>
  </tr>
  <tr>
    <td>Parque Tamul arrecifes</td>
    <td><a href="https://maps.app.goo.gl/PpLVUKjDeE6oQ5pB6"
      target="_blank" rel="noopener noreferrer">89603 Miramar, Tamps.</a></td>
  </tr>
  <tr>
    <td>Estadio Marte R. Gómez</td>
    <td><a href="https://maps.app.goo.gl/i4kDoWXCNz2wjuRD6"
      target="_blank" rel="noopener noreferrer">C. Alberto Carrera Torres 104, Pedro José Méndez, 87048 Cdad. Victoria, Tamps.</a></td>
  </tr>
  <tr>
    <td>Cinépolis Plaza Campestre</td>
    <td><a href="https://maps.app.goo.gl/Y2qcB6JH63YLQg2G6"
      target="_blank" rel="noopener noreferrer">Av Tamaulipas 3031, Villa Jardín, 87020 Cdad. Victoria, Tamps.</a></td>
  </tr>
  <tr>
    <td>Zoológico del Altiplano de Tlaxcala</td>
    <td><a href="https://maps.app.goo.gl/fFL5LV8YNgs9UcuAA"
      target="_blank" rel="noopener noreferrer">Carretera Santa Ana -Apizaco kilómetro 32.5, San Pablo Apetatitlán, 90600 Apetatitlán, Tlax.</a></td>
  </tr>
  <tr>
    <td>Recinto Feria Tlaxcala y Palenque</td>
    <td><a href="https://maps.app.goo.gl/LFaB1bkuv31eSzv47"
      target="_blank" rel="noopener noreferrer">Av. Tlahuicole, Adolfo López Mateos, 90040 Tlaxcala de Xicohténcatl, Tlax.</a></td>
  </tr>
  <tr>
    <td>Unidad Deportiva Texoloc</td>
    <td><a href="https://maps.app.goo.gl/Nn6MxoK2aVZUPUfQ7"
      target="_blank" rel="noopener noreferrer">Monterrey LB, Tlayecac, Barrio de Tlayecac, 90110 San Damián Texóloc, Tlax.</a></td>
  </tr>
  <tr>
    <td>Galerías Tlaxcala</td>
    <td><a href="https://maps.app.goo.gl/4aNoZzyNiJZqkWfu9"
      target="_blank" rel="noopener noreferrer">Libramiento Poniente de Tlaxcala S/N, San Sebastián Atlahapa, 90111 San Sebastián Atlahapa, Tlax.</a></td>
  </tr>
  <tr>
    <td>Auditorio de San Sebastian Atlahapa</td>
    <td><a href="https://maps.app.goo.gl/7ykdeF1DAGypXewh7"
      target="_blank" rel="noopener noreferrer">Av. Gasoducto, 90111 San Sebastián Atlahapa, Tlax.</a></td>
  </tr>
  <tr>
    <td>MUREM Museo de Ropa Étnica de México</td>
    <td><a href="https://maps.app.goo.gl/kxP9WwoBdv9wYvfYA"
      target="_blank" rel="noopener noreferrer">Calle 49A 202A, entre Calle 40 y Calle 42, San Juan, 97780 Valladolid, Yuc.</a></td>
  </tr>
  <tr>
    <td>Mercado Municipal de Valladolid</td>
    <td><a href="https://maps.app.goo.gl/FtdWHJHxP1RgxTei6"
      target="_blank" rel="noopener noreferrer">C. 32 #0, Sta Ana, 97780 Valladolid, Yuc.</a></td>
  </tr>
  <tr>
    <td>Zoológico Tizimín</td>
    <td><a href="https://maps.app.goo.gl/EhRATwtQoK8P9KHt7"
      target="_blank" rel="noopener noreferrer">Centro, Benito Juárez, 97700 Tizimín, Yuc.</a></td>
  </tr>
  <tr>
    <td>Campo Santo Domingo</td>
    <td><a href="https://maps.app.goo.gl/6UEUCCm3sBShrBPa6"
      target="_blank" rel="noopener noreferrer">C. 65 214-362, Santo Domingo, 97703 Tizimín, Yuc.</a></td>
  </tr>
  <tr>
    <td>Estadio Carlos Iturralde Rivero</td>
    <td><a href="https://maps.app.goo.gl/mwPk7KLRHrEqpDTb8"
      target="_blank" rel="noopener noreferrer">Circuito Colonias por, Calle 77 Ote S/N, Morelos Oriente, 97179 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Parque de la Alemán</td>
    <td><a href="https://maps.app.goo.gl/5K4qvQmgaU3Zq6Ky6"
      target="_blank" rel="noopener noreferrer">Calle 21 281F, Miguel Alemán, 97148 Mérida, Yuc.</a></td>
  </tr>
  <tr>
    <td>Jardín De La Madre</td>
    <td><a href="https://maps.app.goo.gl/2ccKFgdEcD11eZBs6"
      target="_blank" rel="noopener noreferrer">Av B. Juárez 607, Centro, 99000 Ejido del Centro, Zac.</a></td>
  </tr>
  <tr>
    <td>Parque La Lagunilla</td>
    <td><a href="https://maps.app.goo.gl/jDRRdeweWqhZjJ7YA"
      target="_blank" rel="noopener noreferrer">C. Vaso Lagunilla 28, Sector Lagunilla, 99060 Fresnillo, Zac.</a></td>
  </tr>
  <tr>
    <td>Museo Rafael Coronel</td>
    <td><a href="https://maps.app.goo.gl/XTqZ7KYCNyaczk3fA"
      target="_blank" rel="noopener noreferrer">Ex convento de San Francisco s/n, Zacatecas Centro, 98000 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Zigzag Centro Interactivo de Ciencia y Tecnología Zacatecas</td>
    <td><a href="https://maps.app.goo.gl/Wx9ovDKUQpLGez6f7"
      target="_blank" rel="noopener noreferrer">Av. de la Juventud 502 México, Zona A, Javier Barros Sierra, 98090 Zacatecas, Zac.</a></td>
  </tr>
  <tr>
    <td>Museo de Guadalupe</td>
    <td><a href="https://maps.app.goo.gl/ybfSGzAre1bP4V5M9"
      target="_blank" rel="noopener noreferrer">Jardin Juarez Oriente S/N, Centro, 98600 Guadalupe, Zac.</a></td>
  </tr>
  <tr>
    <td>Alberca Olímpica Guadalupe</td>
    <td><a href="https://maps.app.goo.gl/VpLVpRKrN2RKzjWA8"
      target="_blank" rel="noopener noreferrer">Periferico Siglo XXI No.402, Lomas II, 98615 Guadalupe, Zac.</a></td>
  </tr>
</table>`;

export default pointsChecocards;