const faqs = `<table class="text-center mx-auto small-table">
  <tr>
    <th class="py-12 text-white">PAQUETE</th>
  </tr>
  <tr>
    <td>SS POLO SHIRT (MENS) RED BULL REPLICA</td>
  </tr>
  <tr>
    <td>SHORT UMBRELLA RED BULL</td>
  </tr>
  <tr>
    <td>LANDYARD RED BULL</td>
  </tr>
  <tr>
    <td>BACKPACK RED BULL</td>
  </tr>
  <tr>
    <td>WATER RESISTANT JACKET UNISEX RED BULL REPLICA</td>
  </tr>
  <tr>
    <td>SHORT SLEEVE T-SHIRT LAS VEGAS RED BULL REPLICA</td>
  </tr>
  <tr>
    <td>GORRA CHECO PÉREZ</td>
  </tr>
  `;

export default faqs;