import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import changeNameImages from 'utils/changeNameImages';
import UserService from '../../services/userService.service';
import { useForm } from 'react-hook-form';
import { useUserContext } from 'providers/UserProvider';
import { ax } from 'providers/LoaderProvider';
import './login.scss';
import SEO from 'providers/HelmetProvider';
import melody from 'assets/audio/speed-car-cuted.mp3'
import Layout from 'components/routes/Layout';

const LogIn = () => {
  const { setUser } = useUserContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const audio = new Audio(melody);

  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [serverError, setServerError] = useState(null);
  const bg = changeNameImages('/assets/images/forms/bg.jpg');
  const bgTexture = changeNameImages('/assets/images/global/bg-texture.png');
  const logoCheco = changeNameImages('/assets/images/global/logo-checo-cards.png');
  const blur = changeNameImages('/assets/images/not-found/blur.png');
  const card1 = changeNameImages('/assets/images/cards/card-1.png');
  const card2 = changeNameImages('/assets/images/cards/card-2.png');
  const card3 = changeNameImages('/assets/images/cards/card-3.png');

  const onLogin = async (data) => {
    const dataForm = {
      correo: data.email,
      password: data.password
    };
    setServerError(null);
    ax
      .post(
        `${ process.env.REACT_APP_SERVER_URL }${ process.env.REACT_APP_ENDPOINT_LOGIN }`,
        dataForm)
      .then(async (res) => {
          if (res.status !== 200) {
            throw new Error(errorResponse.message || 'Error en el inicio de sesión');
          } else {
            localStorage.setItem('CHECO_TOKEN', res.data.token);
            localStorage.setItem('CHECO_USER', JSON.stringify(res.data.usuario));
            const saveCardId = localStorage.getItem('SAVED_CARDID');
            const sharedToken = localStorage.getItem('SHARED_TOKEN');
            const userData = {...res.data.usuario, token: res.data.token};
            setUser(userData);
            if (saveCardId) {
              if (sharedToken) {
                navigate(`/transfer-card/${saveCardId}/${sharedToken}`);
              } else {
                navigate(`/save-card/${saveCardId}`);
              }
            } else {
              navigate('/explorar');
            }
          }
      })
      .catch((err) => {
        setServerError(err.response.data.msg || 'Error');
      });
    audio.playbackRate = 2.0;
    audio.play();
  };
  const validateToken = async () => {
    try {
      const res = await UserService.validToken();
      if (res) {
        navigate('/');
      }
    } catch (err) {
      navigate('/login');
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  return (
    <>
      <SEO
        title={'Checo Cards | Login'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'} />
      <Layout id='login' classes='pt-main pb-18 relative forms'>
        <div className='flex-md'>
          <div className='col-md-2 relative d-none d-md-block'>
            <div className='bg cnt-h col-1 bg-texture'>
              <picture>
                <source srcSet={bgTexture.desk_avif + ' 1x, ' + bgTexture.desk2_avif + ' 2x'}
                  type="image/avif" />
                <source srcSet={bgTexture.desk_webp + ' 1x, ' + bgTexture.desk2_webp + ' 2x'}
                  type="image/webp" />
                <img className='col-1 full'
                  srcSet={bgTexture.desk + ' 1x, ' + bgTexture.desk2 + ' 2x'}
                  src={bgTexture.desk}
                  alt=''
                  width={720}
                  height={980} />
              </picture>
            </div>
            <div className='py-24'>
              <picture>
                <source srcSet={logoCheco.desk_avif + ' 1x, ' + logoCheco.desk2_avif + ' 2x'}
                  type="image/avif" />
                <source srcSet={logoCheco.desk_webp + ' 1x, ' + logoCheco.desk2_webp + ' 2x'}
                  type="image/webp" />
                <img className='img-logo-checo mx-auto col-1 relative'
                  srcSet={logoCheco.desk + ' 1x, ' + logoCheco.desk2 + ' 2x'}
                  src={logoCheco.desk}
                  alt=''
                  width={482}
                  height={180} />
              </picture>
            </div>

            <div className='py-24 relative'>
              <picture>
                <source srcSet={blur.desk_avif + ' 1x, ' + blur.desk2_avif + ' 2x'}
                  type="image/avif" />
                <source srcSet={blur.desk_webp + ' 1x, ' + blur.desk2_webp + ' 2x'}
                  type="image/webp" />
                <img className='img-blur mx-auto col-1 relative'
                  srcSet={blur.desk + ' 1x, ' + blur.desk2 + ' 2x'}
                  src={blur.desk}
                  alt=''
                  width={545}
                  height={341} />
              </picture>
              <div className='cards-content flex cnt mx-auto flex-center'>
                <picture>
                  <source srcSet={card1.desk_avif + ' 1x, ' + card1.desk2_avif + ' 2x'}
                    type="image/avif" />
                  <source srcSet={card1.desk_webp + ' 1x, ' + card1.desk2_webp + ' 2x'}
                    type="image/webp" />
                  <img className='img-card mx-auto col-1 card-one relative'
                    srcSet={card1.desk + ' 1x, ' + card1.desk2 + ' 2x'}
                    src={card1.desk}
                    alt=''
                    width={176}
                    height={264} />
                </picture>
                <picture>
                  <source srcSet={card2.desk_avif + ' 1x, ' + card2.desk2_avif + ' 2x'}
                    type="image/avif" />
                  <source srcSet={card2.desk_webp + ' 1x, ' + card2.desk2_webp + ' 2x'}
                    type="image/webp" />
                  <img className='img-card mx-auto col-1 card-two relative'
                    srcSet={card2.desk + ' 1x, ' + card2.desk2 + ' 2x'}
                    src={card2.desk}
                    alt=''
                    width={176}
                    height={264} />
                </picture>
                <picture>
                  <source srcSet={card3.desk_avif + ' 1x, ' + card3.desk2_avif + ' 2x'}
                    type="image/avif" />
                  <source srcSet={card3.desk_webp + ' 1x, ' + card3.desk2_webp + ' 2x'}
                    type="image/webp" />
                  <img className='img-card mx-auto col-1 card-three relative'
                    srcSet={card3.desk + ' 1x, ' + card3.desk2 + ' 2x'}
                    src={card3.desk}
                    alt=''
                    width={176}
                    height={264} />
                </picture>
              </div>
            </div>
          </div>
          <div className='px-container mb-24 relative col-md-2'>
            <div className='bg cnt-h col-1'>
              <picture>
                <source srcSet={`${ bg.sm_avif } 1x, ${ bg.sm2_avif } 2x`}
                  media="(max-width: 768px)" type="image/avif" />
                <source srcSet={`${ bg.sm_webp } 1x, ${ bg.sm2_webp } 2x`}
                  media="(max-width: 768px)" type="image/webp" />
                <source srcSet={bg.desk_avif + ' 1x, ' + bg.desk2_avif + ' 2x'}
                  type="image/avif" />
                <source srcSet={bg.desk_webp + ' 1x, ' + bg.desk2_webp + ' 2x'}
                  type="image/webp" />
                <img className='col-1'
                  srcSet={bg.desk + ' 1x, ' + bg.desk2 + ' 2x'}
                  src={bg.desk}
                  alt=''
                  width={375}
                  height={420} />
              </picture>
            </div>
            <h1 className='text-center text-venera mb-12 relative'>Bienvenido</h1>
            <div className='px-24 relative'>
              <p className='text-center'>Colecciona contenido exclusivo y <br />conviértete en el fan más apasionado de Checo Cards</p>
            </div>
            <div className='form mt-18 px-12 relative'>
              <form onSubmit={handleSubmit(onLogin)}>
                <div className='input-group'>
                  <div className='flex flex-start'>
                    <div className='relative col-1'>
                      <input
                        id='email'
                        type='email'
                        name='email'
                        inputMode="email"
                        {...register('email', {
                          required: 'El correo es obligatorio',
                          pattern: {
                            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                            message: 'Correo no válido'
                          }
                        })} />
                      {errors.email && <span className="error-message">{errors.email.message}</span>}
                    </div>
                    <label htmlFor='email'>Correo</label>
                  </div>
                </div>
                <div className='input-group'>
                  <div className='flex flex-start'>
                    <div className='relative col-1'>
                      <input
                        id='password'
                        type={showPass ? 'text' : 'password'}
                        name='password'
                        {...register('password', {
                          required: 'La contraseña es obligatoria',
                        })} />
                      {errors.password && <span className="error-message">{errors.password.message}</span>}
                      <div className='toggle-pass cnt-v' onClick={() => setShowPass(!showPass)}>
                        {showPass ? (
                          <img
                            src='/assets/images/global/i-eye-off-outline.svg'
                            alt='Mostrar contraseña' />
                        ) : (
                          <img
                            src='/assets/images/global/i-eye-outline.svg'
                            alt='Ocultar contraseña' />
                        )}
                      </div>
                    </div>
                    <label htmlFor='password'>Contraseña</label>
                  </div>
                </div>
                <div className='mb-18 mt-12'>
                  <p className='pl-24'>
                    <Link className='p-6' to='/restablecer-password'>¿Olvidaste tu contraseña?</Link>
                  </p>
                </div>
                <button type='submit' className='btns btns-blue mt-24'>
                  <span>Iniciar sesión</span>
                </button>
                {serverError && <div className="error-message text-center my-12">{serverError}</div>}
              </form>
              {/* <div className='divisor flex my-24 py-6'><span className='mx-6'>o</span></div>
    <button className='btns btns-special'>
      <img src='/assets/images/global/i-google.svg'
        width={20} height={20} alt='' />
      <span>Inicia sesión con Google</span>
    </button> */}
              <div className='text-center my-18'>
                <p>¿Aún no tienes cuenta?
                  <Link to='/registro' className='text-red'><u> Regístrate</u></Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export { LogIn };
