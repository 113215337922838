import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import changeNameImages from 'utils/changeNameImages';
import { useForm } from 'react-hook-form';
import { customPasswordValidator } from 'utils/customPasswordValidator';
import { ax } from 'providers/LoaderProvider';
import SEO from 'providers/HelmetProvider';
import Layout from 'components/routes/Layout';
import '../login/login.scss';
import './register.scss';

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [serverError, setServerError] = useState(null);
  const bg = changeNameImages('/assets/images/forms/bg.jpg');


  const onRegister = async (data) => {
    const dataForm = {
      nombre: data.name,
      correo: data.email,
      telefono: data.tel,
      password: data.password,
    };
    setServerError(null);
    ax
      .post(
        `${ process.env.REACT_APP_SERVER_URL }${ process.env.REACT_APP_ENDPOINT_REGISTER }`, 
        dataForm)
      .then(async (res) => {
        if (res.status !== 200) {
          const errorResponse = await response.json();
          throw new Error(errorResponse.message || 'Error en el registo');
        } else {
          localStorage.setItem('CHECO_FIRST_TIME', true);
          navigate('/login');
        }
        
      })
      .catch((err) => {
        setServerError(err.response.data.msg || 'Error en el registro');
      });
  };

  return (
    <>
      <SEO
        title={'Checo Cards | Registro'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'} />
      <Layout id='register' classes='pt-main pb-18 relative forms'>
        <div className='bg cnt-h col-1'>
          <picture>
            <source srcSet={`${ bg.sm_avif } 1x, ${ bg.sm2_avif } 2x`}
              media="(max-width: 768px)" type="image/avif" />
            <source srcSet={`${ bg.sm_webp } 1x, ${ bg.sm2_webp } 2x`}
              media="(max-width: 768px)" type="image/webp" />
            <source srcSet={bg.desk_avif + ' 1x, ' + bg.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={bg.desk_webp + ' 1x, ' + bg.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='col-1'
              srcSet={bg.desk + ' 1x, ' + bg.desk2 + ' 2x'}
              src={bg.desk}
              alt=''
              width={375}
              height={420} />
          </picture>
        </div>
        <div className='px-container mb-24 relative'>
          <h1 className='text-center text-venera mb-12'>Regístrate</h1>
          <div className='px-24'>
            <p className='text-center txt-top'>
              Vive la experiencia de coleccionar contenido exclusivo de Checo Pérez
            </p>
          </div>
          <div className='form mt-18 px-12'>
            <form
              onSubmit={handleSubmit(onRegister)}
              className='flex-md flex-md-wrap flex-md-baseline'
            >
              <div className='input-group content-input'>
                <div className='flex flex-start'>
                  <div className='relative col-1'>
                    <input
                      id='name'
                      type='text'
                      {...register('name', { required: 'El nombre es obligatorio' })} />
                    {errors.name && <span className='error-message'>{errors.name.message}</span>}
                  </div>
                  <label htmlFor='name'>Nombre</label>
                </div>
              </div>
              <div className='input-group content-input'>
                <div className='flex flex-start'>
                  <div className='relative col-1'>
                    <input
                      id='email'
                      type='email'
                      inputMode="email"
                      {...register('email', {
                        required: 'El correo es obligatorio',
                        pattern: {
                          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                          message: 'Correo no válido',
                        },
                      })} />
                    {errors.email && <span className='error-message'>{errors.email.message}</span>}
                  </div>
                  <label htmlFor='email'>Correo</label>
                </div>
              </div>
              <div className='input-group content-input'>
                <div className='flex flex-start'>
                  <div className='relative col-1'>
                    <input
                      id='tel'
                      type='text'
                      inputMode="numeric"
                      {...register('tel', {
                        required: 'El teléfono es obligatorio',
                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'Teléfono no válido',
                        },
                      })} />
                    {errors.tel && <span className='error-message'>{errors.tel.message}</span>}
                  </div>
                  <label htmlFor='tel'>Teléfono</label>
                </div>
              </div>
              <div className='pt-18 content-input'>
                <div className='input-group'>
                  <div className='flex flex-start'>
                    <div className='relative col-1'>
                      <input
                        id='password'
                        type={showPass ? 'text' : 'password'}
                        {...register('password', {
                          validate: customPasswordValidator,
                        })} />
                      {errors.password && (
                        <span className='error-message'>{errors.password.message}</span>
                      )}
                      <div className='toggle-pass cnt-v' onClick={() => setShowPass(!showPass)}>
                        {showPass ? (
                          <img
                            src='/assets/images/global/i-eye-off-outline.svg'
                            alt='Mostrar contraseña' />
                        ) : (
                          <img
                            src='/assets/images/global/i-eye-outline.svg'
                            alt='Ocultar contraseña' />
                        )}
                      </div>
                    </div>
                    <label htmlFor='password'>Contraseña</label>
                  </div>
                </div>
                <div className='px-24 my-18'>
                  <div className='input-group-checkbox'>
                    <label htmlFor='terms' className='flex'>
                      <input
                        type='checkbox'
                        id='terms'
                        {...register('terms', { required: 'Debes aceptar los términos y condiciones' })} />
                      <small>
                        Al registrarme acepto{' '}
                        <a href='/terminos-y-condiciones' target='_blank'>
                          <u>Términos y Condiciones</u>
                        </a>{' '}
                        así como el{' '}
                        <a href='/aviso-de-privacidad' target='_blank'>
                          <u>Aviso de Privacidad</u>
                        </a>
                      </small>
                    </label>
                    {errors.terms && <span className='error-message'>{errors.terms.message}</span>}
                  </div>
                </div>
              </div>
              <button className='btns btns-red mt-24' type='submit'>
                <span>Quiero registrarme</span>
              </button>
              {serverError && <div className="error-message text-center my-12">{serverError}</div>}
            </form>
            {/* <div className='divisor flex my-24 py-6'>
      <span className='mx-6'>o</span>
    </div>
    <button className='btns btns-special'>
      <img src='/assets/images/global/i-google.svg' width={20} height={20} alt='' />
      <span>Inicia sesión con Google</span>
    </button> */}
            <div className='text-center my-18'>
              <p>
                ¿Ya tienes cuenta?{' '}
                <Link to='/login' className='text-blue'>
                  <u>Inicia sesión</u>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Layout></>
  );
};

export { Register };
