import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import changeNameImages from 'utils/changeNameImages';
import { customPasswordValidator } from 'utils/customPasswordValidator';
import { Toastify } from 'components/toastify/toastify';
import SEO from 'providers/HelmetProvider';
import Layout from 'components/routes/Layout';

const ChangePassword = () => {
  const bg = changeNameImages('/assets/images/forms/bg.jpg');
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [serverError, setServerError] = useState(null);
  const params = useParams();
  const toastifyRef = useRef();
  const navigate = useNavigate();

  const onValidateTokenPassword = async () => {
    const token = params.token;
    setServerError(null);
    try {
      const response = await fetch(
        (`${ process.env.REACT_APP_SERVER_URL }${ process.env.REACT_APP_ENDPOINT_VALIDATE_PASSWORD_TOKEN }`).replace('@token', token),
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      if (response.status !== 200) {
        const errorResponse = await response.json();
        toastifyRef.current.notify(
          'error',
          'El link ha expirado, intenta de nuevo.' 
        );
        navigate('/login');
        throw new Error(errorResponse.message || 'Error al validar el token');
      } else {
        const res = await response.json();
        setUserInfo(res.updateToken[0]);

      }
    } catch (error) {
      setServerError(error.message);
    }
  };

  const onChangePassword = async (data) => {
    const dataForm = {
      correo: userInfo.correo,
      password: data.password
    };
    setServerError(null);
    try {
      const response = await fetch(
        (`${ process.env.REACT_APP_SERVER_URL }${ process.env.REACT_APP_ENDPOINT_UPDATE_PASSWORD }`).replace('@token', userInfo.token),
        {
          method: 'POST',
          body: JSON.stringify(dataForm),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || 'Error al cambiar la contraseña');
      } else {
        toastifyRef.current.notify(
          'success',
          'La contraseña ha sido cambiada.'
        );
        navigate('/login');
      }
    } catch (error) {
      setServerError(error.message);
    }
  };

  useEffect(() => {
    onValidateTokenPassword();
  }, []);

  return (
    <>
      <SEO
        title={'Checo Cards | Cambiar contraseña'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'} />
      <Layout id='recover-pass' classes='pt-main pb-18 relative forms'>
        <div className='bg cnt-h col-1'>
          <picture>
            <source srcSet={bg.desk_avif + ' 1x, ' + bg.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={bg.desk_webp + ' 1x, ' + bg.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='col-1'
              srcSet={bg.desk + ' 1x, ' + bg.desk2 + ' 2x'}
              src={bg.desk}
              alt=''
              width={375}
              height={420} />
          </picture>
        </div>
        <div className='px-container mb-24 relative'>
          <h1 className='text-center text-venera mb-12'>Cambiar contraseña</h1>
          <div className='px-24'>
            <p className='text-center'>Para restablecer tu contraseña, <br />ingresa la información a continuación</p>
          </div>
          <div className='form mt-18 px-12'>
            <form onSubmit={handleSubmit(onChangePassword)}>
              <div className='input-group'>
                <div className='flex flex-start'>
                  <div className='relative col-1'>
                    <input
                      id='password'
                      type={showPass ? 'text' : 'password'}
                      {...register('password', {
                        validate: customPasswordValidator,
                      })} />
                    {errors.password && (
                      <span className='error-message'>{errors.password.message}</span>
                    )}
                    <div className='toggle-pass cnt-v' onClick={() => setShowPass(!showPass)}>
                      {showPass ? (
                        <img
                          src='/assets/images/global/i-eye-off-outline.svg'
                          alt='Mostrar contraseña' />
                      ) : (
                        <img
                          src='/assets/images/global/i-eye-outline.svg'
                          alt='Ocultar contraseña' />
                      )}
                    </div>
                  </div>
                  <label htmlFor='password'>Nueva contraseña</label>
                </div>
              </div>
              <div className='input-group'>
                <div className='flex flex-start'>
                  <div className='relative col-1'>
                    <input
                      id='password-confirm'
                      type={showPassConfirm ? 'text' : 'password'}
                      {...register('passwordConfirm', {
                        validate: customPasswordValidator,
                        validate: (value) => {
                          return value === watch('password') || 'Las contraseñas no coinciden.';
                        }
                      })} />
                    {errors.passwordConfirm && (
                      <span className='error-message'>{errors.passwordConfirm.message}</span>
                    )}
                    <div className='toggle-pass cnt-v' onClick={() => setShowPassConfirm(!showPassConfirm)}>
                      {showPassConfirm ? (
                        <img
                          src='/assets/images/global/i-eye-off-outline.svg'
                          alt='Mostrar contraseña' />
                      ) : (
                        <img
                          src='/assets/images/global/i-eye-outline.svg'
                          alt='Ocultar contraseña' />
                      )}
                    </div>
                  </div>
                  <label htmlFor='password-confirm'>Confirmar Contraseña</label>
                </div>
              </div>
              <button className='btns btns-blue mt-24'>
                <span>Cambiar contraseña</span>
              </button>
              {serverError && <div className="error-message text-center my-12">{serverError}</div>}
              <Toastify ref={toastifyRef} />
            </form>
            <div className='text-center my-18'>
              <p>Regresar a <Link to="/login"
                className="text-blue"
              ><u>Iniciar sesión</u></Link></p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export { ChangePassword }
