import React from 'react'

import changeNameImages from 'utils/changeNameImages';
import { VideoTutorial } from 'components/video/video';

const HomeTutorial = ({logo = false}) => {
  const bg_tutorial = changeNameImages('/assets/images/home/bg-tutorial.jpg');

  return (
    <div className='home-tutorial px-container relative py-24 pt-md-0'>
      <div className='py-24 py-md-0'>
        <div className='bg-tutorial cnt full'>
          <picture>
            <source srcSet={bg_tutorial.desk_avif + ' 1x, ' + bg_tutorial.desk2_avif + ' 2x'} 
              type="image/avif" />
            <source srcSet={bg_tutorial.desk_webp + ' 1x, ' + bg_tutorial.desk2_webp + ' 2x'} 
              type="image/webp" />
            <img className='full cnt'
              srcSet={bg_tutorial.desk + ' 1x, ' + bg_tutorial.desk2 + ' 2x'}
              src={bg_tutorial.desk} 
              alt=''
              width={375}
              height={400}
            />
          </picture>
        </div>
        <div className='relative'>
          <h2 className='h1 text-venera text-white text-center'>¿Cómo jugar?</h2>
          {
            logo && 
            <div className='logo mx-auto mt-24'>
              <img className='col-1'
                src={'/assets/images/home/logo-checo-cards.svg'} 
                alt='Logo - Checo cards'
                width={150}
                height={57}
              />
            </div>
          }
          <div className='mt-24 px-12 pt-12 pb-24 mt-md-0'>
            <VideoTutorial />
          </div>
        </div>  
      </div>  
    </div>
  );
}

export {HomeTutorial}