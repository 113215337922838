const cacsChecocards = `<table>
  <tr>
    <td>CAC TELCEL CABO SAN LUCAS EXPRES</td>
    <td>
      <a href="https://maps.app.goo.gl/WWGkkDXJLv3KQmX57"
        target="_blank"
        rel="noopener noreferrer">De las Brisas 2404, Brisas del Pacifico, 23473 Cabo San Lucas, B.C.S.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CIUDAD CONSTITUCIÓN</td>
    <td>
      <a href="https://maps.app.goo.gl/9x8XurZJgFtNN4U47"
        target="_blank"
        rel="noopener noreferrer">Agustín Olacheaa 155 Sur, Real, Centro, 23600 Cdad. Constitución, B.C.S.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ENSENADA</td>
    <td>
      <a href="https://maps.app.goo.gl/PWL3GETnnP94r5Q49"
        target="_blank"
        rel="noopener noreferrer">Av. Riveroll 425, Zona Centro, 22800 Ensenada, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ENSENADA II</td>
    <td>
      <a href="https://maps.app.goo.gl/zoiKRrmHXxCZ8HA59"
        target="_blank"
        rel="noopener noreferrer">Av. Reforma Esq. Calle Hierro Local 1-A, Carlos Pacheco, 22895 Ensenada, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LA PAZ I</td>
    <td>
      <a href="https://maps.app.goo.gl/J4H1FJXEuNriFpwf8"
        target="_blank"
        rel="noopener noreferrer">Blvd. Constituyentes De 1975 No.4120, Local S-01 Al S-06, Sector Inalapa, 23098 La Paz, B.C.S.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LA PAZ II</td>
    <td>
      <a href="https://maps.app.goo.gl/mv8u9cZR3MxEDCL5A"
        target="_blank"
        rel="noopener noreferrer">Blvd. Forjadores De Sudcalifornia. 4215, Int. F-2, Fracc. Ex, Fuerza Aérea, 23070 La Paz, B.C.S.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LA PAZ III</td>
    <td>
      <a href="https://maps.app.goo.gl/3ed5zNXUgudKZaW4A"
        target="_blank"
        rel="noopener noreferrer">5 de Febrero 835, Pueblo Nuevo, 23060 La Paz, B.C.S.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LOS CABOS</td>
    <td>
      <a href="https://maps.app.goo.gl/u56cmxUkru7u9z7KA"
        target="_blank"
        rel="noopener noreferrer">Blvd. Lázaro Cárdenas S/N-Local 26, Colonia el medano, 23479 Cabo San Lucas, B.C.S.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MEXICALI II</td>
    <td>
      <a href="https://maps.app.goo.gl/xdSS1EGFuwtj6Lpa8"
        target="_blank"
        rel="noopener noreferrer">Av. Francisco I. Madero 837, Zona Centro 2Da Sección, 21100 Mexicali, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MEXICALI III</td>
    <td>
      <a href="https://maps.app.goo.gl/sm5hxNRpKGLYcwqL8"
        target="_blank"
        rel="noopener noreferrer">C. Novena 300, Nuevo Mexicali, 21399 Mexicali, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MEXICALI IV</td>
    <td>
      <a href="https://maps.app.goo.gl/KtSztiBB6ZKRyb8r5"
        target="_blank"
        rel="noopener noreferrer">Blvd. Lázaro Cárdenas 2200-Int. L-A 82, El Porvenir, 21220 Mexicali, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MONARCA</td>
    <td>
      <a href="https://maps.app.goo.gl/PJrcxoDjkZSFrhRd6"
        target="_blank"
        rel="noopener noreferrer">Blvd. Manuel Jesus Clouthier 18561, Lago Sur, 22535 Tijuana, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL OTAY</td>
    <td>
      <a href="https://maps.app.goo.gl/2wCVMAerTPGCawE87"
        target="_blank"
        rel="noopener noreferrer">Calzada Tecnologico No. 2100, Locales 49 50 51 Y 52, Plaza Otay, 22450 Tijuana, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PLAZA RIO</td>
    <td>
      <a href="https://maps.app.goo.gl/vJ1qrMdsHZqmy6M88"
        target="_blank"
        rel="noopener noreferrer">P.º de los Héroes 98, Zona Urbana Rio Tijuana, 22010 Tijuana, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PORTICOS 5 Y 10</td>
    <td>
      <a href="https://maps.app.goo.gl/xegpE7RKVfEUTTVU7"
        target="_blank"
        rel="noopener noreferrer">Blvd. Gustavo Díaz Ordaz 14691-Int. 10, Chapultepec California, 22610 Tijuana, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ROSARITO</td>
    <td>
      <a href="https://maps.app.goo.gl/32ojD2nvxgdSPyFn9"
        target="_blank"
        rel="noopener noreferrer">Blvd. Bénito Juárez No. 5075, Locales 13 Y 14, Norte Villa Floresta, 22710 Playas de Rosarito, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN JOSE DEL CABO</td>
    <td>
      <a href="https://maps.app.goo.gl/gBxs5xEV6q37EXSd7"
        target="_blank"
        rel="noopener noreferrer">Carr. Transpeninsular Km 31, Zona Conurbada, El Rosarito, 23439 San José del Cabo, B.C.S.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN LUIS RIO COLORADO</td>
    <td>
      <a href="https://maps.app.goo.gl/ZvDkU3SVZ1ZjcieL6"
        target="_blank"
        rel="noopener noreferrer">Av. Zaragoza Y Calle 26 2593, Residencias, 83448 San Luis Río Colorado, Son.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN MACRO PLAZA</td>
    <td>
      <a href="https://maps.app.goo.gl/19yz2iAWjo8ZjnUf9"
        target="_blank"
        rel="noopener noreferrer">Blvd. Insurgentes 18015-Pad 6, Río Tijuana, 22226 Tijuana, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN MEXICALI I</td>
    <td>
      <a href="https://maps.app.goo.gl/oSS5jzaQekBGLZax6"
        target="_blank"
        rel="noopener noreferrer">Calzada Justo Sierra Y Cuauhtémoc 51, Loc. 17, Cuauhtémoc, 21200 Mexicali, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TECATE</td>
    <td>
      <a href="https://maps.app.goo.gl/bGmp3FM2PTXLHqTX9"
        target="_blank"
        rel="noopener noreferrer">Boulevard Los Encinos No. 700, Local 42 Al 44, Loma Alta, 21480 Tecate, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TIJUANA PLAYAS</td>
    <td>
      <a href="https://maps.app.goo.gl/dWqjFmk5n7M8PNNK8"
        target="_blank"
        rel="noopener noreferrer">Av. Ensenada, P.º Playas de Tijuana 24 Esq, Playas, De Tijuana, 22200 Tijuana, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TIJUANA RÍO</td>
    <td>
      <a href="https://maps.app.goo.gl/Ptw6jwkdWosVUB4e9"
        target="_blank"
        rel="noopener noreferrer">Zona Urbana Rio, P.º de los Héroes 9539, Zona Urbana Rio Tijuana, 22320 Tijuana, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TIJUANA VII PASEO 2000</td>
    <td>
      <a href="https://maps.app.goo.gl/Wdy86EMz8a1io5PT9"
        target="_blank"
        rel="noopener noreferrer">Corredor Tijuana - Rosarito 2000 26135-Int. B Pb, Ejido Francisco, 22235 Tijuana, B.C.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL AGUAPRIETA</td>
    <td>
      <a href="https://maps.app.goo.gl/gXvcfrahR5CXhFLZ7"
        target="_blank"
        rel="noopener noreferrer">Av. 21 2001, Vallarta, 84260 Agua Prieta, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CABORCA</td>
    <td>
      <a href="https://maps.app.goo.gl/1uwsSsjGYKynmu1A6"
        target="_blank"
        rel="noopener noreferrer">Av. Álvaro Obregón 1, Centro, 83600 Heroica Caborca, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CULIACÁN</td>
    <td>
      <a href="https://maps.app.goo.gl/fy4NSQAkYNmgTdA59"
        target="_blank"
        rel="noopener noreferrer">Prol. Álvaro Obregón 1796, Esq. Juan De Dios, Colinas de San Miguel, 80228 Culiacán Rosales, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CULIACAN II</td>
    <td>
      <a href="https://maps.app.goo.gl/g1S4nHjJzVoThEYR8"
        target="_blank"
        rel="noopener noreferrer">Javier Mina No. 1657, entre Insurgentes Y Lázaro Cárdenas, Centro Sinaloa, Centro, 80220 Culiacán Rosales, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CULIACÁN III</td>
    <td>
      <a href="https://maps.app.goo.gl/4ZLHr7Pi3gg26Tvv8"
        target="_blank"
        rel="noopener noreferrer">Diego Valdéz Ríos 1676-Loc. Sa-04, Desarrollo Urbano Tres Ríos, 80000 Culiacán Rosales, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CULIACÁN IV</td>
    <td>
      <a href="https://maps.app.goo.gl/i3rSSHaL5xkodBBy6"
        target="_blank"
        rel="noopener noreferrer">Calz. Aeropuerto 5528, Gasolinera del Valle, 80140 Culiacán Rosales, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CULIACÁN V ABASTOS</td>
    <td>
      <a href="https://maps.app.goo.gl/oSNFAAUvDuAdAFHSA"
        target="_blank"
        rel="noopener noreferrer">Av. Patria N° 3358 Sur, Providencia, 80290 Culiacán Rosales, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CULIACAN VII LA ISLA</td>
    <td>
      <a href="https://maps.app.goo.gl/ekWPGQGAUdwVuVSr6"
        target="_blank"
        rel="noopener noreferrer">Av. Cancun N° 155 Nte, Local 5, Isla Musala, Isla Bonita, 80065 Culiacán Rosales, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GUAMUCHIL</td>
    <td>
      <a href="https://maps.app.goo.gl/Gr25WKuCnRzG2eE48"
        target="_blank"
        rel="noopener noreferrer">Av. Mariano Matamoros, C. Jesús Rodríguez esq, Juárez, 81430 Guamúchil, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GUASAVE</td>
    <td>
      <a href="https://maps.app.goo.gl/GaSV4t6LZ4rLQTFS7"
        target="_blank"
        rel="noopener noreferrer">Blvd. Central 635, Centro, 81000 Guasave, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GUAYMAS</td>
    <td>
      <a href="https://maps.app.goo.gl/RyxRhcPeGihrBcRn6"
        target="_blank"
        rel="noopener noreferrer">Calzada Agustín, García López 847-Int 1, Guadalupe, 85440 Guaymas, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL HERMOSILLO ROSALES</td>
    <td>
      <a href="https://maps.app.goo.gl/gZ2msD45tktokvY16"
        target="_blank"
        rel="noopener noreferrer">Calle Av. Rosales 86, Centro, 83000 Hermosillo, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL HERMOSILLO SENDERO</td>
    <td>
      <a href="https://maps.app.goo.gl/mEGGY8EaPsFqCzmp6"
        target="_blank"
        rel="noopener noreferrer">Periférico Ote Esq, A Sahuaripa 1274, Y Griega, 83290 Hermosillo, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL HERMOSILLO TORRE</td>
    <td>
      <a href="https://maps.app.goo.gl/hBRmZyB2EQhCMBde6"
        target="_blank"
        rel="noopener noreferrer">Blvd. Kino, Ramón Corral 514 Esq, Country Club, 83010 Hermosillo, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL HERMOSILLO V MORELOS</td>
    <td>
      <a href="https://maps.app.goo.gl/3F6C5UwEjzaz4fEk7"
        target="_blank"
        rel="noopener noreferrer">Cuartel Xv, Blvd.José Morelos. 355, Local 3-4 Y 5, Zona Militar, 83138 Hermosillo, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL HERMOSILLO VI QUIROGA</td>
    <td>
      <a href="https://maps.app.goo.gl/xgJ6uHUgajX3rGjb6"
        target="_blank"
        rel="noopener noreferrer">Blvd. Jesús García Morales 547-25, El Llano, Quinta Emilia, 83210 Hermosillo, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL HERMOSILLO VII PROGRESO</td>
    <td>
      <a href="https://maps.app.goo.gl/x2Wgg2xauVr69RcZ7"
        target="_blank"
        rel="noopener noreferrer">Blvd. Juan Bautista De Escalante No. 663, Subancla A, Solidaridad, 83116 Hermosillo, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LOS MOCHIS</td>
    <td>
      <a href="https://maps.app.goo.gl/yLzzKXCrcYck3kPw8"
        target="_blank"
        rel="noopener noreferrer">Blvd. Antonio Rosales 502, Centro, 81200 Los Mochis, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LOS MOCHIS II</td>
    <td>
      <a href="https://maps.app.goo.gl/csXL2vU2LcW7si7z6"
        target="_blank"
        rel="noopener noreferrer">Blvd. Antonio Rosales 1080, Blvd Centenario Esq-sur, Centro, 81000 Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LOS MOCHIS III</td>
    <td>
      <a href="https://maps.app.goo.gl/zEowggB7DH9m4Vc27"
        target="_blank"
        rel="noopener noreferrer">Blvd Centenario 750 Ote, Morelos, 81290 Los Mochis, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MAZATLÁN</td>
    <td>
      <a href="https://maps.app.goo.gl/J7FHFaoSM8fniSh18"
        target="_blank"
        rel="noopener noreferrer">Av. del Delfin 6402-Local 146, Col, Marina Mazatlán, 82103 Mazatlán, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MAZATLÁN II</td>
    <td>
      <a href="https://maps.app.goo.gl/V3QgcuBsGxLD8g26A"
        target="_blank"
        rel="noopener noreferrer">Av Juan Carrasco 160, entre Pesqueira Y Rio Papagayo, Lomas del Mar, 82010 Mazatlán, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MAZATLÁN III</td>
    <td>
      <a href="https://maps.app.goo.gl/QwDxzppBmL3kvLqf7"
        target="_blank"
        rel="noopener noreferrer">Av. Reforma S/N-Local V-2, entre Av. Apolo Y De Universidad, Alameda, 82123 Mazatlán, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MAZATLAN IV SANTA ROSA</td>
    <td>
      <a href="https://maps.app.goo.gl/XKZCC2mRt3X68smHA"
        target="_blank"
        rel="noopener noreferrer">Av. Luis Donaldo Colosio M. No. 17301, Libramiento Sur, Valle Dorado, 82132 Mazatlán, Sin.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL NAVOJOA</td>
    <td>
      <a href="https://maps.app.goo.gl/Y6mMrnAN26kbGVNi6"
        target="_blank"
        rel="noopener noreferrer">Calle Gral. I. Pesqueira 807-A, Reforma, 85830 Navojoa, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL NOGALES II COLOSIO</td>
    <td>
      <a href="https://maps.app.goo.gl/cxCoXB1chPAct5Tq8"
        target="_blank"
        rel="noopener noreferrer">Blvd. Luis Donaldo Colosio M. No. 2700, Unidad Deportiva, 84065 Nogales, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL OBREGÓN I</td>
    <td>
      <a href="https://maps.app.goo.gl/ya9nUChV9RYyskfc9"
        target="_blank"
        rel="noopener noreferrer">Av. Miguel Alemán 645, Norte, Urb. No. 4, 85000 Cdad. Obregón, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL OBREGÓN II</td>
    <td>
      <a href="https://maps.app.goo.gl/MZQ8AkpTGbFESphA7"
        target="_blank"
        rel="noopener noreferrer">Calle Vicente Guerrero 1057-Local C-1, Cumuripa, 85149 Cdad. Obregón, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL OBREGON III BORLAUG</td>
    <td>
      <a href="https://maps.app.goo.gl/5sB8Guqf8SQWFRvAA"
        target="_blank"
        rel="noopener noreferrer">Blvd. Dr. Norman E. Borlaug 7301-Sur Subancla A, Hacienda Del Sol, 85097 Cdad. Obregón, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PUERTO PEÑASCO</td>
    <td>
      <a href="https://maps.app.goo.gl/Tjs1HLuDyUDG81ve8"
        target="_blank"
        rel="noopener noreferrer">Blvd. Benito Juárez García s/n, Centro, 83550 Puerto Peñasco, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHIHUAHUA I</td>
    <td>
      <a href="https://maps.app.goo.gl/43mvooXT8i43KG9g7"
        target="_blank"
        rel="noopener noreferrer">Av. Luis Donaldo Colosio Murrieta 3512 Fracc. Las, Haciendas IV, 31215 Chihuahua, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHIHUAHUA II</td>
    <td>
      <a href="https://maps.app.goo.gl/jhrAq4odmoqnJvth9"
        target="_blank"
        rel="noopener noreferrer">Av. Glandorf 3306, San Felipe I Etapa, 31203 Chihuahua, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHIHUAHUA III</td>
    <td>
      <a href="https://maps.app.goo.gl/i1raySTTZ85ZxFQt9"
        target="_blank"
        rel="noopener noreferrer">Catalpa 1301, Las Granjas, 31100 Chihuahua, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHIHUAHUA IV</td>
    <td>
      <a href="https://maps.app.goo.gl/sT95CkSd95p6Vdxt5"
        target="_blank"
        rel="noopener noreferrer">Av. Independencia 100, Zona Centro, 31000 Chihuahua, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHIHUAHUA V</td>
    <td>
      <a href="https://maps.app.goo.gl/Cnos3v18g9tiGvL56"
        target="_blank"
        rel="noopener noreferrer">Blvd. Fuentes Mares Y Av. Nueva España 1901, Local 1, Mármol Viejo, 31063 Chihuahua, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHIHUAHUA VI</td>
    <td>
      <a href="https://maps.app.goo.gl/pqYAHrvF6hUVCuKb7"
        target="_blank"
        rel="noopener noreferrer">Av Cristobal Colón 14549, Paseos de Chihuahua, 31125 Chihuahua, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHIHUAHUA VII</td>
    <td>
      <a href="https://maps.app.goo.gl/xoaicS8F8V2KWbpy8"
        target="_blank"
        rel="noopener noreferrer">Indiana 3307 Sub Ancla C, Virreyes, 31214 Chihuahua, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHIHUAHUA VIII</td>
    <td>
      <a href="https://maps.app.goo.gl/M9SEBtrMW8JFq8sv9"
        target="_blank"
        rel="noopener noreferrer">Perif. de la Juventud 3501-Local J-11, Puerta de Hierro, 31207 Chihuahua, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CIUDAD JUÁREZ I</td>
    <td>
      <a href="https://maps.app.goo.gl/wmXFZys22vTfwaDA6"
        target="_blank"
        rel="noopener noreferrer">Av. Paseo Triunfo de la República 4051-Local 17, Monumental, 32310 Juárez, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CIUDAD JUÁREZ II</td>
    <td>
      <a href="https://maps.app.goo.gl/YqcY7mexHxZ164DeA"
        target="_blank"
        rel="noopener noreferrer">Av. Ejército Nacional 2926-Local A2, Partido Iglesias, 32500 Juárez, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CIUDAD JUÁREZ III</td>
    <td>
      <a href="https://maps.app.goo.gl/DWRgXiBLfHLCyWZx8"
        target="_blank"
        rel="noopener noreferrer">Boulevard Zaragoza 6008 SA-5 B18 y B19, Parques Industriales, 32685 Juárez, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CIUDAD JUÁREZ V</td>
    <td>
      <a href="https://maps.app.goo.gl/ivBNzHFG6BHwR2p38"
        target="_blank"
        rel="noopener noreferrer">Av. Tecnológico 1770-4, Fuentes del Valle, 32500 Juárez, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CIUDAD JUÁREZ VI</td>
    <td>
      <a href="https://maps.app.goo.gl/CJuirDfYMxV3uCMh7"
        target="_blank"
        rel="noopener noreferrer">Av. Vicente Guerrero 308 Locales E34-E37, Centro, 32500 Juárez, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CIUDAD JUÁREZ VII</td>
    <td>
      <a href="https://maps.app.goo.gl/YNZ8bZbAwpAehPKr9"
        target="_blank"
        rel="noopener noreferrer">Blvr. Independencia 2810-Local N, Lote Bravo, 32695 Juárez, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CUAHUTÉMOC II</td>
    <td>
      <a href="https://maps.app.goo.gl/tJKeSavJgHHUKfuU9"
        target="_blank"
        rel="noopener noreferrer">Carr. Cuauhtemoc-Alvaro Obregon Km 7 Campo 22, 31607 Cuauhtémoc, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CUAUHTÉMOC</td>
    <td>
      <a href="https://maps.app.goo.gl/bK8HNVjmtMfyhPU17"
        target="_blank"
        rel="noopener noreferrer">Av.Morelos 555, Zona Centro, 31500 Cuauhtémoc, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL DELICIAS</td>
    <td>
      <a href="https://maps.app.goo.gl/AVF8Bmd3hi5NjUQz8"
        target="_blank"
        rel="noopener noreferrer">Esq. Con, Av. 6A Oriente, Av. 7a. Ote. 701, Oriente 1, 33000 Delicias, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL DELICIAS II</td>
    <td>
      <a href="https://maps.app.goo.gl/L2EYwfx7X66tjpK9A"
        target="_blank"
        rel="noopener noreferrer">Calle Tercera Nte. 311, Centro, 33000 Delicias, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL DURANGO</td>
    <td>
      <a href="https://maps.app.goo.gl/fjGoYTTXL2ndHNhi8"
        target="_blank"
        rel="noopener noreferrer">Av. 20 de Noviembre 1011, Nueva Vizcaya, 34080 Durango, Dgo.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL DURANGO II</td>
    <td>
      <a href="https://maps.app.goo.gl/Kf28PTCYnRsJUgWB9"
        target="_blank"
        rel="noopener noreferrer">C. Aquiles Serdan 500, Zona Centro, 34000 Durango, Dgo.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL DURANGO III</td>
    <td>
      <a href="https://maps.app.goo.gl/AMZjwzigkazDFxSi8"
        target="_blank"
        rel="noopener noreferrer">Blvd. Felipe Pescador 1401-Local A-01,02 Y 03, La Esperanza, 34080 Durango, Dgo.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL DURANGO IV</td>
    <td>
      <a href="https://maps.app.goo.gl/we1RGM8Coo1bWjMXA"
        target="_blank"
        rel="noopener noreferrer">Blvd. Domingo Arrieta 601, El Refugio, 34170 Durango, Dgo.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PARRAL</td>
    <td>
      <a href="https://maps.app.goo.gl/NKxUZJc3Pe9oh8ty8"
        target="_blank"
        rel="noopener noreferrer">Av. A. Ortiz Mena 293, Progreso, 33820 Hidalgo, Chih.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN PEDRO</td>
    <td>
      <a href="https://maps.app.goo.gl/jHyB2obX2ZYAdx8DA"
        target="_blank"
        rel="noopener noreferrer">Miguel Hidalgo 27, Centro, 27800 San Pedro, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN TORREÓN VI</td>
    <td>
      <a href="https://maps.app.goo.gl/xYPtH8c9g9jEFbLf7"
        target="_blank"
        rel="noopener noreferrer">Av Morelos 858, Zona Centro, 35000 Torreón, Dgo.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TORREÓN I</td>
    <td>
      <a href="https://maps.app.goo.gl/L1q9cKm9tiojZwi26"
        target="_blank"
        rel="noopener noreferrer">Av. Juarez 1810, Primero de Cobián Centro, 27000 Torreón, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TORREÓN II</td>
    <td>
      <a href="https://maps.app.goo.gl/2MFtC2FtsqjhfH139"
        target="_blank"
        rel="noopener noreferrer">Esq. Aldama, Blvd. Miguel Alemán S/N, Centro, 35000 Gómez Palacio, Dgo.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TORREON III GALERIAS</td>
    <td>
      <a href="https://maps.app.goo.gl/ypNCHVcWqKSFdwG87"
        target="_blank"
        rel="noopener noreferrer">Periférico Raúl López Sánchez 6000-Local 278, Residencial el Fresno, 27018 Torreón, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TORREON IV COLON</td>
    <td>
      <a href="https://maps.app.goo.gl/zTuD24TQQrwAQT376"
        target="_blank"
        rel="noopener noreferrer">Calz Colón 210 Norte, Primero de Cobián Centro, 27000 Torreón, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TORREON V INTERMALL</td>
    <td>
      <a href="https://maps.app.goo.gl/VxPQTRsKJRV362eX8"
        target="_blank"
        rel="noopener noreferrer">Calz José Vasconcelos 1955-Locales 1006 Y 1007, Residencial Tecnológico, 27272 Torreón, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TORREÓN VII</td>
    <td>
      <a href="https://maps.app.goo.gl/jX1sMqMJcVvjyYHe6"
        target="_blank"
        rel="noopener noreferrer">Blvd Independencia, Oriente 1300, 27010 Torreón, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ACUÑA</td>
    <td>
      <a href="https://maps.app.goo.gl/fuBAXh9Kp9CBVdn98"
        target="_blank"
        rel="noopener noreferrer">Telcel Acuña, C. Monclova 2040, Los Fresnos I, 26250 Cd Acuña, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ANÁHUAC</td>
    <td>
      <a href="https://maps.app.goo.gl/AJbgMFXDHV4cyTSUA"
        target="_blank"
        rel="noopener noreferrer">Plaza Stiva, Avenida Universidad 407 Norte-Local 4 y 5, Roble 2do Sector, 66450 Morelos, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL APODACA</td>
    <td>
      <a href="https://maps.app.goo.gl/ebsRooRjcB51VHnJA"
        target="_blank"
        rel="noopener noreferrer">Av. Miguel Alemán Lote Oriente 300, Col. Moderno, 66600 Cdad. Apodaca, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CD. VALLES</td>
    <td>
      <a href="https://maps.app.goo.gl/5PvMDKJZ5VBtxpu58"
        target="_blank"
        rel="noopener noreferrer">Blvd. Mexico - Laredo 530, Zona Centro, 79000 Cdad. Valles, S.L.P.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CENTRIKA</td>
    <td>
      <a href="https://maps.app.goo.gl/EqNzRF1n2yhtCCXs5"
        target="_blank"
        rel="noopener noreferrer">Av. Vicente Guerrero. 2500, Locales S41 Al S46, Céntrika Victoria, 64520 Monterrey, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CENTRO</td>
    <td>
      <a href="https://maps.app.goo.gl/CkN3tAB7W6TJy2Vc8"
        target="_blank"
        rel="noopener noreferrer">Av. Benito Juárez 800, Centro, 64000 Monterrey, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CITADEL</td>
    <td>
      <a href="https://maps.app.goo.gl/Gqab449PH1i2oCndA"
        target="_blank"
        rel="noopener noreferrer">Av. Rómulo Garza 410-Subancla 04, La Fe, 66477 San Nicolás de los Garza, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL COUNTRY</td>
    <td>
      <a href="https://maps.app.goo.gl/HbWRbLwNtz6YySdU6"
        target="_blank"
        rel="noopener noreferrer">Av. Eugenio Garza Sada 3551-Local B1, Plaza Country, 64860 Monterrey, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CUMBRES</td>
    <td>
      <a href="https://maps.app.goo.gl/LyjyzTe23j6rxBTs9"
        target="_blank"
        rel="noopener noreferrer">Av Hacienda de Peñuelas 6769-Local S, Cumbres Las Palmas Residencial, 64340 Monterrey, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL EMPRESARIAL</td>
    <td>
      <a href="https://maps.app.goo.gl/EX4N4YqaLzYyg8sv9"
        target="_blank"
        rel="noopener noreferrer">Calz San Pedro 212 Sur, Col. del Valle, Del Valle, 66220 San Pedro Garza García, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ESCOBEDO</td>
    <td>
      <a href="https://maps.app.goo.gl/B1A3sfFxgKVcKhwX6"
        target="_blank"
        rel="noopener noreferrer">Av. Raúl Salinas Lozano 3300-H-5, Los Girasoles 1er Sector, 66050 Cdad. Gral. Escobedo, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL EXPOSICIÓN</td>
    <td>
      <a href="https://maps.app.goo.gl/J5TH7HXGit41o86Z6"
        target="_blank"
        rel="noopener noreferrer">PAD-1-S, Benito Juárez 1851, El Sabino, 67154 Guadalupe, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL EXPRESS NUEVO SUR</td>
    <td>
      <a href="https://maps.app.goo.gl/7HhSPSxjihTha5Fx6"
        target="_blank"
        rel="noopener noreferrer">Av. Revolución.2703 Dentro De Plaza Nuevo Sur Local S-65, Ladrillera, 64830 Monterrey, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GALERÍAS</td>
    <td>
      <a href="https://maps.app.goo.gl/hzxzdVEbgVxPD3j9A"
        target="_blank"
        rel="noopener noreferrer">Av Insurgentes 2500, Vista Hermosa, 64620 Monterrey, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GUADALUPE</td>
    <td>
      <a href="https://maps.app.goo.gl/Tq6sQVUBRqQv5ikW6"
        target="_blank"
        rel="noopener noreferrer">Av Maestro Israel Cavazos Garza 7460, Rincón de Guadalupe, 67193 Guadalupe, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LAREDO I</td>
    <td>
      <a href="https://maps.app.goo.gl/G5PkHTDSzbhWKb6X6"
        target="_blank"
        rel="noopener noreferrer">Héroe De Nacataz. 2335, Esq. Lerdo De Tejada, Ojo Caliente Fracc, 88000 Nuevo Laredo, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LAREDO II</td>
    <td>
      <a href="https://maps.app.goo.gl/hH16gwUpczNE6fAU6"
        target="_blank"
        rel="noopener noreferrer">Av. Reforma 5601-Local 84 A 87, Las Alamedas, 88275 Nuevo Laredo, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LAS AMÉRICAS</td>
    <td>
      <a href="https://maps.app.goo.gl/zsenqRMzJV7D46Rz9"
        target="_blank"
        rel="noopener noreferrer">Av. Félix Galván 800, Hacienda los Morales 2do Sector, 66495 San Nicolás de los Garza, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MANTE</td>
    <td>
      <a href="https://maps.app.goo.gl/rXbAe7CoFWHLtvSK6"
        target="_blank"
        rel="noopener noreferrer">Prolongación Vicente Guerrero #900 Int. Sub Ancla B, V. Guerrero, 89800 Cdad. Mante, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MATAMOROS</td>
    <td>
      <a href="https://maps.app.goo.gl/4NrGTaHMdVFmSP6i9"
        target="_blank"
        rel="noopener noreferrer">Calle Sexta, Esq. Mina 11, Zona Centro, 87300 Heroica Matamoros, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MATAMOROS II</td>
    <td>
      <a href="https://maps.app.goo.gl/V46K19yoirUzk8HKA"
        target="_blank"
        rel="noopener noreferrer">Av Pedro Cárdenas 1901, Victoria, 87390 Heroica Matamoros, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MONCLOVA</td>
    <td>
      <a href="https://maps.app.goo.gl/8wB3L9uPb2yY8uFz9"
        target="_blank"
        rel="noopener noreferrer">Blvd Harold R. Pape 746, Guadalupe, 25250 Monclova, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MONCLOVA II</td>
    <td>
      <a href="https://maps.app.goo.gl/pPehZoECEaKXYCCp8"
        target="_blank"
        rel="noopener noreferrer">Blvd. San Buenaventura, 10 de Mayo 105, 25668 Monclova, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MTY SUN MALL VIP</td>
    <td>
      <a href="https://maps.app.goo.gl/zdo7yGAaDzLMusWm9"
        target="_blank"
        rel="noopener noreferrer">Carr. A Reynosa No.1800, Paseo Del Prado, 67275 Cdad. Benito Juárez, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PIEDRAS NEGRAS</td>
    <td>
      <a href="https://maps.app.goo.gl/JquwayFQcRD3bxf56"
        target="_blank"
        rel="noopener noreferrer">Av. Emilio Carranza 1021-Local 115, Juárez Ampliación, Burócrata, 26070 Piedras Negras, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PLAZA BELLA</td>
    <td>
      <a href="https://maps.app.goo.gl/nvKSREwRPpRqoiDJ8"
        target="_blank"
        rel="noopener noreferrer">Av. Sendero Divisorio 1001, Privadas de Anáhuac, Cerrada de Anáhuac Residencial, 66059 Cdad. Gral. Escobedo, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL REYNOSA I</td>
    <td>
      <a href="https://maps.app.goo.gl/ukhZjitm1mdyWyfM7"
        target="_blank"
        rel="noopener noreferrer">C. Pedro J. Méndez 150, 88500 Reynosa, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL REYNOSA II</td>
    <td>
      <a href="https://maps.app.goo.gl/nbzgc8LDTdW2cxtL7"
        target="_blank"
        rel="noopener noreferrer">Real Diamante, Blvd. Miguel Hidalgo 101, Las Fuentes, 88710 Reynosa, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL REYNOSA III</td>
    <td>
      <a href="https://maps.app.goo.gl/RKjSmkj1jMxcXoQp7"
        target="_blank"
        rel="noopener noreferrer">Autop. Monterrey- Reynosa 1000, Lomas del Real de Jarachina Sur, 88730 Reynosa, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL REYNOSA IV</td>
    <td>
      <a href="https://maps.app.goo.gl/bhtgaZBirF2zpmEC9"
        target="_blank"
        rel="noopener noreferrer">Carretera Matamoros Km 83-Local A-01/A, El Bienestar, 88788 Reynosa, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SALTILLO I</td>
    <td>
      <a href="https://maps.app.goo.gl/4e4fLStM52aWmamd9"
        target="_blank"
        rel="noopener noreferrer">Blvrd. Nazario Ortiz Garza 2345-Local 321, Los Tanques, 25250 Saltillo, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SALTILLO II</td>
    <td>
      <a href="https://maps.app.goo.gl/UdT6nuuvyCxF2Tsq6"
        target="_blank"
        rel="noopener noreferrer">Av. Presidente Cárdenas 250-Pte, Zona Centro, 25000 Saltillo, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SALTILLO III</td>
    <td>
      <a href="https://maps.app.goo.gl/qZ6CRqcd5fXWxWTT8"
        target="_blank"
        rel="noopener noreferrer">Av. Periferico Lic, Luis Echeverría Álvarez 867, Lourdes, 25070 Saltillo, Coah.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN AGUSTÍN</td>
    <td>
      <a href="https://maps.app.goo.gl/tuwWPH8TvcrfoL34A"
        target="_blank"
        rel="noopener noreferrer">Av. Batallón de San Patricio 1000, Zona Loma Larga Oriente, 66260 San Pedro Garza García, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN PEDRO</td>
    <td>
      <a href="https://maps.app.goo.gl/V8WaM3HQ7YHJne1EA"
        target="_blank"
        rel="noopener noreferrer">Av. Humberto Lobo 520, Zona Los Callejones, 66220 San Pedro Garza García, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SANTA CATARINA</td>
    <td>
      <a href="https://maps.app.goo.gl/8U3UqaunoYwu3QPc8"
        target="_blank"
        rel="noopener noreferrer">Industriales del Pte. 1050, Sin Nombre de Col 3, 66376 Cdad. Santa Catarina, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SATELITE</td>
    <td>
      <a href="https://maps.app.goo.gl/7XuwksRTmeYGqwdx8"
        target="_blank"
        rel="noopener noreferrer">Av Eugenio Garza Sada 6115-Locales C-107 Al C-115, Villa Las Fuentes, 64890 Monterrey, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SEARS EXPRESS ESFERA MINI</td>
    <td>
      <a href="https://maps.app.goo.gl/wPCivNL1tFg8bcnJ7"
        target="_blank"
        rel="noopener noreferrer">Carr Nacional 268, Villas La Rioja, 64988 Monterrey, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SENDERO LINCOLN</td>
    <td>
      <a href="https://maps.app.goo.gl/FoC8ePrhvrcyYzvG7"
        target="_blank"
        rel="noopener noreferrer">Av. Abraham Lincoln. 4001, Subancla Interior E1, Local 1 Al 6, Av. Puerta de Hierro, 64346 Monterrey, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO ATENCIÓN MONTERREY AZTLAN</td>
    <td>
      <a href="https://maps.app.goo.gl/cs544k1mypPmbjf59"
        target="_blank"
        rel="noopener noreferrer">Av. Aztlán 6659-Local 4-9, Nueva Galicia, 64240 Monterrey, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TAMPICO I</td>
    <td>
      <a href="https://maps.app.goo.gl/evprwGZG5S64qyS19"
        target="_blank"
        rel="noopener noreferrer">Av. Ejército Mexicano 103-L-4, Esfuerzo Nacional, 89470 Cd Madero, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TAMPICO II</td>
    <td>
      <a href="https://maps.app.goo.gl/xDGX6Mq5XDZEwq659"
        target="_blank"
        rel="noopener noreferrer">Av. Miguel Hidalgo 7102, Laguna de la Herradura, 89219 Tampico, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TAMPICO III</td>
    <td>
      <a href="https://maps.app.goo.gl/pTiLv6yLQV4TZYDv6"
        target="_blank"
        rel="noopener noreferrer">Francisco I. Madero 104, Zona Centro, 88730 Tampico, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TAMPICO IV</td>
    <td>
      <a href="https://maps.app.goo.gl/pm9noPpPh3qrnpxy5"
        target="_blank"
        rel="noopener noreferrer">Carr. Tampico Mante 5508, Roma, 89350 Tampico, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VALLE ORIENTE</td>
    <td>
      <a href="https://maps.app.goo.gl/Bq3GK8Fhsxh8wye36"
        target="_blank"
        rel="noopener noreferrer">Av Lázaro Cárdenas 1000-Local P, Valle del Mirador, 64750 Monterrey, N.L.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VICTORIA</td>
    <td>
      <a href="https://maps.app.goo.gl/qz6c59gKVd5f56bb7"
        target="_blank"
        rel="noopener noreferrer">Calle Juan B. Tijerina 638, Centro, 87000 Cdad. Victoria, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VICTORIA II</td>
    <td>
      <a href="https://maps.app.goo.gl/Kg1A2bs3Hor6bA3m6"
        target="_blank"
        rel="noopener noreferrer">Calz Gral Luis Caballero 410, Zozaya, 87070 Cdad. Victoria, Tamps.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ANDARES</td>
    <td>
      <a href="https://maps.app.goo.gl/EXk2BuhzfFau9mFw9"
        target="_blank"
        rel="noopener noreferrer">Boulevard Puerta De Hierro 4965 Level, Puerta de Hierro, 45116 Zapopan, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL APATZINGAN</td>
    <td>
      <a href="https://maps.app.goo.gl/MqwdgMBEqHYzCjbv6"
        target="_blank"
        rel="noopener noreferrer">Centro Comercial Agricentro, 22 de Octubre 621, Los Limones, 60697 Apatzingán de la Constitución, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ÁVILA CAMACHO</td>
    <td>
      <a href="https://maps.app.goo.gl/CSuuGLFTDrgWmF7SA"
        target="_blank"
        rel="noopener noreferrer">Av. Manuel Ávila Camacho 1019, Miraflores, 44265 Guadalajara, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL BAHIA DE BANDERAS</td>
    <td>
      <a href="https://maps.app.goo.gl/aUNYNpEgtxHnegj97"
        target="_blank"
        rel="noopener noreferrer">Puerto Vallarta - Tepic 430, Valle Dorado, 63735 Mezcales, Nay.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CENTRO SUR</td>
    <td>
      <a href="https://maps.app.goo.gl/qWmQgcnk9zp6deT57"
        target="_blank"
        rel="noopener noreferrer">Anillo, Av. Perif. Pte. Manuel Gómez Morin 7835, Santa María Tequepexpan, 45601 San Pedro Tlaquepaque, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHAPALA</td>
    <td>
      <a href="https://maps.app.goo.gl/9rdkZtCJSQdMEddr5"
        target="_blank"
        rel="noopener noreferrer">Carretera Chapala-Jocotepec. 206, 45922 San Antonio Tlayacapan, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHAPULTEPEC</td>
    <td>
      <a href="https://maps.app.goo.gl/P3wStcxM96M29f149"
        target="_blank"
        rel="noopener noreferrer">Av. Chapultepec Sur 408, Col Americana, Moderna, 44190 Guadalajara, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CIUDAD GUZMAN</td>
    <td>
      <a href="https://maps.app.goo.gl/bKRWmpe82V2T5Ar96"
        target="_blank"
        rel="noopener noreferrer">Calz Madero y Carranza 569, Zapotlán, 49000 Zapotlán el Grande, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL COLIMA I SEVILLA</td>
    <td>
      <a href="https://maps.app.goo.gl/MH5g2QmXtfjS5FgaA"
        target="_blank"
        rel="noopener noreferrer">Felipe Sevilla del Río 700, Jardines de las Lomas, 28014 Colima, Col.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL FORUM TLAQUEPAQUE</td>
    <td>
      <a href="https://maps.app.goo.gl/wPXH5o3sxtWjNXd7A"
        target="_blank"
        rel="noopener noreferrer">Blvrd Gral Marcelino García Barragán 2207, Prados del Nilo, 44840 Guadalajara, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GALERIAS GUADALAJARA</td>
    <td>
      <a href="https://maps.app.goo.gl/ZXTD3xTDRvwwj48r8"
        target="_blank"
        rel="noopener noreferrer">Av Rafael Sanzio 150-Locale Q12, La Estancia, 45030 Zapopan, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL INDEPENDENCIA</td>
    <td>
      <a href="https://maps.app.goo.gl/rmESrQusjWu7ttud9"
        target="_blank"
        rel="noopener noreferrer">Calz. Independencia Norte, No. 3295, Locales Del 7 Al 18, Zona O, Ricardo Flores Magón, 44240 Guadalajara, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LA CIMA</td>
    <td>
      <a href="https://maps.app.goo.gl/NjTRWiJbPw6hyabn6"
        target="_blank"
        rel="noopener noreferrer">Av. Juan Gil Preciado 1600, La Cima, 45134 Zapopan, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LA GRAN PLAZA</td>
    <td>
      <a href="https://maps.app.goo.gl/SVU1WZ43h4hLQfqz8"
        target="_blank"
        rel="noopener noreferrer">Av. Ignacio L Vallarta 3959, Don Bosco Vallarta, 45049 Zapopan, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LA PIEDAD</td>
    <td>
      <a href="https://maps.app.goo.gl/e7D75jnub4dKmveH8"
        target="_blank"
        rel="noopener noreferrer">Av. Juan Pablo II 901, México, 59330 La Piedad de Cabadas, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LÁZARO CÁRDENAS</td>
    <td>
      <a href="https://maps.app.goo.gl/q5RY1GcKoKLNTfBQ8"
        target="_blank"
        rel="noopener noreferrer">Melchor Ocampo 17-Segundo Sector, Ejidal, 60950 Cdad. Lázaro Cárdenas, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LÓPEZ MATEOS</td>
    <td>
      <a href="https://maps.app.goo.gl/RL34CA8Etuu31YmJ6"
        target="_blank"
        rel="noopener noreferrer">Av. López Mateos Sur, Av Tizoc 2200 Esq, Cd del Sol, 45050 Zapopan, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MANZANILLO</td>
    <td>
      <a href="https://maps.app.goo.gl/Wi5QjFDc3UkR3Qqj8"
        target="_blank"
        rel="noopener noreferrer">Blvd. Miguel de la Madrid 1996, Soleares, Salagua, 28867 Manzanillo, Col.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MORELIA I</td>
    <td>
      <a href="https://maps.app.goo.gl/vtH4MQkSQA7jaroa9"
        target="_blank"
        rel="noopener noreferrer">Av. Enrique Ramirez, Gral. Miguel Blanco 1000, Las Américas, 58270 Morelia, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MORELIA IV</td>
    <td>
      <a href="https://maps.app.goo.gl/mwGcDFK8XX6n4j2N8"
        target="_blank"
        rel="noopener noreferrer">Perif. Paseo de la República 1101 Col, El Realito, 58116 Morelia, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MORELIA V</td>
    <td>
      <a href="https://maps.app.goo.gl/GRDLNqnoFXHQLZrf9"
        target="_blank"
        rel="noopener noreferrer">Av Francisco I. Madero Pte 577, Centro, 58000 Morelia, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL NIÑOS HEROES</td>
    <td>
      <a href="https://maps.app.goo.gl/VVzWovgQ9nFqM3xNA"
        target="_blank"
        rel="noopener noreferrer">Av Niños Héroes 3004, Jardines del Bosque, 44520 Guadalajara, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL OCOTLAN</td>
    <td>
      <a href="https://maps.app.goo.gl/PZLhHSGhboQUmt5t5"
        target="_blank"
        rel="noopener noreferrer">20 de Noviembre 351, El Nuevo Fuerte, 47899 Ocotlán, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PLAZA TAPATIA</td>
    <td>
      <a href="https://maps.app.goo.gl/adq5HZxU4z2eNkwz5"
        target="_blank"
        rel="noopener noreferrer">Calz. Independencia Sur 4-Local B, Col Americana, Centro, 44100 Guadalajara, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PROVIDENCIA</td>
    <td>
      <a href="https://maps.app.goo.gl/HJDpf7WD3ozxgDbx6"
        target="_blank"
        rel="noopener noreferrer">Cerca Glorieta Colon, Av. de las Américas 1488, Country Club, 44610 Guadalajara, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PUERTO VALLARTA</td>
    <td>
      <a href="https://maps.app.goo.gl/XPsBjTVbmgEaMduD6"
        target="_blank"
        rel="noopener noreferrer">Av. Francisco Medina Ascencio 1720, Olímpica, 48300 Puerto Vallarta, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PUERTO VALLARTA II GALERIAS</td>
    <td>
      <a href="https://maps.app.goo.gl/4tEzVEspt9RxvKYv8"
        target="_blank"
        rel="noopener noreferrer">Av. Francisco Medina Ascencio 2920, Puerto Vallarta, 48313 Puerto Vallarta, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL REGINA</td>
    <td>
      <a href="https://maps.app.goo.gl/9yep5B7FULL3p6g4A"
        target="_blank"
        rel="noopener noreferrer">Av. Adolf Bernard Horn Junior 3513, 45640 Real del Valle, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL REVOLUCION</td>
    <td>
      <a href="https://maps.app.goo.gl/HXK9YBLtV4eutL7cA"
        target="_blank"
        rel="noopener noreferrer">Calz. Revolución 1563, La Loma, 44410 Guadalajara, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL RIO NILO</td>
    <td>
      <a href="https://maps.app.goo.gl/Meep4qP6TYFPZswB8"
        target="_blank"
        rel="noopener noreferrer">Av Río Nilo 1000-Int. Sub Ancla D, Lomas del Nilo, 44825 Guadalajara, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL RUBEN DARIO</td>
    <td>
      <a href="https://maps.app.goo.gl/GgqJZanA6JLUFigR9"
        target="_blank"
        rel="noopener noreferrer">Av. Rubén Darío 750, Lomas de Guevara, 44657 Guadalajara, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN ISIDRO</td>
    <td>
      <a href="https://maps.app.goo.gl/LYN5pZu2U1CLWi6d9"
        target="_blank"
        rel="noopener noreferrer">Av. Camino a Bosque de San Isidro S/N, Bosques del Centinela, 45187 Zapopan, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN JUAN DE LOS LAGOS</td>
    <td>
      <a href="https://maps.app.goo.gl/BT1KDRXQwo9miwCy8"
        target="_blank"
        rel="noopener noreferrer">Calle. Moreno Valley No.176, Centro, 47000 San Juan de los Lagos, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SANTA ANITA</td>
    <td>
      <a href="https://maps.app.goo.gl/AyE6qC9NskWqB1oC6"
        target="_blank"
        rel="noopener noreferrer">Av. Adolfo López Mateos Sur 5550-B-L-38, Fraccionamiento La Rioja, 45645 Los Gavilanes, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TECOMAN</td>
    <td>
      <a href="https://maps.app.goo.gl/3sZrfWG5ZTKyF4mEA"
        target="_blank"
        rel="noopener noreferrer">Calle Emiliano Zapata 593, Centro, 28100 Tecomán, Col.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TEPATITLÁN</td>
    <td>
      <a href="https://maps.app.goo.gl/P1DgidAMSCQJZixD8"
        target="_blank"
        rel="noopener noreferrer">Esq, Blvd. Anacleto González Flores, C. Ganaderos S/N, Centro, 47698 Tepatitlán de Morelos, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TEPIC I INSURGENTES</td>
    <td>
      <a href="https://maps.app.goo.gl/kQh48P6QrkP8rkRFA"
        target="_blank"
        rel="noopener noreferrer">De Los Insurgentes Pte. 851, IMSS, 63000 Tepic, Nay.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TEPIC II</td>
    <td>
      <a href="https://maps.app.goo.gl/8qFXBLk5rGUsCscA6"
        target="_blank"
        rel="noopener noreferrer">Blvrd Luis Donaldo Colosio 680-Local 03, Benito Juárez, 63175 Tepic, Nay.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TERRAZA OBLATOS</td>
    <td>
      <a href="https://maps.app.goo.gl/uG2Ve1rQXmwcMzMZ9"
        target="_blank"
        rel="noopener noreferrer">Av. Circunvalación Oblatos 2700-Locales 107-109, Oblatos, 44716 Guadalajara, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TLAQUEPAQUE</td>
    <td>
      <a href="https://maps.app.goo.gl/SeGQXWgcEUPoQjum6"
        target="_blank"
        rel="noopener noreferrer">Av. Niños Héroes 429, Fracc. Quinta, El Alamo, 45560 San Pedro Tlaquepaque, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TONALA</td>
    <td>
      <a href="https://maps.app.goo.gl/UimL5R9nALKuYKfS7"
        target="_blank"
        rel="noopener noreferrer">Av. Periferico Oriente Tonaltecas Norte. 375, Col. Pachaguillo, 45400 Tonalá, Jal.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL URUAPAN</td>
    <td>
      <a href="https://maps.app.goo.gl/vttFQBjQktv2cibXA"
        target="_blank"
        rel="noopener noreferrer">Av. Latinoamericana 237, Huertas del Cupatitzio, 60080 Uruapan, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL URUAPAN II</td>
    <td>
      <a href="https://maps.app.goo.gl/biwRnykDKspHFFbs6"
        target="_blank"
        rel="noopener noreferrer">C. Calz. Benito Juárez 70-Int. 8, Lomas del Valle Sur, 60110 Uruapan, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ZAMORA</td>
    <td>
      <a href="https://maps.app.goo.gl/TapYbmq3Lhj9uZnq5"
        target="_blank"
        rel="noopener noreferrer">C. Pino Suarez 11-12,13,20,21 & 22, Los Laureles, 59674 Zamora de Hidalgo, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ZAMORA SENTURA</td>
    <td>
      <a href="https://maps.app.goo.gl/KHvExwseAvmwGYS7A"
        target="_blank"
        rel="noopener noreferrer">Carretera Zamora-La Barca. 4300 Localidad Atecucario, 59720 Zamora de Hidalgo, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ZENTRALIA</td>
    <td>
      <a href="https://maps.app.goo.gl/QhiJU4XeWaHoD68c6"
        target="_blank"
        rel="noopener noreferrer">3Er Anillo Periferico, P.º Valle Dorado 301-Locales 117, 118 Y 119, 28018 Colima, Col.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ZITÁCUARO</td>
    <td>
      <a href="https://maps.app.goo.gl/iLJfpk6adv9PWsvt9"
        target="_blank"
        rel="noopener noreferrer">Av. Revolución Sur 363, El Moral, 61514 Zitácuaro, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL AGUASCALIENTES I</td>
    <td>
      <a href="https://maps.app.goo.gl/fHmuhcCMvcpbwRgp6"
        target="_blank"
        rel="noopener noreferrer">Av Independencia 2351, Trojes de Alonso, 20110 Aguascalientes, Ags.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL AGUASCALIENTES II</td>
    <td>
      <a href="https://maps.app.goo.gl/suRfaPfW1gBrJWJ9A"
        target="_blank"
        rel="noopener noreferrer">Pról, Av. Las Américas 1701, Valle Dorado, 20230 Aguascalientes, Ags.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL AGUASCALIENTES III</td>
    <td>
      <a href="https://maps.app.goo.gl/A8qjBr85pvmneugB7"
        target="_blank"
        rel="noopener noreferrer">Av. De La Convención De 1914. 702 Coronel, Gómez Portugal, 20250 Aguascalientes, Ags.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL AGUASCALIENTES IV</td>
    <td>
      <a href="https://maps.app.goo.gl/FEt9FaxUozEXYBCu7"
        target="_blank"
        rel="noopener noreferrer">Moctezuma 101, Centro, 20000 Aguascalientes, Ags.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL AGUASCALIENTES V</td>
    <td>
      <a href="https://maps.app.goo.gl/8UR55SCuREFu3wqV7"
        target="_blank"
        rel="noopener noreferrer">Av Canal Interceptor 415, Villas de la Universidad, 20029 Aguascalientes, Ags.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL AGUASCALIENTES VI</td>
    <td>
      <a href="https://maps.app.goo.gl/88Yd6yqCodFtKCeo7"
        target="_blank"
        rel="noopener noreferrer">Av. Siglo XXI 5047-A, El Riego, 20199 Aguascalientes, Ags.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CAE LEÓN</td>
    <td>
      <a href="https://maps.app.goo.gl/N9vnjnnYGEUMDdPQ7"
        target="_blank"
        rel="noopener noreferrer">Av Paseo del Moral 129, Jardines del Moral, 37160 León de los Aldama, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CELAYA I</td>
    <td>
      <a href="https://maps.app.goo.gl/R5AfF2h6N17awpyy6"
        target="_blank"
        rel="noopener noreferrer">AVE. TECNOLÓGICO, Av. México Japón 101 ESQ, Ciudadela, 38017 Celaya, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CELAYA II</td>
    <td>
      <a href="https://maps.app.goo.gl/ZajoQzutJLGNLXv79"
        target="_blank"
        rel="noopener noreferrer">Blvrd Adolfo López Mateos 1016 Poniente, Arboledas 1A Sección, 38060 Celaya, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CELAYA III</td>
    <td>
      <a href="https://maps.app.goo.gl/TmyW3V2BgG51puzh8"
        target="_blank"
        rel="noopener noreferrer">Lib. Nororiente 200, Compuertas De Campestre, 38080 Celaya, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL FRESNILLO</td>
    <td>
      <a href="https://maps.app.goo.gl/W4uwqWCVyLUYsvn48"
        target="_blank"
        rel="noopener noreferrer">P.º del Mineral 804, Venustiano Carranza, 99099 Fresnillo, Zac.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GALERÍAS METROPILITANA MOROLEON URIANGATO</td>
    <td>
      <a href="https://maps.app.goo.gl/1rJveFpJhJ1tTXM17"
        target="_blank"
        rel="noopener noreferrer">Blvd. Uriangato 240-Subancla 03, La Joyita, 38983 Uriangato, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GUANAJUATO</td>
    <td>
      <a href="https://maps.app.goo.gl/17qXDJqLJy2c5eVE6"
        target="_blank"
        rel="noopener noreferrer">Av. Benito Juárez 87, Zona Centro, 36000 Guanajuato, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GUANAJUATO II EL LAUREL</td>
    <td>
      <a href="https://maps.app.goo.gl/p4bRce5T8qbLzrzg6"
        target="_blank"
        rel="noopener noreferrer">Carr. Libre Guanajuato - Silao Km 5, 36250 Marfil, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL IRAPUATO I</td>
    <td>
      <a href="https://maps.app.goo.gl/DSkGdUsvQrMwhTMx9"
        target="_blank"
        rel="noopener noreferrer">Blvd. Lázaro Cárdenas 1394, Residencial Campestre, 36698 Irapuato, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL IRAPUATO II</td>
    <td>
      <a href="https://maps.app.goo.gl/ajegFzyZxxRHXwUY8"
        target="_blank"
        rel="noopener noreferrer">Las Villas 1443, Quinta las Villas, 36643 Irapuato, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LEÓN I</td>
    <td>
      <a href="https://maps.app.goo.gl/qh9J95QFuM6Y84Xs7"
        target="_blank"
        rel="noopener noreferrer">Álvaro Obregón 113-115, Centro, 37000 León de los Aldama, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LEÓN II</td>
    <td>
      <a href="https://maps.app.goo.gl/RHLonY5F38fyCyXr5"
        target="_blank"
        rel="noopener noreferrer">Blvd. Adolfo López Mateos 1213, El Coecillo, 37260 León de los Aldama, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LEÓN III</td>
    <td>
      <a href="https://maps.app.goo.gl/WUpYy6F8uViX1Wqu5"
        target="_blank"
        rel="noopener noreferrer">Plaza Comercial Centro Max, Blvd. Adolfo López Mateos 2518 Ote, Jardines de Jerez, 37530 León de los Aldama, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LEÓN IV</td>
    <td>
      <a href="https://maps.app.goo.gl/sg1GsNR3ac5xtbKA9"
        target="_blank"
        rel="noopener noreferrer">C. Primera de Mayo 207, La Piscina CTM, 37440 León de los Aldama, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LEÓN V</td>
    <td>
      <a href="https://maps.app.goo.gl/ViSfBrokJdk8AG85A"
        target="_blank"
        rel="noopener noreferrer">Blvd. Juan Alonso de Torres 2002, Valle del Campestre, 37150 León de los Aldama, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LEÓN VI</td>
    <td>
      <a href="https://maps.app.goo.gl/bYgJRRJhN6jvai2r8"
        target="_blank"
        rel="noopener noreferrer">Blvd. Juan Alonso de Torres Pte. 1315, Unión Comunitaria de Leon, 37200 León de los Aldama, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL QUERÉTARO I</td>
    <td>
      <a href="https://maps.app.goo.gl/EJ2QvXb193sJ6xAfA"
        target="_blank"
        rel="noopener noreferrer">Fernando de Tapia 72, Centro Histórico, Centro, 76000 Santiago de Querétaro, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL QUERÉRATO II</td>
    <td>
      <a href="https://maps.app.goo.gl/Cd9zsnT2yGRYvc8y5"
        target="_blank"
        rel="noopener noreferrer">Av. Corregidora Nte. 306, Plaza Boulevares, 76160 Santiago de Querétaro, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL QUERÉTARO III</td>
    <td>
      <a href="https://maps.app.goo.gl/PjNQRVMfFmBhwh6R7"
        target="_blank"
        rel="noopener noreferrer">Blvd. Hacienda del Jacal 220, Jardines de la Hacienda, 76180 Santiago de Querétaro, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL QUERÉTARO IV</td>
    <td>
      <a href="https://maps.app.goo.gl/e7YBBcV44jw3TfGR7"
        target="_blank"
        rel="noopener noreferrer">Epigmenio González 2, Conjunto Parques, 76158 Santiago de Querétaro, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL QUERÉTARO IX</td>
    <td>
      <a href="https://maps.app.goo.gl/u1sJz3LiUbZRu9K99"
        target="_blank"
        rel="noopener noreferrer">Blvd. de las Américas 117, Colinas del Sur, 76927 Corregidora, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL QUERÉTARO V</td>
    <td>
      <a href="https://maps.app.goo.gl/dtdrGsiPjP5yPyxk6"
        target="_blank"
        rel="noopener noreferrer">Calle Benito Juárez Nte. 4, Centro, 76000 Santiago de Querétaro, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL QUERÉTARO VI</td>
    <td>
      <a href="https://maps.app.goo.gl/81CNeFsSKxqtS62Q8"
        target="_blank"
        rel="noopener noreferrer">Blvd. Juriquilla 3100, Jurica Acueducto, 76230 Santiago de Querétaro, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL QUERÉTARO VII</td>
    <td>
      <a href="https://maps.app.goo.gl/2NAMWutqJu1Vp5A7A"
        target="_blank"
        rel="noopener noreferrer">Av. Revolución 81, El Sol, 76113 Santiago de Querétaro, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL QUERÉTARO X</td>
    <td>
      <a href="https://maps.app.goo.gl/SHgMbcbvT5D9xnn47"
        target="_blank"
        rel="noopener noreferrer">San Luis Potosí - Santiago De Querétaro 12401 El Salitre, 76127 Santiago de Querétaro, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL RÍO VERDE</td>
    <td>
      <a href="https://maps.app.goo.gl/Po96rRZGZmnZ7SU48"
        target="_blank"
        rel="noopener noreferrer">Carlos Jonguitud Barrios 110, Zona Centro, 79610 San Luis Potosí, S.L.P.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SALAMANCA</td>
    <td>
      <a href="https://maps.app.goo.gl/FkjrJ26bdFPrLiPE9"
        target="_blank"
        rel="noopener noreferrer">Blvd. Faja de Oro 1502-Ancla 1, Guadalupe, 36740 Salamanca, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN JUAN DEL RÍO</td>
    <td>
      <a href="https://maps.app.goo.gl/tAjnDxT5ztv8pRgb9"
        target="_blank"
        rel="noopener noreferrer">C. Miguel Hidalgo 62-Loc. B, Centro, 76800 San Juan del Río, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN JUAN DEL RÍO II</td>
    <td>
      <a href="https://maps.app.goo.gl/hMNFft7Mpx7K2Wea7"
        target="_blank"
        rel="noopener noreferrer">Paseo Central 18, Zona Ind, Valle de Oro, 76803 San Juan del Río, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN LUIS POTOSÍ I</td>
    <td>
      <a href="https://maps.app.goo.gl/vgCwfTjARRLbCwrP6"
        target="_blank"
        rel="noopener noreferrer">Jesús Goytortua, C. Alberto Cossio 230 Esq, Tangamanga, 78269 San Luis Potosí, S.L.P.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN LUIS POTOSÍ III</td>
    <td>
      <a href="https://maps.app.goo.gl/3h31pKcbugeX71om6"
        target="_blank"
        rel="noopener noreferrer">Av. Acceso Nte. 451, Industrial Mexicana, 78309 Soledad de Graciano Sánchez, S.L.P.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN LUIS POTOSÍ IV</td>
    <td>
      <a href="https://maps.app.goo.gl/McgFuQ8fUA4y3da38"
        target="_blank"
        rel="noopener noreferrer">Jdn. Hidalgo 6, Centro, 78000 San Luis Potosí, S.L.P.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN LUIS POTOSÍ V</td>
    <td>
      <a href="https://maps.app.goo.gl/66Djr6NEQvUADrbJ7"
        target="_blank"
        rel="noopener noreferrer">Av. Venustiano Carranza 815-1, Centro, 78000 San Luis Potosí, S.L.P.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN LUIS POTOSÍ VI</td>
    <td>
      <a href="https://maps.app.goo.gl/wgnxAkTrdYkZeBWo8"
        target="_blank"
        rel="noopener noreferrer">Blvd. Antonio Rocha Cordero 700 Loc. D-03 y D-04, Lomas del Tecnologico, 78215 San Luis Potosí</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN LUIS POTOSÍ VII</td>
    <td>
      <a href="https://maps.app.goo.gl/pqj8f9KeRH3UBVkK9"
        target="_blank"
        rel="noopener noreferrer">Av Nereo Rodríguez Barragán 450, Col del Valle, 78200 San Luis Potosí, S.L.P.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN MIGUEL ALLENDE</td>
    <td>
      <a href="https://maps.app.goo.gl/7ks3ofc2j22Ym2nt6"
        target="_blank"
        rel="noopener noreferrer">Lib. José Manuel Zavala Km. 2.2, Fracc. La Lejona, 37765 San Miguel de Allende, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LEÓN</td>
    <td>
      <a href="https://maps.app.goo.gl/1TvpcFR72XTMUDxo7"
        target="_blank"
        rel="noopener noreferrer">Blvd. Juan Alonso de Torres Pte. 101, Zona Centro Comercial, 37148 León de los Aldama, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL IRAPUATO III</td>
    <td>
      <a href="https://maps.app.goo.gl/y1jPkPRutUNUHn879"
        target="_blank"
        rel="noopener noreferrer">Enrique Colunga 1184, 18 de Agosto, 36590 Irapuato, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL QUERETARO VIII</td>
    <td>
      <a href="https://maps.app.goo.gl/Q3eAwiGNrhBpJBn88"
        target="_blank"
        rel="noopener noreferrer">Av. Constituyentes 123 Y 125, Carretas, 76050 Santiago de Querétaro, Qro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SILAO</td>
    <td>
      <a href="https://maps.app.goo.gl/BPwUWLdrT181YSz78"
        target="_blank"
        rel="noopener noreferrer">Boulevard. H Ducoing 144, Centro, 36100 Silao de la Victoria, Gto.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ZACATECAS</td>
    <td>
      <a href="https://maps.app.goo.gl/WJmUJ6vH5pDKe93U7"
        target="_blank"
        rel="noopener noreferrer">José López Portillo 4F, Indeco, 98610 Guadalupe, Zac.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ZACATECAS II</td>
    <td>
      <a href="https://maps.app.goo.gl/JKKvwcRtiVz94Hdn7"
        target="_blank"
        rel="noopener noreferrer">La Encantada 308, 5 Señores, 98089 Zacatecas, Zac.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ZACATECAS III</td>
    <td>
      <a href="https://maps.app.goo.gl/eRxV35jHLhGtrTzD9"
        target="_blank"
        rel="noopener noreferrer">Blvd. El Bote 202, Argentum, 98040 Guadalupe, Zac.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ACAPULCO CUAUHTÉMOC</td>
    <td>
      <a href="https://maps.app.goo.gl/7TgCuk7WaXoSAks16"
        target="_blank"
        rel="noopener noreferrer">Av Cuauhtémoc 612, Dominguillo, 39300 Acapulco de Juárez, Gro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ACAPULCO ICACOS</td>
    <td>
      <a href="https://maps.app.goo.gl/Rqia26gUtEEHgpon8"
        target="_blank"
        rel="noopener noreferrer">Plaza Icacos, Loc. 13-15, Icacos, 39860 Acapulco de Juárez, Gro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ACAPULCO PLAZA</td>
    <td>
      <a href="https://maps.app.goo.gl/mpSfY3bFSBSdhum77"
        target="_blank"
        rel="noopener noreferrer">Av Costera Miguel Alemán 1632, Fracc Magallanes, Magallanes, 39670 Acapulco de Juárez, Gro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ACAPULCO RENACIMIENTO</td>
    <td>
      <a href="https://maps.app.goo.gl/2NTXiScmuVEMjreC7"
        target="_blank"
        rel="noopener noreferrer">Blvd. Vicente Guerrero 1005, Cd Renacimiento, 39715 Acapulco de Juárez, Gro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ACAPULCO V DIAMANTE</td>
    <td>
      <a href="https://maps.app.goo.gl/PNkXs5jsPPppD6mk6"
        target="_blank"
        rel="noopener noreferrer">Multi Plaza, Blvd. de las Naciones, Las Palmas 402-Loc. 15, Granjas del Marqués, 39890 Acapulco de Juárez, Gro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL APIZACO</td>
    <td>
      <a href="https://maps.app.goo.gl/F4xWKnoddSfn989QA"
        target="_blank"
        rel="noopener noreferrer">Avenida 16 De Septiembre No. 103 A, Entre Av. Cuauhtemoc Y Av. Xicotencatl, Centro, 90300 Cdad. de Apizaco, Tlax.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CENTRO SUR</td>
    <td>
      <a href="https://maps.app.goo.gl/DFd9X9E3Ntg6NVRX9"
        target="_blank"
        rel="noopener noreferrer">C. 11 Sur 11904, INFONAVIT Agua Santa, 72490 Heroica Puebla de Zaragoza, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHILPANCINGO</td>
    <td>
      <a href="https://maps.app.goo.gl/a9etSjkGxpuWZpjE6"
        target="_blank"
        rel="noopener noreferrer">Prosperidad Manzana 3 Lote 10, Universal, 39080 Chilpancingo de los Bravo, Gro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHILPANCINGO II</td>
    <td>
      <a href="https://maps.app.goo.gl/Jm2opBujz55vkMWi6"
        target="_blank"
        rel="noopener noreferrer">Lic. René Juárez Cisneros 130, Fraccionamiento Villas Vicente Guerrero, Tepango, 39010 Chilpancingo de los Bravo, Gro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHOLULA</td>
    <td>
      <a href="https://maps.app.goo.gl/7yVywWR77NZ4MW2RA"
        target="_blank"
        rel="noopener noreferrer">Av. 5 de Mayo 1427, Centro, 72810 San Andrés Cholula, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL COATZACOALCOS</td>
    <td>
      <a href="https://maps.app.goo.gl/HCfB7KRWATBP7i4Y7"
        target="_blank"
        rel="noopener noreferrer">Av. Ignacio Zaragoza 905, Centro, 96400 Coatzacoalcos, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CÓRDOBA</td>
    <td>
      <a href="https://maps.app.goo.gl/o48r1VQ3XhZeejN6A"
        target="_blank"
        rel="noopener noreferrer">Av 1 2208, San Jose, 94500 Córdoba, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL EL CRISTO</td>
    <td>
      <a href="https://maps.app.goo.gl/tcHRMzEKavjvC3p66"
        target="_blank"
        rel="noopener noreferrer">Boulevard Cadete Vicente Suárez. 1011 A, Esq, Av 14 Ote, El Cristo, 72340 Heroica Puebla de Zaragoza, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GALERÍAS SERDÁN PUEBLA</td>
    <td>
      <a href="https://maps.app.goo.gl/nEAm1FLQKkJChami8"
        target="_blank"
        rel="noopener noreferrer">Blvd. Hermanos Serdán 270-Local 307, Rancho Colorado, 72062 Heroica Puebla de Zaragoza, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL HUAJAPAN DE LEÓN</td>
    <td>
      <a href="https://maps.app.goo.gl/jxHDdmUt2B1JnvSdA"
        target="_blank"
        rel="noopener noreferrer">C. Porfirio Díaz 16, Centro, 69000 Heroica Cdad. de Huajuapan de León, Oax.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL HUEXOTITLA</td>
    <td>
      <a href="https://maps.app.goo.gl/YUnSSe2xHcsUpKNf9"
        target="_blank"
        rel="noopener noreferrer">Av. 43 Ote. 11, Huexotitla, 72534 Heroica Puebla de Zaragoza, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL IGUALA</td>
    <td>
      <a href="https://maps.app.goo.gl/JTXT9D97P6wNCM6CA"
        target="_blank"
        rel="noopener noreferrer">Av. Periférico Oriente, Heroico Colegio Militar S/N, Centro, 40000 Iguala de la Independencia, Gro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL IXTAPA</td>
    <td>
      <a href="https://maps.app.goo.gl/55sm97jgib83xVmt7"
        target="_blank"
        rel="noopener noreferrer">Blvd. Paseo Ixtapa Manzana 1 Lote 1-B2, 40884 Ixtapa Zihuatanejo, Gro.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MINATITLÁN</td>
    <td>
      <a href="https://maps.app.goo.gl/vKKiiiT246aHh3MK8"
        target="_blank"
        rel="noopener noreferrer">Av Justo Sierra 75, Santa Clara, 96730 Minatitlán, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL OAXACA II</td>
    <td>
      <a href="https://maps.app.goo.gl/sbxAe7PPSUc7qicJ8"
        target="_blank"
        rel="noopener noreferrer">Avenida Universidad 200, Universidad, Exhacienda Candiani, 71233 Oaxaca de Juárez, Oax.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL OAXACA III</td>
    <td>
      <a href="https://maps.app.goo.gl/q1HtX7ASEeQFvj348"
        target="_blank"
        rel="noopener noreferrer">Carretera Internacional, Carr. Cristobal Colón 400, Cuauhtemoc, 68030 Oaxaca de Juárez, Oax.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL OAXACA IV</td>
    <td>
      <a href="https://maps.app.goo.gl/hBfD18KALdLqdmhSA"
        target="_blank"
        rel="noopener noreferrer">Carr. Internacional 1800, Centro, 68104 Oaxaca de Juárez, Oax.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL OAXACA V</td>
    <td>
      <a href="https://maps.app.goo.gl/5cABAHndAmThf8hr8"
        target="_blank"
        rel="noopener noreferrer">Las Casas 806, Zona Lunes Feb 09, Centro, 68000 Oaxaca de Juárez, Oax.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ORIZABA</td>
    <td>
      <a href="https://maps.app.goo.gl/SnTC72ETUjf4zBv59"
        target="_blank"
        rel="noopener noreferrer">Oriente 6, Sur 5 211 Esq, Centro, 94300 Orizaba, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL POZA RICA</td>
    <td>
      <a href="https://maps.app.goo.gl/KNFkRUaaktdUcG5Y6"
        target="_blank"
        rel="noopener noreferrer">Blvd. Adolfo Ruíz Cortines 1728, Manuel Avila Camacho, 93320 Poza Rica de Hidalgo, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL POZA RICA II</td>
    <td>
      <a href="https://maps.app.goo.gl/g9rNXU3CErvJoa9VA"
        target="_blank"
        rel="noopener noreferrer">Blvd. Lázaro Cárdenas 1101- A, Tajin, 93330 Poza Rica de Hidalgo, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PUEBLA CENTRO MAYOR</td>
    <td>
      <a href="https://maps.app.goo.gl/eXF6h9LKAgFed8By7"
        target="_blank"
        rel="noopener noreferrer">Calz Zavaleta 110, Santa Cruz Buenavista Norte, Sta Cruz Buenavista, 72150 Heroica Puebla de Zaragoza, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SALINA CRUZ</td>
    <td>
      <a href="https://maps.app.goo.gl/ugYs6zvU7Fji1HYQ9"
        target="_blank"
        rel="noopener noreferrer">Carretera Federal Transísmica. 10 Fs 2 Granadillo, 70613 Salina Cruz, Oax.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN MARTÍN TEXMELUCAN</td>
    <td>
      <a href="https://maps.app.goo.gl/xXQHKhX5zsaSG9RV9"
        target="_blank"
        rel="noopener noreferrer">Mujica 6, Col Centro, 74000 San Martín Texmelucan de Labastida, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN PEDRO</td>
    <td>
      <a href="https://maps.app.goo.gl/E8h1zGGs239wQ5DR8"
        target="_blank"
        rel="noopener noreferrer">Blvrd Nte 1833, Villas San Alejandro, 72090 Heroica Puebla de Zaragoza, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL PUEBLA ZOCALO</td>
    <td>
      <a href="https://maps.app.goo.gl/HbwQPp1SMQqeYoAv5"
        target="_blank"
        rel="noopener noreferrer">16 De Septiembre # 101 Col. Centro, Lote Zocalo, Portales Pct, 72000 Heroica Puebla de Zaragoza, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO Y CENTRO DE ATENCIÓN NUEVO VERACRUZ</td>
    <td>
      <a href="https://maps.app.goo.gl/2vqdfmvNVt1aF8Pz6"
        target="_blank"
        rel="noopener noreferrer">Carretera México-Xalapa Km.435.3 S/N, El Laureal De Buenavista, 91697 Veracruz, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO Y CENTRO DE ATENCIÓN VERACRUZ CENTRO</td>
    <td>
      <a href="https://maps.app.goo.gl/NvmJBfHMTAUGihQD9"
        target="_blank"
        rel="noopener noreferrer">C. Benito Juárez 222, Centro, 91700 Veracruz, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SONATA</td>
    <td>
      <a href="https://maps.app.goo.gl/7Eu5WEUnHfvrbqin8"
        target="_blank"
        rel="noopener noreferrer">P.º Opera 2, Lomas de Angelópolis, 72830 San Bernardino Tlaxcalancingo, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TEHUACÁN</td>
    <td>
      <a href="https://maps.app.goo.gl/o86KvMMiidTx7s6H9"
        target="_blank"
        rel="noopener noreferrer">Calz. Adolfo López Mateos 3800-Local 58, 59, 59A y 80, Exhacienda San Lorenzo, 75720 Tehuacán, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TLAXCALA</td>
    <td>
      <a href="https://maps.app.goo.gl/1hSgJSXzonwCBs7q6"
        target="_blank"
        rel="noopener noreferrer">Carr. Sta. Ana - Tlaxcala 194, Industrial, 90800 Santa Ana Chiautempan, Tlax.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TUXPAN</td>
    <td>
      <a href="https://maps.app.goo.gl/2Th6mCtDiyHNMQA97"
        target="_blank"
        rel="noopener noreferrer">Blvd. Demetrio Ruiz Malerva 285, Zapote Gordo, 92860 Túxpam de Rodríguez Cano, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VERACRUZ ANDAMAR</td>
    <td>
      <a href="https://maps.app.goo.gl/XkZcCpRk2c2i4ZHk9"
        target="_blank"
        rel="noopener noreferrer">Blvd. Adolfo Ruíz Cortines 4300, Zona Hotelera, 94298 Poza Rica de Hidalgo, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VERACRUZ EL DORADO</td>
    <td>
      <a href="https://maps.app.goo.gl/7LiWi5TR3GVbeZ9G8"
        target="_blank"
        rel="noopener noreferrer">Carretera, Antón Lizardo 4405, El Novillero, 94286 Boca del Río, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VERACRUZ I (BOCA DEL RÍO)</td>
    <td>
      <a href="https://maps.app.goo.gl/CTKtaP8yPPUr7GJ96"
        target="_blank"
        rel="noopener noreferrer">Bv. Adolfo Ruíz Cortines 2521, Heriberto Kehoe Vicent, 94299 Boca del Río, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VERACRUZ III (PINOS)</td>
    <td>
      <a href="https://maps.app.goo.gl/4MSmhxRwHZuqUaf57"
        target="_blank"
        rel="noopener noreferrer">Av. Miguel Angel de Quevedo 2101, Pinos, 91870 Veracruz, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VERACRUZ IV (DÍAZ MIRÓN)</td>
    <td>
      <a href="https://maps.app.goo.gl/kaXEbZoTGPSpGMTAA"
        target="_blank"
        rel="noopener noreferrer">Av. Díaz Mirón 2125, Col. Centro, Centro, 91700 Veracruz, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VÍA SAN ANGEL</td>
    <td>
      <a href="https://maps.app.goo.gl/3vB9PfVYUzr54axp6"
        target="_blank"
        rel="noopener noreferrer">Atlixcáyotl 1504, Reserva Territorial Atlixcáyotl, Desarrollo Atlixcayotl, 72197 San Andrés Cholula, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL XALAPA I</td>
    <td>
      <a href="https://maps.app.goo.gl/8o3CtfK4ThP6Hpys6"
        target="_blank"
        rel="noopener noreferrer">Esq. Azueta, Av. Manuel Ávila Camacho 292 A, Zona Centro, Jose Cardel, 91030 Xalapa-Enríquez, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL XALAPA III</td>
    <td>
      <a href="https://maps.app.goo.gl/RmJGnsnG1i3HhcHu6"
        target="_blank"
        rel="noopener noreferrer">Av. Manuel Ávila Camacho 2, Zona Centro, Centro, 91000 Xalapa-Enríquez, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL XALAPA URBAN CENTER</td>
    <td>
      <a href="https://maps.app.goo.gl/XKdSZuv1HNzgfRP3A"
        target="_blank"
        rel="noopener noreferrer">Av. Lázaro Cárdenas Y Plaza Crital 923, Rafael Lucio, 91110 Xalapa-Enríquez, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ZÓCALO</td>
    <td>
      <a href="https://maps.app.goo.gl/UnoVneuNXUkeEjLM6"
        target="_blank"
        rel="noopener noreferrer">16 De Septiembre # 101 Col. Centro, Lote Zocalo, Portales Pct, 72000 Heroica Puebla de Zaragoza, Pue.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CAMPECHE II GALERÍAS</td>
    <td>
      <a href="https://maps.app.goo.gl/r9jVd9TMgKcTQC2a7"
        target="_blank"
        rel="noopener noreferrer">Pedro Sainz de Baranda 103, Área Ah, 24014 San Francisco de Campeche, Camp.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CANCÚN III CHICHEN</td>
    <td>
      <a href="https://maps.app.goo.gl/BDTjznr6WzGbYXs49"
        target="_blank"
        rel="noopener noreferrer">Av. Chichen-Itza Supermanzana 62 Manzana 7 Lote 6-A, 77500 Cancún, Q.R.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CANCÚN IV GRAN PLAZA</td>
    <td>
      <a href="https://maps.app.goo.gl/srC5sqQyZhUF3dx76"
        target="_blank"
        rel="noopener noreferrer">Avenida Nichupté, C. 4 Loc. 28, Sin Nombre, 77533 Cancún, Q.R.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CÁRDENAS TABASCO</td>
    <td>
      <a href="https://maps.app.goo.gl/wnuV6kJ9ZWHt3DNz7"
        target="_blank"
        rel="noopener noreferrer">Avenida Lázaro Cárdenas S/N, Col. Gil González, 86500 Cárdenas, Tab.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHETUMAL</td>
    <td>
      <a href="https://maps.app.goo.gl/yW8X8UGSvV3Z2ead7"
        target="_blank"
        rel="noopener noreferrer">Av de los Héroes 18, Centro, 77000 Chetumal, Q.R.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CHETUMAL II</td>
    <td>
      <a href="https://maps.app.goo.gl/VvDavALnRmvLEyWf8"
        target="_blank"
        rel="noopener noreferrer">Av. Javier Rojo Gomez, Fracción B, Lote 30, Payo Obispo, 77083 Q.R.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CIUDAD DEL CARMEN</td>
    <td>
      <a href="https://maps.app.goo.gl/haqGPaG9TuffJJo3A"
        target="_blank"
        rel="noopener noreferrer">Av Isla de Tris 2, Aeropuerto, 24119 Cdad. del Carmen, Camp.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL COMACALCO</td>
    <td>
      <a href="https://maps.app.goo.gl/jb6tR4MzQjDAzYJt6"
        target="_blank"
        rel="noopener noreferrer">Benito Juárez García 508, Centro, 86300 Comalcalco, Tab.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL COMITÁN</td>
    <td>
      <a href="https://maps.app.goo.gl/7Mbj5q3NNA6X4nmm9"
        target="_blank"
        rel="noopener noreferrer">Blvd. Sur Dr. Blisario Domínguez 98, Centro, 30000 Comitán de Domínguez, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL COZUMEL I</td>
    <td>
      <a href="https://maps.app.goo.gl/wBZKKYNC1KAmpmqRA"
        target="_blank"
        rel="noopener noreferrer">Av Lic Benito Juárez S/N, Adolfo López Mateos, 77667 Cozumel, Q.R.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MÉRIDA GRAN PLAZA</td>
    <td>
      <a href="https://maps.app.goo.gl/dbdLgGiV6WpaFk4g6"
        target="_blank"
        rel="noopener noreferrer">C. 50 Diag. 460, Gonzalo Guerrero, 97118 Mérida, Yuc.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MÉRIDA II ITZAES</td>
    <td>
      <a href="https://maps.app.goo.gl/Jnqmn4XPKxJRkeBd6"
        target="_blank"
        rel="noopener noreferrer">Av. Itzáes 644, Centro, 97000 Mérida, Yuc.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MÉRIDA III LAS AMERICAS</td>
    <td>
      <a href="https://maps.app.goo.gl/xsYG8t5wMMeJfhCq9"
        target="_blank"
        rel="noopener noreferrer">C. 21 321-A, Miguel Hidalgo, 97212 Mérida, Yuc.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MÉRIDA IV ORIENTE</td>
    <td>
      <a href="https://maps.app.goo.gl/HkFtiSYnPnK6jSoXA"
        target="_blank"
        rel="noopener noreferrer">Centro Comercial San Francisco De Asís, C. 4 (Circuito Colonias) Con 57 S/N, Pacabtún, 97160 Mérida, Yuc.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MERIDA V ALTABRISA</td>
    <td>
      <a href="https://maps.app.goo.gl/ZaMXMLKUjxUW3jwN9"
        target="_blank"
        rel="noopener noreferrer">C. 7 451-Loc. 32, Altabrisa, 97133 Mérida, Yuc.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MÉRIDA VI CENTRO</td>
    <td>
      <a href="https://maps.app.goo.gl/Ryky2rj1PmnLxToQ7"
        target="_blank"
        rel="noopener noreferrer">C. 59 No. 496, entre 56, Centro, 97000 Mérida, Yuc.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MÉRIDA VII MONTEJO</td>
    <td>
      <a href="https://maps.app.goo.gl/XpQcPHWsVSpsWXdp8"
        target="_blank"
        rel="noopener noreferrer">Calle 30.127Col, Buenavista, 97217 Mérida, Yuc.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL OCOSINGO</td>
    <td>
      <a href="https://maps.app.goo.gl/sxrfsvFZZSzbSssi6"
        target="_blank"
        rel="noopener noreferrer">Perif. Ote. Nte. 53, Ocosingo, 29950 Ocosingo, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PALENQUE I</td>
    <td>
      <a href="https://maps.app.goo.gl/8iMCqw8BmJeS1QZv9"
        target="_blank"
        rel="noopener noreferrer">Carretera Catazajá-Palenque Km. 27.5, Centro, 29960 Palenque, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PLAYA DEL CARMEN I</td>
    <td>
      <a href="https://maps.app.goo.gl/EGz3P5WdvS62dcxJ7"
        target="_blank"
        rel="noopener noreferrer">México 307, Centro, 77712 Q.R.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN CRISTOBAL</td>
    <td>
      <a href="https://maps.app.goo.gl/DczLNSASL6AKg7Vr7"
        target="_blank"
        rel="noopener noreferrer">Diagonal Hermanos Paniagua 50, San Ramon, 29200 San Cristóbal de las Casas, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SAN CRISTOBAL EXPRESS</td>
    <td>
      <a href="https://maps.app.goo.gl/J5TXMk7sqiguLT1U9"
        target="_blank"
        rel="noopener noreferrer">Blvd. Juan Sabines, carretera panamericana km. 1.178, Barrio de Fatima, 29264 San Cristóbal de las Casas, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL TUXTLA NORTE</td>
    <td>
      <a href="https://maps.app.goo.gl/UPiBUuzsLfo22xCV7"
        target="_blank"
        rel="noopener noreferrer">5a. Nte. Pte. 1742, Covadonga, 29030 Tuxtla Gutiérrez, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN CANCÚN V</td>
    <td>
      <a href="https://maps.app.goo.gl/pULi9qXupr3433L58"
        target="_blank"
        rel="noopener noreferrer">Av. Bonampak Supermanzana 6 Manzana 1 Lote 1-Ancla B02, 77500 Cancún, Q.R.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO Y CENTRO DE ATENCIÓN VILLA VI CARRIZAL</td>
    <td>
      <a href="https://maps.app.goo.gl/R6q3XpkgW4mzGd1B6"
        target="_blank"
        rel="noopener noreferrer">Esq. Periférico, Boulevard Adolfo Ruiz Cortines S/N, Carrizal, 86108 Villahermosa, Tab.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TAPACHULA II</td>
    <td>
      <a href="https://maps.app.goo.gl/w97AhFwZFwEk11r17"
        target="_blank"
        rel="noopener noreferrer">Octava Avenida Nte., Los Naranjos, Centro, 30700 Tapachula de Córdova y Ordóñez, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TAPACHULA III BOULEVARD</td>
    <td>
      <a href="https://maps.app.goo.gl/nN3VjWkZixSXoPGx7"
        target="_blank"
        rel="noopener noreferrer">Blvd. Gustavo Diaz Ordaz. 182 Coapante, 30740 Tapachula, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TUXTLA I</td>
    <td>
      <a href="https://maps.app.goo.gl/64zaGGiFCjLnQp9j9"
        target="_blank"
        rel="noopener noreferrer">Blvd. Ángel Albino Corzo 2681, entre Rodulfo Figueroa Y Las Palmas, Las Palmas, 29040 Tuxtla Gutiérrez, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TUXTLA II GALERÍAS</td>
    <td>
      <a href="https://maps.app.goo.gl/eteLGKwhDSvzG8Y66"
        target="_blank"
        rel="noopener noreferrer">Blvd. Belisario Domínguez 1861-Loc. H18, Sin Nombre, Bugambilias, 29020 Tuxtla Gutiérrez, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TUXTLA III NORTE</td>
    <td>
      <a href="https://maps.app.goo.gl/4qpATcb4dU13Tca2A"
        target="_blank"
        rel="noopener noreferrer">5a. Nte. Pte. 1742, Covadonga, 29030 Tuxtla Gutiérrez, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TUXTLA IV CENTRO</td>
    <td>
      <a href="https://maps.app.goo.gl/XDYmXchMma3LRVTt6"
        target="_blank"
        rel="noopener noreferrer">Av. 2a. Nte. Ote. 402, Centro, 29000 Tuxtla Gutiérrez, Chis.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VILLA II CALINDA</td>
    <td>
      <a href="https://maps.app.goo.gl/EPm9NvHqn6suHzDU7"
        target="_blank"
        rel="noopener noreferrer">A Un Lado Hotel Calinda, Av. Ruíz Cortínes, Av Paseo Tabasco S/N, Col. Lindavista, 86050 Tab.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VILLA III AMÉRICAS</td>
    <td>
      <a href="https://maps.app.goo.gl/zt6wkJE7fqDSDpbAA"
        target="_blank"
        rel="noopener noreferrer">Av Prof. Ramón Mendoza Herrera 104, Centro, 86029 Villahermosa, Tab.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VILLA IV CENTRO</td>
    <td>
      <a href="https://maps.app.goo.gl/NzLJZCC14Y3RK1L19"
        target="_blank"
        rel="noopener noreferrer">C. Manuel Sánchez Mármol 406, Centro Delegacion Tres, 86000 Villahermosa, Tab.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VILLA V ALTABRISA</td>
    <td>
      <a href="https://maps.app.goo.gl/KCeR8D3ixouqY9dG8"
        target="_blank"
        rel="noopener noreferrer">Periférico Carlos Pellicer Cámara, P.º Altabrisa 129-Local 4, Primero de Mayo, 86190 Villahermosa, Tab.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL VILLA VII SENDERO</td>
    <td>
      <a href="https://maps.app.goo.gl/gaPxqijjH6ttqkaD9"
        target="_blank"
        rel="noopener noreferrer">Plaza Sendero, Perif. Carlos Pellicer Cámara 1020, Jose Maria Pino Suarez, 86029 Villahermosa, Tab.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL AEROPUERTO</td>
    <td>
      <a href="https://maps.app.goo.gl/cstDV7bR9HXRCnf7A"
        target="_blank"
        rel="noopener noreferrer">Aeropuerto Inter. De La Cd. De Mexico, Capitán Carlos León S/N-Local. Cf-7, Peñón de los Baños, Benito Juárez, 15620 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL AMORES</td>
    <td>
      <a href="https://maps.app.goo.gl/3QwytagVTyQSeZiBA"
        target="_blank"
        rel="noopener noreferrer">Amores 26, código 2, Col. Del Valle, Benito Juárez, 03100 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ARAGÓN</td>
    <td>
      <a href="https://maps.app.goo.gl/u8uK6xWtinGeyM998"
        target="_blank"
        rel="noopener noreferrer">Av. Carlos Hank González 120-Loc. 2, 3 Y 4, Rinconada de Aragon, 55140 Ecatepec de Morelos, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CAMARONES</td>
    <td>
      <a href="https://maps.app.goo.gl/yPMaSUKN69r8pQBF9"
        target="_blank"
        rel="noopener noreferrer">Nte. 77 3331, Obrero Popular, Azcapotzalco, 11560 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CAPUCHINAS</td>
    <td>
      <a href="https://maps.app.goo.gl/pCQNBm2DrwQCqAfo6"
        target="_blank"
        rel="noopener noreferrer">República de Uruguay 56-Planta Alta, Centro Histórico de la Cdad. de México, Centro, Cuauhtémoc, 06000 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL COACALCO</td>
    <td>
      <a href="https://maps.app.goo.gl/xhTcrcbb4is2ouwy7"
        target="_blank"
        rel="noopener noreferrer">Av. José López Portillo 220-Local 101 Y 102, San Lorenzo Tetlixtac, 55730 San Francisco Coacalco, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CONDESA</td>
    <td>
      <a href="https://maps.app.goo.gl/B8jfd1R3xJJ2YrMP6"
        target="_blank"
        rel="noopener noreferrer">C. Ometusco 53, Hipódromo Condesa, Cuauhtémoc, 06100 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL CUAUTLA</td>
    <td>
      <a href="https://maps.app.goo.gl/KYZm92fb6Tb4Cdc98"
        target="_blank"
        rel="noopener noreferrer">Calle Conspiradores. 6, Esq, Niño Artillero, Centro, 62740 Cuautla, Mor.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL DEL VALLE</td>
    <td>
      <a href="https://maps.app.goo.gl/kG6Gb1Vj8WXbbK5ZA"
        target="_blank"
        rel="noopener noreferrer">Eje 7 Sur Felix Cuevas, C. Aniceto Ortega 825, Esq, Col. Del Valle, Benito Juárez, 03100 Ciudad de México</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL EJE CENTRAL LÁZARO CÁRDENAS</td>
    <td>
      <a href="https://maps.app.goo.gl/Hn7fJ1GQNn345SaD8"
        target="_blank"
        rel="noopener noreferrer">Eje Central Lázaro Cárdenas 911, Vértiz Narvarte, Benito Juárez, 03600 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL ETRAM AZTECA</td>
    <td>
      <a href="https://maps.app.goo.gl/gPQbzWQrYnzmqZX8A"
        target="_blank"
        rel="noopener noreferrer">Av. Carlos Hank González 50-Locales B33 A B35, Ciudad Azteca, 55120 Ecatepec de Morelos, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL EXPRÉS PACHUCA LA JOYA</td>
    <td>
      <a href="https://maps.app.goo.gl/QVx5zWKJdahMscH97"
        target="_blank"
        rel="noopener noreferrer">Blvrd Everardo Márquez 100, Periodistas, 42060 Pachuca de Soto, Hgo.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL EXPRÉS PERISUR</td>
    <td>
      <a href="https://maps.app.goo.gl/bXjyrSXF9QuXma2z9"
        target="_blank"
        rel="noopener noreferrer">Anillo Perif. Blvd. Adolfo López Mateos 4690-Local 129, Insurgentes Cuicuilco, Coyoacán, 04500 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL EXPRÉS TOLUCA COLÓN</td>
    <td>
      <a href="https://maps.app.goo.gl/CGKMC4cS6apUNuq17"
        target="_blank"
        rel="noopener noreferrer">P.º Cristóbal Colón 200, Residencial Colón y Col Ciprés, 50120 Toluca de Lerdo, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL EXPRÉS XOCHIMILCO</td>
    <td>
      <a href="https://maps.app.goo.gl/r1xjjBGs74FaYGVv9"
        target="_blank"
        rel="noopener noreferrer">Av. Guadalupe I. Ramírez 318, Col. Tepepan, San Marcos, Xochimilco, 16050 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GALERÍAS ATIZAPÁN</td>
    <td>
      <a href="https://maps.app.goo.gl/E2yyc3zMbTdGg3hB6"
        target="_blank"
        rel="noopener noreferrer">Av. Adolfo Ruiz Cortines 255, Mexico Nuevo, 52977 Cdad. López Mateos, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GALERÍAS CUERNAVACA</td>
    <td>
      <a href="https://maps.app.goo.gl/7DnL9yYpLAMLkZz47"
        target="_blank"
        rel="noopener noreferrer">Autopista Mexico-Acapulco Km 87 +, 850, Local 160, Ricardo Flores Magon, 62370 Cuernavaca, Mor.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GALERIAS PACHUCA</td>
    <td>
      <a href="https://maps.app.goo.gl/NDanCvLQmPUpFnH4A"
        target="_blank"
        rel="noopener noreferrer">Camino Real de La Plata 100-Loc. 230, Zona Plateada, 42083 Pachuca de Soto, Hgo.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL GALERÍAS TOLUCA</td>
    <td>
      <a href="https://maps.app.goo.gl/BLEmdEu882cub3th7"
        target="_blank"
        rel="noopener noreferrer">378, Av. Paseo Tollocan s/n, Santa Ana Tlapaltitlán, 50071 Santa Ana Tlapaltitlán, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL INTERLOMAS</td>
    <td>
      <a href="https://maps.app.goo.gl/4eywmWMrHw1cDwGHA"
        target="_blank"
        rel="noopener noreferrer">Circuito, Magnocentro Interlomas Manzana 2 Lote 1, Palo Solo, 52787 Naucalpan de Juárez, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL IXTAPALUCA</td>
    <td>
      <a href="https://maps.app.goo.gl/hYp86ZPCZNVCmBqF9"
        target="_blank"
        rel="noopener noreferrer">Carr Federal México-Cuautla Km.37, Hacienda De Santa Barbara, 56538 Ixtapaluca, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL IZTAPALAPA</td>
    <td>
      <a href="https://maps.app.goo.gl/KPGT3quFmJ3XoG7Q6"
        target="_blank"
        rel="noopener noreferrer">Ermita Iztapalapa 510, Mexicaltzingo, Iztapalapa, 09090 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LA CÚSPIDE</td>
    <td>
      <a href="https://maps.app.goo.gl/gpkoj95djBqSN89N6"
        target="_blank"
        rel="noopener noreferrer">Avenida Lomas Verdes 1200, 5Ta. Seccion, Lomas Verdes, 53126 Naucalpan de Juárez, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LAGO ALBERTO</td>
    <td>
      <a href="https://maps.app.goo.gl/EWDfT7gCijzvqz4z7"
        target="_blank"
        rel="noopener noreferrer">Lago Alberto 366, Anáhuac I Secc, Miguel Hidalgo, 11320 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LAS AMÉRICAS</td>
    <td>
      <a href="https://maps.app.goo.gl/RXy8PSXbogrY7iXa8"
        target="_blank"
        rel="noopener noreferrer">Manzana 005, Fraccionamiento Las Americas, Las Américas, 55070 Ecatepec de Morelos, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LINDAVISTA</td>
    <td>
      <a href="https://maps.app.goo.gl/vQgYKSibVGAckVzr8"
        target="_blank"
        rel="noopener noreferrer">Av. Miguel Othon De Mendizabal, Av. Ex-Hacienda de Enmedio 455, La Escalera, Gustavo A. Madero, 07900 Ciudad de México</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL LORETO</td>
    <td>
      <a href="https://maps.app.goo.gl/V83zcP9PYL4meTne6"
        target="_blank"
        rel="noopener noreferrer">Cuarta Privada Altamirano 46, Tizapán San Ángel, San Ángel, Álvaro Obregón, 01090 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MASARYK</td>
    <td>
      <a href="https://maps.app.goo.gl/ZKmEjJ2cxiWzTYFy9"
        target="_blank"
        rel="noopener noreferrer">Tennyson, Av. Pdte. Masaryk 120, Esq, Polanco, Reforma, Miguel Hidalgo, 11560 Ciudad de México</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL METRÓPOLI PATRIOTISMO</td>
    <td>
      <a href="https://maps.app.goo.gl/n8B71UdfB1brSsae8"
        target="_blank"
        rel="noopener noreferrer">Av. Patriotismo 229-Local Pa 09, San Pedro de los Pinos, Benito Juárez, 03800 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL NEZA</td>
    <td>
      <a href="https://maps.app.goo.gl/otpLh2FVtrJtEdsr5"
        target="_blank"
        rel="noopener noreferrer">Av. Bordo de Xochiaca 3, Plaza Comercial Plaza Jardín, Loc. E3 Ciudad Jardín, Bicentenario, 57200 Cdad. Nezahualcóyotl, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PABELLÓN DEL VALLE</td>
    <td>
      <a href="https://maps.app.goo.gl/MFjEkKR1SR8DFVVr6"
        target="_blank"
        rel="noopener noreferrer">Av. Universidad 740-Local No.2, Sta Cruz Atoyac, Benito Juárez, 03100 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PABELLÓN POLANCO</td>
    <td>
      <a href="https://maps.app.goo.gl/EEtFK4k6ScCDKvZF7"
        target="_blank"
        rel="noopener noreferrer">Av. Ejército Nacional Mexicano 980-Local 250 Y 252, Chapultepec Morales, Polanco I Secc, Miguel Hidalgo, 11510 Miguel Hidalgo, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PACHUCA</td>
    <td>
      <a href="https://maps.app.goo.gl/s7r6Y5kgMYPkxPe46"
        target="_blank"
        rel="noopener noreferrer">Blvrd Luis Donaldo Colosio Km 77, Colinas de Plata, 42186 Colinas de Plata, Hgo.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PARQUE LINDAVISTA</td>
    <td>
      <a href="https://maps.app.goo.gl/cb6iWJxp2ohPqtuE6"
        target="_blank"
        rel="noopener noreferrer">Colector 13 280-Local Sa-10, Magdalena de las Salinas, Gustavo A. Madero, 07760 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PARQUE VÍA VALLEJO</td>
    <td>
      <a href="https://maps.app.goo.gl/sABb3XsWgkyia7JF8"
        target="_blank"
        rel="noopener noreferrer">Calz. Vallejo 1090-Local 232-234, Col. Santa Cruz De La Salinas, Azcapotzalco, 02340 Azcapotzalco, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PASEO ACOXPA</td>
    <td>
      <a href="https://maps.app.goo.gl/KLkKpK2z3AjhgpV38"
        target="_blank"
        rel="noopener noreferrer">Av Acoxpa 430-Loc. A-PA 03-04, Coapa, Vergel del Sur, Tlalpan, 14340 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PERINORTE</td>
    <td>
      <a href="https://maps.app.goo.gl/8TdXExhmbwvNXrVE6"
        target="_blank"
        rel="noopener noreferrer">Hacienda de Sierra Vieja 2-Local H27, Hacienda del Parque, 54769 Cuautitlán Izcalli, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PERISUR</td>
    <td>
      <a href="https://maps.app.goo.gl/pNGSdoxxyUMTGu5z8"
        target="_blank"
        rel="noopener noreferrer">Periferico Sur 4690, Jardines del Pedregal de San Ángel, Coyoacán, 04500 Coyoacán, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PLAZA CARSO</td>
    <td>
      <a href="https://maps.app.goo.gl/TsM8ujYMFdTpFpDh6"
        target="_blank"
        rel="noopener noreferrer">C. Lago Zurich 245-Local A-19, Amp Granada, Miguel Hidalgo, 11529 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PLAZA CENTRAL</td>
    <td>
      <a href="https://maps.app.goo.gl/2ptMJK8aY9A3LmZr9"
        target="_blank"
        rel="noopener noreferrer">Av. Canal Rio Churubusco, Central de Abasto 1635-Local 101, 102, 103 Y 104-A, 09040 Iztapalapa, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PLAZA CUERNAVACA</td>
    <td>
      <a href="https://maps.app.goo.gl/zAvcgtAW2drvEQBm7"
        target="_blank"
        rel="noopener noreferrer">Av Vicente Guerrero 110-Local A-18, Lomas de la Selva, 62270 Cuernavaca, Mor.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PLAZA SAN MARCOS</td>
    <td>
      <a href="https://maps.app.goo.gl/u3Jj2RdcRig7jw5y8"
        target="_blank"
        rel="noopener noreferrer">Av Chalma S/N-SA02, Jardines de la Hacienda, 54720 Cuautitlán Izcalli, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PLAZA SATÉLITE</td>
    <td>
      <a href="https://maps.app.goo.gl/jTT2KmMNtSGU7DPZA"
        target="_blank"
        rel="noopener noreferrer">Plaza Satélite, Cto Centro Comercial 2251-Loc. R313A, Cd. Satélite, 53100 Naucalpan de Juárez, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL REFORMA 222</td>
    <td>
      <a href="https://maps.app.goo.gl/2hUMPYGAPwSmnwcP6"
        target="_blank"
        rel="noopener noreferrer">Reforma 222, entre Havre Y Av.Insurgentes, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL REFORMA LOMAS</td>
    <td>
      <a href="https://maps.app.goo.gl/WipKRUnsTTriprcQ9"
        target="_blank"
        rel="noopener noreferrer">Av. Reforma, Lomas 310, Lomas de Chapultepec, Miguel Hidalgo, 11000 Ciudad de México</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL SANTA FE II</td>
    <td>
      <a href="https://maps.app.goo.gl/v6RD1bxhvoNuM6Kt6"
        target="_blank"
        rel="noopener noreferrer">Vasco de Quiroga 3850-Local 1665, Santa Fe, Contadero, Cuajimalpa de Morelos, 05109 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL ZENTRALIA CHURUBUSCO</td>
    <td>
      <a href="https://maps.app.goo.gl/9K7x4SeAQApEEmrn8"
        target="_blank"
        rel="noopener noreferrer">Av. Río Churubusco 1072, Nueva Rosita, Iztapalapa, 09420 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL TOLUCA SENDERO</td>
    <td>
      <a href="https://maps.app.goo.gl/HzWAo41S2tbrGpF88"
        target="_blank"
        rel="noopener noreferrer">Blvd. Aeropuerto Miguel Alemán, Av. Paseo Tollocan 55, Esq, 52000 Lerma de Villada, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO ATENCIÓN ETRAM ROSARIO</td>
    <td>
      <a href="https://maps.app.goo.gl/bLLR85BtUDS4nn1F8"
        target="_blank"
        rel="noopener noreferrer">Av El Rosario 901-Local L210, El Rosario, Azcapotzalco, 02100 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO ATENCIÓN PASEO INTERLOMAS</td>
    <td>
      <a href="https://maps.app.goo.gl/bR4bCrmhVDRrGAPS9"
        target="_blank"
        rel="noopener noreferrer">Vialidad de la Barranca 6-Local Sa-N2-03, Col. Ex Hacienda Jesús del Monte, Bosque de las Palmas, 52787 Naucalpan de Juárez, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN BUENAVISTA</td>
    <td>
      <a href="https://maps.app.goo.gl/sjmuEc2kYJRZCGer6"
        target="_blank"
        rel="noopener noreferrer">Av. Insurgentes Norte, Esq. Eje 1, Mosqueta Loc. Sa-N2-06, Buenavista, Cuauhtémoc, 06350 Ciudad de México</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN CUEMANCO</td>
    <td>
      <a href="https://maps.app.goo.gl/oe2icnLeoPLFh3Wm7"
        target="_blank"
        rel="noopener noreferrer">Cañaverales 222, Coapa, Granjas Coapa, Tlalpan, 14330 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN CUICUILCO</td>
    <td>
      <a href="https://maps.app.goo.gl/A4ffiYp8abRwhbH17"
        target="_blank"
        rel="noopener noreferrer">Av. San Fernando 649-Local 1 y 4, Manantial Peña Pobre, Tlalpan, 14060 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN GALERÍAS COAPA</td>
    <td>
      <a href="https://maps.app.goo.gl/ZDb1cghq8jAxB2YNA"
        target="_blank"
        rel="noopener noreferrer">Calz. del Hueso 519 Centro-Local 253, Coapa, Acoxpa, Tlalpan, 14300 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN LAS ANTENAS</td>
    <td>
      <a href="https://maps.app.goo.gl/ch7YH1tLqr6tZyeUA"
        target="_blank"
        rel="noopener noreferrer">Anillo Perif. 3278, La Esperanza, Iztapalapa, 09910 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN LUNA PARC</td>
    <td>
      <a href="https://maps.app.goo.gl/umo6f8CH4mUXS8fp8"
        target="_blank"
        rel="noopener noreferrer">Av. Primero de Mayo S/N-Mz. C-34-C, Locales 342 Y 343, Centro Urbano, 57740 Cuautitlán Izcalli, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN MUNDO E</td>
    <td>
      <a href="https://maps.app.goo.gl/svHooSjBYzXcBgP86"
        target="_blank"
        rel="noopener noreferrer">Perif. Blvd. Manuel Ávila Camacho 1007-Local 15-B, San Lucas Tepetlacalco, 54055 Tlalnepantla, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN PARQUE DELTA</td>
    <td>
      <a href="https://maps.app.goo.gl/gQRvFmZW97TsgWza7"
        target="_blank"
        rel="noopener noreferrer">Av.Cuauhtemoc. 462, Esq. Viaducto, Loc. 208-210, 210A, Y 211A, Piedad Narvarte, Benito Juárez, 03020 Ciudad de México</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN PARQUE TEZONTLE</td>
    <td>
      <a href="https://maps.app.goo.gl/ZgG5eunNFW1pwLTu8"
        target="_blank"
        rel="noopener noreferrer">Canal De Tezontle. 1512, Loc. 232BC-235BC, Col. Alfonso, Dr Alfonso Ortiz Tirado, Iztapalapa, 09020 Ciudad de México</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN SANTA FE I</td>
    <td>
      <a href="https://maps.app.goo.gl/5fEQaKZieQiPQxAH8"
        target="_blank"
        rel="noopener noreferrer">Av. Vasco De Quiroga. 3800, Local 628 1er. Nivel Ciudad de México MX, Mina La, Col. Antigua, Cuajimalpa de Morelos, 05109 Totolapa</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN TLALNEPANTLA</td>
    <td>
      <a href="https://maps.app.goo.gl/UsU8cYyeMiyNryVG6"
        target="_blank"
        rel="noopener noreferrer">Av Sor Juana Inés de La Cruz 280-Local A-04, San Lorenzo, 54033 Tlalnepantla, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN TOREO</td>
    <td>
      <a href="https://maps.app.goo.gl/7PrMJAbe6cPcY4QD9"
        target="_blank"
        rel="noopener noreferrer">Perif. Blvd. Manuel Ávila Camacho 5-Local Lpc-01, Lomas de Sotelo, 53390 Naucalpan de Juárez, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN ZARAGOZA</td>
    <td>
      <a href="https://maps.app.goo.gl/Z1Xh5mW6s1MuPRoW8"
        target="_blank"
        rel="noopener noreferrer">Calz. Ignacio Zaragoza 1090-Locales E Y F, Agrícola Pantitlán, Iztacalco, 08100 Ciudad de México, CDMX</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TECAMAC POWER CENTER</td>
    <td>
      <a href="https://maps.app.goo.gl/cGNoYQWi8bmKLGR86"
        target="_blank"
        rel="noopener noreferrer">Carr. Federal Pachuca - Mexico Km. 36.5, Hueyotenco, 55740 San Martín Azcatepec, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TEMIXCO</td>
    <td>
      <a href="https://maps.app.goo.gl/J4x4LravaEsGsCuR6"
        target="_blank"
        rel="noopener noreferrer">Blvd. Apatlaco 145 Fracc. A-Local 12 Al 15, Acatlipa Centro, 62586 Temixco, Mor.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TEXCOCO</td>
    <td>
      <a href="https://maps.app.goo.gl/ojNrnM81jsM1p1kS9"
        target="_blank"
        rel="noopener noreferrer">Av. Juárez Nte. 304, Centro, 56160 Texcoco de Mora, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TOLUCA METEPEC</td>
    <td>
      <a href="https://maps.app.goo.gl/jqQUppm4sTGkVpAt8"
        target="_blank"
        rel="noopener noreferrer">Plaza Izar, C. Leona Vicario 936-Loc. 11-14 PB, Colonia. Ex Hacienda de la Purísima, Real de Arcos, 52156 Metepec, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TORRE LATINOAMERICANA</td>
    <td>
      <a href="https://maps.app.goo.gl/N4jm8WrW3ewDqmxV6"
        target="_blank"
        rel="noopener noreferrer">San Juan De Letran.2, Esq, Av Francisco I. Madero 1, Centro Histórico de la Cdad. de México, Cuauhtémoc, 06060 Ciudad de México</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL TULANCINGO</td>
    <td>
      <a href="https://maps.app.goo.gl/DkNp8YC99xKRL2V29"
        target="_blank"
        rel="noopener noreferrer">Blvd. Miguel Hidalgo, S-20 1101-Loc. J-01 Y, Fracc. Santa Ana, 43600 Tulancingo, Hgo.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL HERMOSILLO FRANQUICIA</td>
    <td>
      <a href="https://maps.app.goo.gl/ZBMJWQGxPCZUEZzq8"
        target="_blank"
        rel="noopener noreferrer">Blvd. Solidaridad 154, Raquet Club, 83200 Hermosillo, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL NOGALES</td>
    <td>
      <a href="https://maps.app.goo.gl/wfFTWTGPA3a9nJsL9"
        target="_blank"
        rel="noopener noreferrer">Av. Alvaro Obregon 643, Centro, 84000 Heroica Nogales, Son.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL COATZACOALCOS II FORUM</td>
    <td>
      <a href="https://maps.app.goo.gl/GspyirA1KbFo6QnW7"
        target="_blank"
        rel="noopener noreferrer">Av. Universidad km 8, Col. Predio Santa Rosa, Fovissste, 96536 México, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL MORELIA III</td>
    <td>
      <a href="https://maps.app.goo.gl/qz64DSe2kiyiypH9A"
        target="_blank"
        rel="noopener noreferrer">Perif. Paseo de la República 501, Los Manantiales de Morelia, 58188 Morelia, Mich.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL OAXACA I</td>
    <td>
      <a href="https://maps.app.goo.gl/5nWSgMdybcCzL3jc6"
        target="_blank"
        rel="noopener noreferrer">Calz. Porfirio Díaz 241, Reforma, 68050 Oaxaca de Juárez, Oax.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL PUERTO ESCONDIDO</td>
    <td>
      <a href="https://maps.app.goo.gl/vTtFHQHTbscayLCk6"
        target="_blank"
        rel="noopener noreferrer">Av. Alfonso Pérez Gasga 1723-El, Marinero, 71984 San Pedro Mixtepec Distrito 22, Oax.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL XALAPA II</td>
    <td>
      <a href="https://maps.app.goo.gl/xrEX1xHs3qrCLSrX6"
        target="_blank"
        rel="noopener noreferrer">Carr. Xalapa Veracruz 680, Rubi Animas, 91193 Xalapa-Enríquez, Ver.</a>
    </td>
  </tr>
  <tr>
    <td>CAC TELCEL COACALCO COSMOPOL</td>
    <td>
      <a href="https://maps.app.goo.gl/PGZtMDZAPaKAtXno8"
        target="_blank"
        rel="noopener noreferrer">Bosque de Felipe 2, Bosques del Valle, 55717 San Francisco Coacalco, Méx.</a>
    </td>
  </tr>
  <tr>
    <td>SERVICIO TÉCNICO TELCEL Y CENTRO DE ATENCIÓN NICOLAS ROMERO</td>
    <td>
      <a href="https://maps.app.goo.gl/J9FLaTgY4gfXTNXz9"
        target="_blank"
        rel="noopener noreferrer">Blvd. Arturo Montiel Rojas Km. 10.7, Vista Hermosa, 54414 Cdad. Nicolás Romero, Méx.</a>
    </td>
  </tr>
</table>`;

export default cacsChecocards;