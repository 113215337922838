import React from 'react'
import Layout from 'components/routes/Layout';

import './mechanics.scss'
import changeNameImages from 'utils/changeNameImages';
import { VideoTutorial } from 'components/video/video';

import SEO from 'providers/HelmetProvider';

function Mechanics() {
  const bg = changeNameImages('/assets/images/mechanics/bg.jpg');
  const bgDesk = changeNameImages('/assets/images/mechanics/bg-desk.jpg');
  const step2 = changeNameImages('/assets/images/mechanics/step2-mapa.jpg');
  const step3 = changeNameImages('/assets/images/mechanics/step3-cards.png');

  return (
    <>
      <SEO
        title={'Checo Cards | Mecánica'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'} />
      <Layout id='mechanics' classes='pt-main pb-18 relative'>
        <div className='bg-checo cnt full d-md-block' hidden>
          <picture>
            <source srcSet={bgDesk.desk_avif + ' 1x, ' + bgDesk.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={bgDesk.desk_webp + ' 1x, ' + bgDesk.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='full cnt'
              srcSet={bgDesk.desk + ' 1x, ' + bgDesk.desk2 + ' 2x'}
              src={bgDesk.desk}
              alt=''
              width={1440}
              height={980} />
          </picture>
        </div>
        <div className='bg cnt full d-md-none'>
          <picture>
            <source srcSet={bg.desk_avif + ' 1x, ' + bg.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={bg.desk_webp + ' 1x, ' + bg.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='full cnt'
              srcSet={bg.desk + ' 1x, ' + bg.desk2 + ' 2x'}
              src={bg.desk}
              alt=''
              width={375}
              height={1274} />
          </picture>
        </div>
        <section className='relative pb-24'>
          <h1 className='text-white text-venera text-center'>Mecánica</h1>
          <div className='steps mb-24 mt-md-24 relative'>
            <div className='step flex-md px-'>
              <div className='flex pr-container'>
                <p className='number text-venera pt-24'>01</p>
                <div className='text-white pl-12 pr-6'>
                  <p className='info'>Conoce cómo utilizar</p>
                  <div className='logo-checo mt-12 col-1'>
                    <img className='col-1'
                      src={'/assets/images/home/logo-checo-cards.svg'}
                      alt='Logo - Checo cards'
                      width={150}
                      height={57} />
                  </div>
                </div>
              </div>
              <div className='px-container'>
                <VideoTutorial />
              </div>
            </div>
            <div className='step flex-md py-md-18'>
              <div className='flex pr-container'>
                <p className='number text-venera pt-24'>02</p>
                <div className='text-white pr-6'>
                  <p className='info'>Explora en el mapa y encuentra tarjetas con contenido especial de Checo Pérez</p>
                </div>
              </div>
              <div className='px-container'>
                <picture>
                  <source srcSet={step2.desk_avif + ' 1x, ' + step2.desk2_avif + ' 2x'}
                    type="image/avif" />
                  <source srcSet={step2.desk_webp + ' 1x, ' + step2.desk2_webp + ' 2x'}
                    type="image/webp" />
                  <img className='col-1'
                    srcSet={step2.desk + ' 1x, ' + step2.desk2 + ' 2x'}
                    src={step2.desk}
                    alt=''
                    width={375}
                    height={1274} />
                </picture>
              </div>
            </div>
            <div className='step flex-md'>
              <div className='flex pr-container'>
                <p className='number text-venera pt-24'>03</p>
                <div className='text-white pr-6'>
                  <p className='info'>Colecciona todas las tarjetas y ¡Sé el primero en llenar su álbum!</p>
                </div>
              </div>
              <div>
                <picture>
                  <source srcSet={step3.desk_avif + ' 1x, ' + step3.desk2_avif + ' 2x'}
                    type="image/avif" />
                  <source srcSet={step3.desk_webp + ' 1x, ' + step3.desk2_webp + ' 2x'}
                    type="image/webp" />
                  <img className='col-1'
                    srcSet={step3.desk + ' 1x, ' + step3.desk2 + ' 2x'}
                    src={step3.desk}
                    alt=''
                    width={375}
                    height={1274} />
                </picture>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export { Mechanics }
