const faqs = `<ul class="no-list">
  <li class="mb-24">
    <h2 class="b pb-6">¿Qué son las Checo Cards?</h2>
    <hr>
    <p>Es una colección de tarjetas digitales con los momentos más épicos de la carrera del piloto mexicano Sergio “Checo” Pérez. Y que se pueden encontrar en diferentes puntos del país. Siendo los Centros de Atención de Clientes Telcel uno de ellos.
      <br><br>
      Para completar tu álbum digital, lo único que tienes que hacer es lo siguiente:</p>
    <ol class="pl-24">
      <li><p>Regístrate o inicia sesión en <a 
        class="text-blue"
        href="www.checocardstelcel.com"
        target="_blank">www.checocardstelcel.com</a>.</p></li>
      <li><p>Toma tu <i>smartphone</i>, prepara <b>la cámara y recorre las calles de México</b> para encontrar todas las tarjetas: en Centros de Atención a Clientes Telcel y puntos de interés turístico de toda la República mexicana.</p></li>
      <li><p>Consulta y <b>completa tu álbum digital</b> de Checo Pérez y listo.</p></li>
    </ol>
    <p>Recuerda que la colección consta de <b>100 tarjetas digitales</b> y  si eres uno de los primeros en llenar el álbum, <b>podrás participar para ganar increíbles premios</b>.
      <br><br>
      Y si te faltan tarjetas, dale <i>follow</i> a todas las redes sociales de Telcel para poder encontrarlas.</p>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6">¿Cómo se pueden obtener las Checo Cards?</h2>
    <hr>
    <p>Al ser una experiencia 100% digital, las distintas maneras de encontrarlas son las siguientes:
      <br><br>
      Mediante un escaneo de realidad aumentada, en los puntos marcados dentro de <a 
        class="text-blue"
        href="www.checocardstelcel.com"
        target="_blank">www.checocardstelcel.com</a>. Los cuales son:</p>
    <ul class="pl-24">
      <li><p>Centros de Atención a Clientes Telcel (Toda la República Mexicana)</p></li> 
      <li><p>Links y códigos QR que se estarán publicando en las cuentas de redes sociales oficiales de Telcel, Escudería Telmex y Telcel Automovilismo, en los contenidos relacionados al piloto Sergio “Checo” Pérez.</p></li> 
      <li><p>En las plazas en donde se presente la Gira del “Static Show Car”</p></li> 
    </ul>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6">¿En qué Países se puede vivir la Experiencia de Checo Cards de Telcel?</h2>
    <hr>
    <p>De momento solamente en toda la República Mexicana.</p>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6"¿Es necesario registrarme para poder vivir la experiencia de de Checo Cards de Telcel?</h2>
    <hr>
    <p>Para poder llenar el álbum digital, si es necesario el registro completo de los usuarios. En dado caso de no realizar un registro completo, no se podrá disfrutar la experiencia en su totalidad.</p>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6">¿Las Checo Cards de Telcel son físicas?</h2>
    <hr>
    <p>Todas las 100 (cien) tarjetas, al igual que el álbum, son totalmente en formato digital. El usuario no podrá reclamar o solicitar dentro de los Centros de Atención a Clientes Telcel alguna tarjeta o álbum físico relacionado a este tema.</p>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6">¿Las Checo Cards tiene algún valor económico?</h2>
    <hr>
    <p>Todas las 100 (cien) tarjetas de Checo Cards carecen de algún valor monetario o de intercambio por algún otro bien físico no mencionado en los Términos y Condiciones.</p>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6">¿Las Checo Cards se podrán cambiar por algún premio que no sea digital?</h2>
    <hr>
    <p>Mantenete al pendiente de las actualizaciones dentro de la sección de “Novedades” en <a 
      class="text-blue"
        href="www.checocardstelcel.com"
        target="_blank">www.checocardstelcel.com</a>, para conocer toda la información referente a premios, dinámicas y ganadores. 
      <br><br>
      Para mayor información:
      <br>
      <a 
        class="text-blue"href="https://checocardstelcel.com/terminos-y-condiciones"
        target="_blank">https://checocardstelcel.com/terminos-y-condiciones</a></p>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6">¿Las Checo Cards están a la venta? ¿Dónde se pueden comprar? </h2>
    <hr>
    <p>No, las Checo Cards son tarjetas coleccionables digitales, no están a la venta en ningún Centro Autorizado Telcel, ni en ningún punto de venta.</p>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6">¿Cuánto tiempo van a durar las Checo Cards?</h2>
    <hr>
    <p>La experiencia está vigente desde julio del 2024 hasta julio del 2025.</p>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6">¿Hay algún beneficio si completo el álbum de las Checo Cards? </h2>
    <hr>
    <p>Si eres de los primeros en coleccionarlo podrás ganar increíbles premios, así que sigue con la búsqueda y participa para poder ser unos de los ganadores. Los invitamos a mantenerse pendientes en nuestras redes sociales, si hay alguna noticia sobre este tema, ahí podrán enterarse.</p>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6">¿Se pueden intercambiar las Checo Cards con otros usuarios de la plataforma?</h2>
    <hr>
    <p>Intercambiar como tal no, lo que sí se podrá hacer es transferir tarjetas, esto se podrá hacer mediante un botón en la Web App que te dará un link para compartir en WhatsApp.</p>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6">¿Por qué no puedo entrar al mapa para conocer las ubicaciones de las Checo Cards? </h2>
    <hr>
    <p>Si tienes algún problema para poder entrar al mapa y comenzar a buscar Checo Cards, te sugerimos activar la geolocalización de tu navegador, de esa manera ya podrás empezar a buscar tarjetas.</p>
  </li>
  <li class="mb-24">
    <h2 class="b pb-6">¿Tengo que ir a otros estados para conseguir todas las Checo Cards? </h2>
    <hr>
    <p>No necesariamente, en todas las ubicaciones de las Checo Cards cada cierto tiempo van cambiando aleatoriamente, es decir, en una misma ubicación podrás encontrar diferentes Checo Cards.</p>
  </li>
</ul>`;

export default faqs;