export const customPasswordValidator = (value) => {
  if (!value) return 'La contraseña es obligatoria';

  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasTwoDigits = /\d.*\d/.test(value);

  if (!hasUpperCase || !hasLowerCase || !hasTwoDigits) {
    return 'La contraseña debe contener al menos una letra mayúscula, una letra minúscula y dos dígitos';
  }

  return true;
};