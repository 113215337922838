import React from 'react'
import Layout from 'components/routes/Layout';

import './news.scss'
import SEO from 'providers/HelmetProvider';
import { Link } from 'react-router-dom';

import changeNameImages from 'utils/changeNameImages';
import CreatSlug from 'utils/createSlug';
import NewService from 'services/newService.service';

function News() {
  const bg = changeNameImages('/assets/images/global/bg.png');
  const data = NewService.getNews();

  return (
    <>
      <SEO
        title={'Checo Cards | Noticias'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'} />
      <Layout id='news' classes='pt-main pb-18 relative'>
        <div className='bg cnt-h col-1'>
          <picture>
            <source srcSet={bg.desk_avif + ' 1x, ' + bg.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={bg.desk_webp + ' 1x, ' + bg.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='col-1'
              srcSet={bg.desk + ' 1x, ' + bg.desk2 + ' 2x'}
              src={bg.desk}
              alt=''
              width={375}
              height={420} />
          </picture>
        </div>
        <div className='px-container relative pb-24'>
          <h1 className='text-venera text-center mb-24'>Novedades</h1>
          <div className='grid-container mx-auto pb-12'>
            {data.map((note, i) => {
              return (
                <div className='new mb-18 p-6 mb-sm-0' key={i}>
                  <Link 
                    to={CreatSlug(note.title)}
                    state={note}
                    className='note relative'>
                    <picture>
                      <source srcSet={note.image.sm_avif + ' 1x, ' + note.image.sm2_avif + ' 2x'}
                        type="image/avif" />
                      <source srcSet={note.image.sm_webp + ' 1x, ' + note.image.sm2_webp + ' 2x'}
                        type="image/webp" />
                      <img className='col-1'
                        srcSet={note.image.sm + ' 1x, ' + note.image.sm2 + ' 2x'}
                        src={note.image.sm}
                        alt=''
                        width={321}
                        height={250} />
                    </picture>
                    <div className='title pt-24 px-18 pb-18 cnt-h'>
                      <p className='text-white b relative h2'>{note.title}</p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export { News }
