import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import { ax } from 'providers/LoaderProvider';
import { Toastify } from 'components/toastify/toastify';
import Layout from 'components/routes/Layout';

import { CardImage } from './cardImage';
import changeNameImages from 'utils/changeNameImages';
import UserService from 'services/userService.service';
import { Skeleton } from 'components/skeleton/skeleton';
import { useUserContext } from 'providers/UserProvider';
import SEO from 'providers/HelmetProvider';
import './detailCard.scss'

const DetailCard = () => {
  const bg = changeNameImages('/assets/images/detail-card/bg.jpg');
  const navigate = useNavigate();
  const toastifyRef = useRef();
  const messageToShare = 'Hola, te quiero transferir una Checo Card, entra a la liga y guarda la tarjeta. ';
  const params = useParams();
  const location = useLocation();
  const data = location.state;
  const [albumPage, setAlbumPage] = useState(-1);
  const [card, setCard] = useState(null);
  const [cardLocation, setCardLocation] = useState(null);
  const [cardCategory, setCardCategory] = useState('1');
  const [totalCards, setTotalCards] = useState(100);
  const [repeated, setRepeated] = useState(0);
  const [tooltipState, setTooltipState] = useState(-1);
  const [shareURL, setShareURL] = useState('');
  const { user } = useUserContext();

  const showTooltip = () => {
    if (!card) return;
    if (tooltipState !== -1) {
      clearTimeout(tooltipState);
    }
    const auxTooltip = setTimeout(() => {
      setTooltipState((current) => {
        clearTimeout(current);
        return -1
      });
    }, 3500);
    setTooltipState(auxTooltip);
  }

  const closeTooltip = () => {
    clearTimeout(tooltipState);
    setTooltipState(-1);
  }

  const getDataCard = () => {
    if (data) {
      setCard(data.card.card);
      setCardLocation(data.card.location ? data.card.location.title : 'Tarjeta de regalo');
    }
    if (data && data.totalCards) {
      setTotalCards(data.totalCards);
    }
    getCard();
  }

  const getCard = async () => {
    const cardId = params.idCard;
    const token = localStorage.getItem('CHECO_TOKEN');
    const user = JSON.parse(localStorage.getItem('CHECO_USER'));

    const headerConfig = {
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'token': token,
      }
    };
    
    ax
      .get(
        (`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_ENDPOINT_GET_CARD_BY_USER}`).replace('@cardId', cardId).replace('@userId', user.uid),
        headerConfig)
      .then(async (res) => {
        if (res.status !== 200 && res.status !== 201) {
          if (res.status === 400) {
            navigate('/404');
          } else {
            throw new Error(errorResponse.message || 'Ocurrió un error inesperado');
          }
        } else {
          if (!data || !data.totalCards) {
            getCountCards();
          }
          setCard(res.data.data[0].card);
          setCardCategory(res.data.dataCategory);
          if (res.data.data[0].location || res.data.data[0].QRcard || res.data.data[0].card.september) {
            setCardLocation(res.data.data[0].QRcard || res.data.data[0].card.september ? 'Tarjeta de regalo' : res.data.data[0].location.title);
            setRepeated(res.data.Repetidas + 1);
            setShareURL(window.location.origin + '/transfer-card/' + res.data.data[0].cardID + '/' + res.data.urlTransfer);
          }
          if (data && data.albumPage) {
            setAlbumPage(data.albumPage);
          } else {
            const cardNumber = res.data.data[0].card.CardNumber;
            setAlbumPage(Math.ceil(cardNumber / 10) - 1);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          navigate('/404');
        } else {
          toastifyRef.current.notify(
            'error', 
            err.response.data.msg || 'Ocurrió un error inesperado'
          );
        }
      })
  }

  const getCardWithOutSession = async () => {
    const cardId = params.idCard;
    const headerConfig = {
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    };
    
    ax
      .get(
        (`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_ENDPOINT_GET_CARD_WITHOUT_USER}`).replace('@cardId', cardId),
        headerConfig)
      .then(async (res) => {
        if (res.status !== 200 && res.status !== 201) {
          if (res.status === 400) {
            navigate('/404')
          } else {
            throw new Error(errorResponse.message || 'Ocurrió un error inesperado');
          }
        } else {
          getCountCards();
          setCard(res.data);
          setCardCategory(res.data.category.category);
        }
      })
      .catch((err) => {
        console.error(err);
        toastifyRef.current.notify(
          'error', 
          err.response.data.msg || 'Ocurrió un error inesperado'
        );
      })
  }

  const getCountCards = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_ENDPOINT_GET_COUNTER_CARDS}`, 
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || 'Ocurrió un error al obtener el total de las cartas.');
      }
      const res = await response.json();
      if (res.data && totalCards < res.data.count) {
        setTotalCards(res.data.count)
      }
    } catch (error) {
      toastifyRef.current.notify(
        'error', 
        error.message
      );
    }
  }

  const validateToken = async () => {
    try {
      const res = await UserService.validToken();
      if (res) {
        getDataCard();
      } else {
        getCardWithOutSession();
      }
    } catch (err) {
      toastifyRef.current.notify(
        'error', 
        err.response.data.msg || 'Tu sesión caducó'
      );
    }
  }

  useEffect(() => {
    validateToken();
  }, []);

  return (
    <>
      <SEO
        title={'Checo Cards | Detalle de tarjeta'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'} />
      <Layout id='detail-card' classes='pt-main pb-18 relative flex'>
        <div className='bg cnt full'>
          <picture>
            <source srcSet={bg.sm_avif + ' 1x, ' + bg.sm2_avif + ' 2x'}
              type="image/avif" media='(max-width: 550px)' />
            <source srcSet={bg.sm_webp + ' 1x, ' + bg.sm2_webp + ' 2x'}
              type="image/webp" media='(max-width: 550px)' />
            <source srcSet={bg.md_avif + ' 1x, ' + bg.md2_avif + ' 2x'}
              type="image/avif" media='(max-width: 768px)' />
            <source srcSet={bg.md_webp + ' 1x, ' + bg.md2_webp + ' 2x'}
              type="image/webp" media='(max-width: 768px)' />
            <source srcSet={bg.desk_avif + ' 1x, ' + bg.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={bg.desk_webp + ' 1x, ' + bg.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='full'
              srcSet={bg.desk + ' 1x, ' + bg.desk2 + ' 2x'}
              src={bg.desk}
              alt=''
              width={375}
              height={420} />
          </picture>
        </div>
        {user &&
          <Link to={'/mi-album'}
            state={{
              'albumPage': albumPage
            }}
            className='back'
          ><img src="/assets/images/global/arrow-back-circle-blue.svg"
            className='col-1'
            alt="" width={24} height={24} /></Link>}
        <section className='col-1 pt-12'>
          <div className='pb-24 px-container mb-12 relative flex-md flex-md-center mb-md-0'>
            <h1 className='text-venera text-center'>{card ?
              (card.CardNumber < 10 ? '0' : '') + (card.CardNumber ? card.CardNumber : '0')
              :
              <Skeleton className='text'>00</Skeleton>} / <span className='text-red'>{totalCards}</span></h1>
            <div className='content-card mx-auto my-24 col-1 mr-md-24'>
              <CardImage card={card} category={cardCategory} />
            </div>
            <div className='info mx-auto mx-md-0'>
              {user && cardLocation &&
                <div className='text-white text-center mb-24 px-md-12'>
                  <p className='h1 text-venera'>{card ?
                    repeated + ' Encontrada' + (repeated > 1 ? 's' : '')
                    :
                    <Skeleton className='text'>Encontrada</Skeleton>}</p>
                <p className='h2'>{
                  card && cardLocation ?
                    cardLocation
                  :
                    <Skeleton className='text'>Ubicación donde se guardó la tarjeta / Por escaneo</Skeleton>}</p>
                </div>}
              {process.env.REACT_APP_ENABLED_TRANSFER && user && isMobile && cardLocation &&
                <div className='pt-6 relative'>
                  {card && repeated === 1 &&
                    <div className={'tooltip cnt-h p-18 text-center ' + (tooltipState === -1 ? '' : 'active')}>
                      <div className='close-tooltip'
                        onClick={closeTooltip}><span className='cnt'></span></div>
                      <p className='text-red h3'>Se requieren mínimo <br />2 tarjetas para transferir</p>
                    </div>}
                  {card ?
                    repeated > 1 ?
                      <a className='btns btns-blue mt-24 mx-auto'
                        href={'https://api.whatsapp.com/send?text=' + messageToShare + shareURL}
                        target='_blank'>
                        <span>Transferir tarjeta</span>
                      </a>
                      :
                      <button className='btns btns-blue locked mt-24 mx-auto'
                        type='button'
                        onClick={showTooltip}>
                        <span>Transferir tarjeta</span>
                      </button>
                    :
                    <button className='btns mx-auto'><Skeleton><span>Transferir tarjeta</span></Skeleton></button>}
                </div>}
              {card && !user &&
                <div>
                  <div className='text-white text-center mb-24'>
                    <p className='h1 text-venera'><span>¿Y tú <br /> ya la tienes?</span></p>
                    <p className='h2 mt-24'>Regístrate o Inicia sesión para empezar a coleccionar las Checo Cards</p>
                  </div>
                  <div className='pt-6 relative'>
                    <div>
                      <Link className='btns btns-blue mt-24 mx-auto'
                        to={'/login'}>
                        <span>Inicia sesión</span>
                      </Link>
                      <Link className='btns btns-red mt-24 mx-auto'
                        href={'/registro'}>
                        <span>Regístrate</span>
                      </Link>
                    </div>
                  </div>
                </div>}
            </div>
          </div>
        </section>
        <Toastify ref={toastifyRef} />
      </Layout>
    </>
  )
}

export { DetailCard }
