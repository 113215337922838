import React, { useRef, useState } from 'react'

import './video.scss';

const VideoTutorial = () => {
  const videoRef = useRef();
  const [videoPaused, setVideoPaused] = useState(true);

  const videoPlay = (ev) => {
    const video = videoRef.current;
    setVideoPaused(!videoPaused);
    if(videoPaused) {
      video.play();
    }else{
      video.pause();
    }
  }
  const videoUpdate = (ev) => {
    setVideoPaused(true);
  }

  return (
    <div className={'video-content relative col-1 ' + (videoPaused ? '' : 'active')}>
      <video ref={videoRef}
        onPause={videoUpdate}
        className={'col-1 relative'}
        loop controls poster='/assets/images/home/portada-manual-checocards.jpg'>
        <source src='/assets/images/home/como-jugar-checocards.mp4' 
          type='video/mp4'/>
      </video>
      <div className='player cnt full' onClick={videoPlay}>
        <img className={'cnt'}
          src='/assets/images/home/i-play.svg'
          alt=''
          width={48}
          height={48}
        />
      </div>
    </div>
  )
}

export { VideoTutorial };