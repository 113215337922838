import React, { useEffect, useState, useRef } from 'react';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  InfoWindow,
} from '@vis.gl/react-google-maps';
import { Link, useNavigate } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import SEO from 'providers/HelmetProvider';
import changeNameImages from 'utils/changeNameImages';
import { ax } from 'providers/LoaderProvider';
import { Toastify } from 'components/toastify/toastify';
import Layout from 'components/routes/Layout';

import './explore.scss'
import { useUserContext } from 'providers/UserProvider';
import UserService from 'services/userService.service';
import markerLocation from '../../assets/explore/point.png';
import commonPin from '../../assets/explore/common-pin.png';
import premiumPin from '../../assets/explore/premium-pin.png';
import specialPin from '../../assets/explore/special-pin.png';
import iInstructions from '../../assets/explore/i-instructions.svg';
import { FirstTime } from 'views/modals/firstTime/firstTime';

const Explore = () => {
  const navigate = useNavigate();
  const markerRefs = useRef({});
  const toastifyRef = useRef();
  const { user } = useUserContext(); 
  const distance = parseInt(process.env.REACT_APP_DISTANCE);
  const logoTelcel = changeNameImages('/assets/images/global/logo-telcel.png');
  const [imageError, setImageError] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [mapLocation, setMapLocation] = useState(null);
  const [myLocation, setMyLocation] = useState(null);
  const [messageLocation, setMessageLocation] = useState('Requerimos de permisos para acceder a tu ubicación.');
  const [activePins, setActivePins] = useState([]);
  const [nearesPin, setNearesPin] = useState(null);
  const [withSesion, setWithSesion] = useState(false);
  const [validateStep, setValidateStep] = useState(0);
  const [infowindowOpen, setInfowindowOpen] = useState(null);
  const [openFirstTime, setOpenFirstTime] = useState(false);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setValidateStep((value) => value + 1);
          setMapLocation(pos);
        },
        error => {
          console.error("Error obteniendo la geolocalización: ", error);
          setMessageLocation('<p>No podemos obtener tu geolocalización <br/> Para mejorar tu experiencia, asegúrate de dar permisos de ubicación en la configuración de tu dispositivo.</p>');
        }
      );
    } else {
      console.error("La geolocalización no es soportada por este navegador.");
      setMessageLocation('La geolocalización no es soportada por este navegador');
    }
  };

  const getPins = async () => {
    const headerConfig = {
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'token': user.token
      },
    };
    ax
      .get(
        `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_ENDPOINT_LOCATIONS}`,
        headerConfig)
        .then(async (res) => {
          if (res.status !== 200) {
            throw new Error(errorResponse.message || 'Ocurrió un error inesperado');
          } else {
            setMarkers(() => res.data.activePins)
        }
      })
      .catch((err) => {
        console.error(err);
        toastifyRef.current.notify(
          'error', 
          err.response.data.msg || 'Ocurrió un error inesperado'
        );
      });
  }

  const getNearesPin = (myLoc) => {
    let auxActivePins = Array.from(activePins.length > 0 ? activePins : markers);
    let newArray = [...auxActivePins].sort((a, b) => validPin(myLoc, {
      lat: a.location.lat,
      lng: a.location.lng
    }) - validPin(myLoc, {
      lat: b.location.lat,
      lng: b.location.lng
    }))
    if (newArray.length > 0 && validPin(myLoc, {
      lat: newArray[0].location.lat,
      lng: newArray[0].location.lng
    }) <= distance) {
      setNearesPin(() => newArray[0]);
    } else {
      setNearesPin(() => null);
    }
    setActivePins(newArray.length > 5 ? newArray.slice(0, 5) : newArray);
  }

  const validPin = (myPosition, pinPosition) => {
    const R = 6378.137;
    const dLat = getRad(pinPosition.lat - myPosition.lat);
    const dLong = getRad(pinPosition.lng - myPosition.lng);
    const calc = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(getRad(myPosition.lat)) * 
      Math.cos(getRad(pinPosition.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const distance = 2 * Math.atan2(Math.sqrt(calc), Math.sqrt(1 - calc));
    return R * distance * 1000;
  }

  const getRad = (dist) => {
    return (dist * Math.PI) / 180;
  }

  const validateToken = async () => {
    try {
      const res = await UserService.validToken();
      if (res) {
        setWithSesion(true);
      }
      setValidateStep((value) => value + 1);
    } catch (err) {
      navigate('/login')
    }
  }

  const isFirstTime = () => {
    const firstTime = localStorage.getItem('CHECO_FIRST_TIME');
    setOpenFirstTime(firstTime);
  }

  const openModal = (location) => {
    setInfowindowOpen(location._id)
    setImageError(false)
  }

  useEffect(() => {
    if (markers.length > 0) {
      getNearesPin(mapLocation)
      setMyLocation(mapLocation);
    }
  }, [markers])

  useEffect(() => {
    if (validateStep === 2) {
      if (withSesion) {
        getPins();
      } else {
        setMyLocation(mapLocation);
      }
    }
  }, [validateStep])

  useEffect(() => {
    if (!myLocation) return;
    let intervalId = setTimeout(() => {
      navigator.geolocation.getCurrentPosition(
        position => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setMyLocation(() => pos);
          getNearesPin(pos);
        },
        error => {
          console.error("Error obteniendo la geolocalización: ", error);
        }
      );
    }, 30000);
    return () => clearTimeout(intervalId);
  }, [myLocation]);

  useEffect(() => {
    validateToken();
    getCurrentLocation();
    isFirstTime();
  }, []);

  return (
    <>
      <SEO
        title={'Checo Cards | Explorar'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'}
      />
      <Layout id='explore' classes='flex flex-column flex-stretch'>
        <section className='px-container mb-24 col-1'>
          <h1 className='text-center text-venera mb-12'>¡A jugar!</h1>
          <div className='px-24'>
            <p className='text-center'>Usa tu celular y dirígete a cada ubicación para revelar una tarjeta oculta. ¡Sé el primero en llenar tu álbum!</p>
          </div>
        </section>
        <section className='map relative col-1'>
          {mapLocation ?
            <div className='full'>
              <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                libraries={['marker']}
              >
                <Map
                  className='full'
                  mapId={process.env.REACT_APP_GOOGLE_MAPS_MAP_ID}
                  defaultCenter={mapLocation}
                  defaultZoom={18}
                  minZoom={15}
                  maxZoom={25}
                  gestureHandling={'cooperative'}
                  disableDefaultUI={true} />
                <AdvancedMarker
                  position={myLocation}
                  title={'Ubicación actual'}
                >
                  <img src={markerLocation}
                    className='current-location'
                    width={370} height={370} alt="Current Location" />
                </AdvancedMarker>
                {markers.map(({ location, category }) => {
                  if (!markerRefs.current[location._id]) {
                    markerRefs.current[location._id] = React.createRef();
                  }
                  return (
                    <AdvancedMarker
                      key={location._id}
                      position={{
                        lat: location.lat,
                        lng: location.lng
                      }}
                      onClick={() => openModal(location)}
                      ref={markerRefs.current[location._id]}
                    >
                      <img
                        src={category === 1 ? commonPin : (category === 2 ? specialPin : (category === 3 ? premiumPin : premiumPin))}
                        className='pin-card'
                        width={108} height={144} alt={location.title} />
                      {infowindowOpen === location._id &&
                        <InfoWindow
                          anchor={markerRefs.current[location._id].current}
                          headerDisabled={true}
                        >
                          <div className='info-window'>
                            <div className='flex flex-between'>
                              <p className="h3 text-location text-venera mb-6 pr-24">{location.title}</p>
                              <div className='close-btn'
                                onClick={() => setInfowindowOpen(null)}
                              ><span className='close-icon cnt'></span></div>
                            </div>
                            <p className="text-location mb-12">{location.location}</p>
                            <div className='location-image mb-12 relative'>
                              <picture>
                                <source srcSet={`${ logoTelcel.sm_avif } 1x, ${ logoTelcel.desk_avif } 2x`}
                                  type="image/avif" media='(max-width: 550px)' />
                                <source srcSet={`${ logoTelcel.sm_avif } 1x, ${ logoTelcel.desk_webp }.webp 2x`}
                                  type="image/webp" media='(max-width: 550px)' />
                                <source srcSet={`${ logoTelcel.desk_avif } 1x, ${ logoTelcel.desk2_avif } 2x`}
                                  type="image/avif" />
                                <source srcSet={`${ logoTelcel.desk_webp } 1x, ${ logoTelcel.desk2_webp } 2x`}
                                  type="image/webp" />
                                <img className="cnt col-1 col-2 logo"
                                  src={`${ logoTelcel.desk } 1x, ${ logoTelcel.desk2 } 2x`}
                                  alt="" width={141} height={40} />
                              </picture>
                              {location.img && !imageError &&
                                <img src={location.img}
                                  onError={() => setImageError(true)}
                                  className="full relative"
                                  alt="" width={680} height={510} />}
                            </div>
                            <a className='btns'
                              href={location.link_google}
                              target='_blank' rel='noreferrer noopener'>
                              <img src={iInstructions}
                                alt="" width={24} height={24} />
                              <span>Cómo llegar</span>
                            </a>
                          </div>
                        </InfoWindow>}
                    </AdvancedMarker>
                  );
                })}
              </APIProvider>
              {isMobile && <>
                {withSesion ?
                  <Link className={(!nearesPin ? 'disabled ' : '') + 'btns cnt-h search-card'}
                    to={'/cards'}
                    state={nearesPin}>
                    <span>Estoy en la ubicación, buscar tarjeta</span>
                  </Link>
                  :
                  <Link className='btns cnt-h search-card' to={'/login'}>
                    <span>Iniciar sesión para jugar</span>
                  </Link>}
              </>}
            </div>
            :
            <div className='full flex flex-center loading text-center px-24' dangerouslySetInnerHTML={{__html: messageLocation}}>  
            </div>}
        </section>
        <Toastify ref={toastifyRef} />
        {openFirstTime && (
          <FirstTime />
        )}
      </Layout>
    </>
  );
}

export { Explore };
