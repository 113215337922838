import { Link } from 'react-router-dom';
import changeNameImages from 'utils/changeNameImages';
import './footer.scss';

function Footer() {
  const logo = changeNameImages('/assets/images/global/logo-oracle-redbull-telcel.jpg')

  return (
    <footer id='footer' className='py-24 px-container relative'>
      <div className='footer-content flex flex-column flex-md-row flex-between mx-auto'>
        <div className='logo mx-auto mt-12 mb-6 mx-md-0'>
          <a href="https://www.redbullracing.com/int-en/" 
            target='_blank'
            rel='noopener noreferrer'>
            <picture>
              <source srcSet={logo.desk_avif + ' 1x, ' + logo.desk2_avif + ' 2x'} 
                type="image/avif" />
              <source srcSet={logo.desk_webp + ' 1x, ' + logo.desk2_webp + ' 2x'} 
                type="image/webp" />
              <img className='col-1'
                srcSet={logo.desk + ' 1x, ' + logo.desk2 + ' 2x'}
                src={logo.desk} 
                alt='Telcel | Oracle | Red bull | Racing'
                width={150}
                height={150}
              />
            </picture>
          </a>
        </div>
        <div className='copyright my-24 text-center text-md-left'>
          <p className='text-white'>© 2024 Todos los derechos reservados Checo Explore Cards Telcel, Red Bull y todos los logotipos relacionados son marcas y nombres comerciales.</p>
        </div>
        <div className='links my-24 text-center text-md-left'>
          <p className='text-white'>Al registrarte aceptas nuestros </p>
          <p className='text-white'
          ><Link to="/terminos-y-condiciones" className='b'
            >Términos y condiciones</Link> y <Link to="/aviso-de-privacidad" className='b'
            >Aviso de privacidad</Link></p>
        </div>
        <div className='rrss flex flex-center py-12'>
          <a href="https://web.facebook.com/Telcel" 
            target='_blank'
            rel='noopener noreferrer'>
            <img src="/assets/images/global/i-fb.svg " 
              className='col-1'
              alt="" 
              width={24}
              height={24}/>
          </a>
          <a href="https://www.instagram.com/telcel" 
            target='_blank'
            rel='noopener noreferrer'>
            <img src="/assets/images/global/i-instagram.svg " 
              className='col-1'
              alt="" 
              width={24}
              height={24}/>
          </a>
          <a href="https://twitter.com/telcel" 
            target='_blank'
            rel='noopener noreferrer'>
            <img src="/assets/images/global/i-x.svg " 
              className='col-1'
              alt="" 
              width={24}
              height={24}/>
          </a>
          <a href="https://www.youtube.com/user/Telceloficial" 
            target='_blank'
            rel='noopener noreferrer'>
            <img src="/assets/images/global/i-youtube.svg" 
              className='col-1'
              alt="" 
              width={24}
              height={24}/>
          </a>
        </div>
      </div>
    </footer>
  )
}

export { Footer };

