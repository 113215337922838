import React from 'react';
import { Link } from 'react-router-dom';
import changeNameImages from 'utils/changeNameImages';
import { useUserContext } from 'providers/UserProvider';
import './notFound.scss';
import SEO from 'providers/HelmetProvider';
import Layout from 'components/routes/Layout';

const NotFound = () => {
  const bg = changeNameImages('/assets/images/global/bg-texture.png');
  const image = changeNameImages('/assets/images/not-found/blur.png');
  const checoLetters = changeNameImages('/assets/images/not-found/checo-letters.png');
  const { user } = useUserContext();

  return (
    <>
      <SEO
        title={'Checo Cards | 404'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'}
      />
      <Layout id='not-found' classes='pt-main pb-18 relative'>
        <div className='bg cnt-h col-1'>
          <picture>
            <source srcSet={bg.desk_avif + ' 1x, ' + bg.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={bg.desk_webp + ' 1x, ' + bg.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='col-1'
              srcSet={bg.desk + ' 1x, ' + bg.desk2 + ' 2x'}
              src={bg.desk}
              alt=''
              width={375}
              height={420}
            />
          </picture>
        </div>

        <div className='flex flex-center'>
          <div className='letters col-3 d-none d-md-block'>
            <picture>
              <source srcSet={checoLetters.desk_avif + ' 1x, ' + checoLetters.desk2_avif + ' 2x'}
                type="image/avif" />
              <source srcSet={checoLetters.desk_webp + ' 1x, ' + checoLetters.desk2_webp + ' 2x'}
                type="image/webp" />
              <img className='col-1 relative'
                srcSet={checoLetters.desk + ' 1x, ' + checoLetters.desk2 + ' 2x'}
                src={checoLetters.desk}
                alt=''
                width={322}
                height={847}
              />
            </picture>
          </div>
          <div className='container-not-found col-1'>
            <div className='relative py-24 py-md-12'>
              <picture>
                <source srcSet={image.desk_avif + ' 1x, ' + image.desk2_avif + ' 2x'}
                  type="image/avif" />
                <source srcSet={image.desk_webp + ' 1x, ' + image.desk2_webp + ' 2x'}
                  type="image/webp" />
                <img className='img-blur mx-auto col-1 relative'
                  srcSet={image.desk + ' 1x, ' + image.desk2 + ' 2x'}
                  src={image.desk}
                  alt=''
                  width={375}
                  height={360}
                />
              </picture>
              <div className='cnt cnt-v cnt-h flex flex-center'>
                <p className='text-blue text-center text-venera txt-404 b'>404</p>
              </div>
            </div>

            <div className='px-container mb-24 relative'>
              <p className='h2 text-center mx-auto py-24 b'>
                Parece que algo salió mal, podemos redirigirte
                al inicio o puedes seguir explorando 
              </p>
              <div className='flex flex-center flex-column flex-md-row btns-container'>
                <Link to='/' className=''>
                  <button className='btns btns-blue mt-24'>
                    <span>Regresar al inicio</span>
                  </button>
                </Link>
                {
                  user && (
                  <Link to='/explore'>
                    <button className='btns btns-red mt-24'>
                      <span>Ir a explorar</span>
                    </button>
                  </Link>
                  )
                } 
              </div>
            </div>
          </div>
        </div>
      </Layout> 
    </>
  )
}

export { NotFound }
