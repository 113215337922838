import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { Toastify } from "components/toastify/toastify";
import { ax } from 'providers/LoaderProvider';
import Layout from 'components/routes/Layout';

import changeNameImages from "utils/changeNameImages";
import UserService from 'services/userService.service';
import { Skeleton } from "components/skeleton/skeleton";
import { CardImage } from "views/detailCard/cardImage";
import { HomeTutorial } from "views/home/tutorial/homeTutorial";
import { useUserContext } from "providers/UserProvider";
import SEO from 'providers/HelmetProvider';
import "../detailCard/detailCard.scss";

const SaveCard = () => {
  const bg = changeNameImages("/assets/images/detail-card/bg.jpg");
  const savedCard = changeNameImages("/assets/images/game/save-card.png");
  const toastifyRef = useRef();
  const navigate = useNavigate();

  const params = useParams();
  const location = useLocation();
  const { user } = useUserContext();
  const [card, setCard] = useState();
  const [cardCategory, setCardCategory] = useState('1');
  const [totalCards, setTotalCards] = useState(100);
  const [nexStep, setNexStep] = useState(false);
  const [isSharedCard, setIsSharedCard] = useState(false);
  const [withSesion, setWithSesion] = useState(false);

  const saveCard = () => {
    if (isSharedCard) {
      saveSharedCard();
    } else {
      saveCardQR();
    }
  };

  const saveSharedCard = async () => {
    ax
      .get(
        (`${ process.env.REACT_APP_SERVER_URL }${ process.env.REACT_APP_ENDPOINT_SAVE_CARD_GIFT }`).replace('@token', params.token))
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error(errorResponse.message || 'Error al obtener la tarjeta');
          } else {
            setNexStep(true);
            localStorage.removeItem("SAVED_CARDID");
            localStorage.removeItem("SHARED_TOKEN");
          }
      })
      .catch((err) => {
        toastifyRef.current.notify("error", err.response?.data.msg || err.message);
      });
  };

  const saveCardQR = async () => {
    const dataForm = {
      userid: user.uid,
      cardid: params.idCard,
    };
    ax
      .post(
        `${ process.env.REACT_APP_SERVER_URL }${ process.env.REACT_APP_ENDPOINT_SAVE_CARD_QR }`,
        dataForm)
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error(res.data.msg || "Error al guardar la tarjeta");
          } else {
            setNexStep(true);
          }
      })
      .catch((err) => {
        toastifyRef.current.notify("error", err.response?.data.msg || err.message);
      });
  };

  const getSesion = () => {
    const _isSharedCard = location.pathname.split("/")[1] === "transfer-card";
    setIsSharedCard(_isSharedCard);
    if (_isSharedCard && !params.token || params.token === '') {
      navigate('/404')
    } else {
      getDataCard();
    }
  };

  const getDataCard = async () => {
    try {
      const response = await fetch(
        (`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_ENDPOINT_GET_CARD_WITHOUT_USER}`).replace('@cardId', params.idCard),
        {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (response.status !== 200) {
        if (response.status === 400) {
          navigate("/404");
          return;
        } else {
          const errorResponse = await response.json();
          throw new Error(
            errorResponse.msg || "Ocurrió un error al obtener la tarjeta"
          );
        }
      }
      const res = await response.json();
      setCard(res);
      setCardCategory(res.category.category);
      getCountCards();
    } catch (error) {
      toastifyRef.current.notify("error", error.message);
    }
  };

  const getCountCards = async () => {
    await fetch(
      `${ process.env.REACT_APP_SERVER_URL }${ process.env.REACT_APP_ENDPOINT_GET_COUNTER_CARDS }`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then(async (response) => {
        if (response.status !== 200) {
          const errorResponse = await response.json();
          toastifyRef.current.notify(
            "error",
            "Error al obtener el número de tarjetas"
          );
          throw new Error(
            errorResponse.message || "Error al obtener el número de tarjetas"
          );
        }
        return response.json();
      })
      .then((res) => {
        if (res && totalCards < res.count) {
          setTotalCards(res.count);
        }
      });
  };

  const dataSave = () => {
    localStorage.setItem("SAVED_CARDID", params.idCard);
    if (isSharedCard) {
      localStorage.setItem("SHARED_TOKEN", params.token);
    }
  };

  const validateToken = async () => {
    try {
      const res = await UserService.validToken();
      if (res) {
        setWithSesion(true);
      }
    } catch (err) {
      toastifyRef.current.notify(
        'error', 
        err.response.data.msg || 'Tu sesión caducó'
      );
    }
  }

  useEffect(() => {
    validateToken();
    getSesion();
  }, []);

  return (
    <>
      <SEO
        title={'Checo Cards | Guardar Tarjeta'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'} />
      <Layout id="detail-card" classes="relative">
        <Toastify ref={toastifyRef} />
        <section className="col-1 pt-main relative pb-18">
          <div className="bg cnt full">
            <picture>
              <source
                srcSet={bg.sm_avif + " 1x, " + bg.sm2_avif + " 2x"}
                type="image/avif"
                media="(max-width: 550px)" />
              <source
                srcSet={bg.sm_webp + " 1x, " + bg.sm2_webp + " 2x"}
                type="image/webp"
                media="(max-width: 550px)" />
              <source
                srcSet={bg.md_avif + " 1x, " + bg.md2_avif + " 2x"}
                type="image/avif"
                media="(max-width: 768px)" />
              <source
                srcSet={bg.md_webp + " 1x, " + bg.md2_webp + " 2x"}
                type="image/webp"
                media="(max-width: 768px)" />
              <source
                srcSet={bg.desk_avif + " 1x, " + bg.desk2_avif + " 2x"}
                type="image/avif" />
              <source
                srcSet={bg.desk_webp + " 1x, " + bg.desk2_webp + " 2x"}
                type="image/webp" />
              <img
                className="full"
                srcSet={bg.desk + " 1x, " + bg.desk2 + " 2x"}
                src={bg.desk}
                alt=""
                width={375}
                height={420} />
            </picture>
          </div>
          <div className="pb-24 px-container relative flex-md flex-md-center">
            <h1 className="text-venera text-center">
              {card ? (
                (card.CardNumber < 10 ? "0" : "") +
                (card.CardNumber ? card.CardNumber : "0")
              ) : (
                <Skeleton className="text">00</Skeleton>
              )}{" "}
              / <span className="text-red">{totalCards}</span>
            </h1>
            <div className="content-card mx-auto my-24 col-1 mr-md-24">
              <CardImage card={card} category={cardCategory} />
            </div>
            <div className="info mx-auto mx-md-0">
              <div className="text-white text-center mb-24 px-md-12">
                <p className="h1 text-venera">
                  {user && withSesion ? (
                    isSharedCard ? (
                      <span>
                        Tarjeta <br /> por transferencia
                      </span>
                    ) : (
                      <span>
                        Tarjeta <br /> de regalo
                      </span>
                    )
                  ) : (
                    <span>
                      Esta tarjeta <br /> puede ser tuya
                    </span>
                  )}
                </p>
                {!user && (
                  <p className="h2 mt-24">
                    Regístrate o Inicia sesión para tener esta tarjeta de Checo
                    Cards
                  </p>
                )}
              </div>
              <div className="pt-6 relative">
                {user ? (
                  <button
                    className="btns btns-blue mb-24 mx-auto"
                    type="button"
                    onClick={saveCard}
                  >
                    <span>Guardar tarjeta</span>
                  </button>
                ) : (
                  <div>
                    <Link
                      className="btns btns-blue mt-24 mx-auto"
                      to={"/login"}
                      onClick={dataSave}
                    >
                      <span>Inicia sesión</span>
                    </Link>
                    <Link
                      className="btns btns-red mt-24 mx-auto"
                      href={"/registro"}
                      onClick={dataSave}
                    >
                      <span>Regístrate</span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {!user && <HomeTutorial logo={true} />}
        {user && card && (
          <div
            className={"success cnt full flex flex-center flex-column" +
              (nexStep ? " active" : "")}
          >
            <div className="content relative col-1">
              <p className="h1 text-center text-venera text-white">
                Tarjeta <br />
                guardada
              </p>
              <picture>
                <source
                  srcSet={savedCard.desk_avif + " 1x, " + savedCard.desk2_avif + " 2x"}
                  type="image/avif" />
                <source
                  srcSet={savedCard.desk_webp + " 1x, " + savedCard.desk2_webp + " 2x"}
                  type="image/webp" />
                <img
                  className="col-1 col-2"
                  srcSet={savedCard.desk + " 1x, " + savedCard.desk2 + " 2x"}
                  src={savedCard.desk}
                  alt=""
                  width={91}
                  height={83} />
              </picture>
              <Link className="btns btns-red mx-auto" to={`/mi-album/card/${params.idCard}`}>
                <span>Ver tarjeta</span>
              </Link>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export { SaveCard };
