import React, { useEffect, useState } from 'react'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import Layout from 'components/routes/Layout';
import NewService from 'services/newService.service';

import './detailNews.scss'

function DetailNew() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [note, setNote] = useState(location.state);
  
  const getNew = () => {
    if (!note) {
      const _note = NewService.getNew(params.slugNew);
      if (_note) {
        setNote(_note)
      } else {
        navigate('/404');
      }
    } 
  }
  
  useEffect(() => {
    getNew();
  });

  return (
    <Layout id='detail-new' className='pb-18 relative'>
      <Link to={'/novedades'}
        className='back'
        ><img src="/assets/images/global/arrow-back-circle-white.svg" 
          alt="" width={24} height={24}
          className='col-1'/></Link>
      {
        note && <>
          <div className='image relative mb-24'>
            <picture>
              <source srcSet={note.image.sm_avif + ' 1x, ' + note.image.sm_avif + ' 2x'}
                type="image/avif" 
                media='(max-width: 640px)'/>
              <source srcSet={note.image.sm_webp + ' 1x, ' + note.image.sm_webp + ' 2x'}
                type="image/webp" 
                media='(max-width: 640px)'/>
              <source srcSet={note.image.desk_avif + ' 1x, ' + note.image.desk2_avif + ' 2x'}
                type="image/avif" />
              <source srcSet={note.image.desk_webp + ' 1x, ' + note.image.desk2_webp + ' 2x'}
                type="image/webp" />
              <img className='col-1'
                srcSet={note.image.desk + ' 1x, ' + note.image.desk2 + ' 2x'}
                src={note.image.desk}
                alt=''
                width={321}
                height={250} />
            </picture>
          </div>
          <section className='info-content pt-6 pb-24 px-container mb-24 relative mx-auto'>
            <h1 className='text-venera h2'>{ note.title }</h1>
            <div className='mt-24'>
              {note.content.map((info, i) => {
                return (
                  <div key={i}>
                    {
                      info.hasOwnProperty('text') && <p>{ info.text }</p>
                    }
                    {
                      info.hasOwnProperty('image') && 
                      <div className='content-image my-12'>
                        <picture>
                          <source srcSet={info.image.desk_avif + ' 1x, ' + info.image.desk2_avif + ' 2x'}
                            type="image/avif" />
                          <source srcSet={info.image.desk_webp + ' 1x, ' + info.image.desk2_webp + ' 2x'}
                            type="image/webp" />
                          <img className='col-1'
                            srcSet={info.image.desk + ' 1x, ' + info.image.desk2 + ' 2x'}
                            src={info.image.desk}
                            alt=''
                            width={321}
                            height={250} />
                        </picture>
                      </div>
                    }
                    {
                      info.hasOwnProperty('video') && 
                      <div className='content-video my-12'>
                        <video className={'col-1 relative'}
                          loop controls>
                          <source src={info.video}
                            type='video/mp4'/>
                        </video>
                      </div>
                    }
                    {
                      info.hasOwnProperty('html') && 
                      <div className='my-12 pt-6' dangerouslySetInnerHTML={{__html: info.html}}></div>
                    }
                  </div>
                )
              })}
            </div>
          </section>
        </>
      }
    </Layout>
  )
}

export { DetailNew }
